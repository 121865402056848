import {React, Link, Locale } from '../../libraries';
import { HomeRoutes } from '../../routes/index';

export const BreadCrumb = ({ data }) => {
  const { t } = Locale();
  const selectedLanguage = localStorage.getItem('i18nextLng');
  return (
    <>
      <div className="breadcrumb-outer-div">
        <nav
          lang="sk"
          role="navigation"
          aria-label="Omrvinková navigácia"
          className="breadcrumb"
        >
          <ol>
            <li>
              <Link to={HomeRoutes.home} className="breadcrumb-link-tag">
                {t('breadcrumb.home')}
              </Link>
            </li>
            {data.length >= 1 &&
              data.map((obj, index) => (
                <React.Fragment key={index}>
                  <span aria-hidden="true" className="breadcrumb-separator">
                    &gt;
                  </span>
                  <li key={obj.breadCrumbName} className="breadcrumb-li-tag">
                    {data.length === 1 ? (
                      <>{obj.breadCrumbName}</>
                    ) : (
                      <>
                        {index === 0 ? (
                          <Link
                            to={obj.breadCrumbPath}
                            className="breadcrumb-link-tag"
                          >
                            {obj.breadCrumbName}
                          </Link>
                        ) : (
                          <>
                            {selectedLanguage === 'en'
                              ? obj.breadCrumbNameEn
                              : obj.breadCrumbNameTh}
                          </>
                        )}
                      </>
                    )}
                  </li>
                </React.Fragment>
              ))}
          </ol>
        </nav>
      </div>
    </>
  );
};
