import { useEffect, useState, useNavigate, useParams, Locale, toast } from '../../../libraries';
import { GetOrganizationStatus } from '../index';
import {BusinessRoutes } from '../../../routes/routes';
import { getPayoutDaillyTransaction, getPayoutMonthlyReport, handleErrorResponse, downloadRecordFile } from '../../../services';
import { decryptData } from '../../../customHooks/index';

const PayoutSummaryLogic = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = Locale();
  const [disableDownload, setDisableDownload] = useState(false);
  const { isOrganizationStatus } = GetOrganizationStatus();
  const [isLoader, setIsLOader] = useState(false);
  const [monthName, setMonthName] = useState({
    monthNameEng: '',
    monthNameThai: '',
  });
  const [isOpenDailyTransaction, setIsOpenDailyTransaction] = useState(false);
  const [total_pages, setTotalePage] = useState(0);
  const dycryptedData = decryptData(params?.encryptedData)
  const { isMonthEn, isMonthThai } = dycryptedData
  const [filterValue, setFilterValue] = useState({
    month: isMonthEn,
    page_number: '1',
    per_page: '10',
  });
  const [monthlyData, setMonthlyData] = useState();
  const [dailyTransactionData, setDailyTransactionData] = useState();

  useEffect(() => {
    setMonthName((prev) => ({
      ...prev,
      monthNameEng: isMonthEn,
      monthNameThai:isMonthThai,
    }));
    const fetchPayoutMonth = async () => {
      let tempData = await getPayoutDataMonthBase(isMonthEn);
      setMonthlyData(tempData);
    };
    fetchPayoutMonth();
  }, []);

  const handleNavigatePayoutPage = (e) => {
    e.preventDefault();
    navigate(`${BusinessRoutes.bussiness}${BusinessRoutes.payout}`);
  };

  useEffect(() => {
    const fetchDailyReport = async () => {
      let tempDillyData = await getDailyTransactionReport(filterValue);
      setDailyTransactionData(tempDillyData);
    };
    isOpenDailyTransaction && fetchDailyReport();
  }, [filterValue, isOpenDailyTransaction]);

  const getPayoutDataMonthBase = async (selectedMonth) => {
    setIsLOader(true);
    try {
      let param = {
        month: selectedMonth,
      };
      let response = await getPayoutMonthlyReport(param);
      return response.data.monthly_payouts;
    } catch (err) {
    } finally {
      setIsLOader(false);
    }
  };

  const getDailyTransactionReport = async (filterValue) => {
    try {
      let response = await getPayoutDaillyTransaction(filterValue);
      setTotalePage(
        response.data.daily_transaction ? response?.data?.total_pages * 10 : 0,
      );
      return response.data.daily_transaction;
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  const tableHeading = [
    t('payoutSummary.bookingId'),
    t('payoutSummary.transactionId'),
    t('payoutSummary.date'),
    t('payoutSummary.time'),
    t('payoutSummary.paidAmount'),
    t('payoutSummary.status'),
  ];

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const formattedTime = date.toLocaleTimeString('th-TH'); // Thai locale
    return formattedTime;
  };

  const handlePaginationChange = (page, pageSize) => {
    setFilterValue((prevValue) => ({
      ...prevValue,
      page_number: page,
      per_page: pageSize,
    }));
  };

  const downloadRecord = async (dynamicMonthName) => {
    if (!disableDownload) {
      try {
        setDisableDownload(true);
        const response = await downloadRecordFile(dynamicMonthName);
        toast.success(response.data.message);
        setDisableDownload(false);
      } catch (err) {
        setDisableDownload(false);
        handleErrorResponse(err);
      }
    }
  };

  return {
    handlePaginationChange,
    formatDate,
    formatTime,
    isOpenDailyTransaction,
    setIsOpenDailyTransaction,
    isOrganizationStatus,
    isLoader,
    handleNavigatePayoutPage,
    monthName,
    dailyTransactionData,
    monthlyData,
    tableHeading,
    filterValue,
    total_pages,
    downloadRecord,
    disableDownload,
  };
};

export default PayoutSummaryLogic;