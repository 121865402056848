import { React } from '../libraries';

const User = React.lazy(() => import('../pages/user.js'))
const UserEditProfileForm = React.lazy(() => import('../pages/UserProfile/UserProfileForm.js'));
const UserBookingsList = React.lazy(() => import('../pages/UserBooking/UserBookingList/UserBookingList.js'));
const CashbackLogic = React.lazy(() => import('../components/cashBackPage/cashBack.js'));
const ReferralLogic = React.lazy(() => import('../components/referralPage/referral.js'));
const ShowAllShop = React.lazy(() => import('../components/reviewAndRatings/shopReviewAndRating/getAllShop.js'));
const ReviewRatingServiceLogic = React.lazy(() => import('../components/reviewAndRatings/reviewAndRatingService/reviewRatingService.js'));
const AddToFavouriteLogic = React.lazy(() => import('../components/addToFavourite/addToFavourite.js'));
const AddToFavouriteServiceLogic = React.lazy(() => import('../components/addToFavouriteService/addToFavouriteService.js'));
const AddCart = React.lazy(() => import('../components/addToCart/addToCart.js'));
const AccountDeletion = React.lazy(() => import('../pages/AccountDeletion/AccountDeletion.js'),);
const AccountDeletionAction = React.lazy(() => import('../components/accountDeletion/accountDeletion.js'),);
const CancelDeletionAction = React.lazy(() => import('../components/cancelDeletion/cancelDeletion.js'));
const Payment = React.lazy(() => import('../pages/Payment/Payment.js'));
const CartItemsLogic = React.lazy(() => import('../components/cartItemsPayment/cartItems.js'));

export {
    User,
    UserEditProfileForm,
    UserBookingsList,
    CashbackLogic,
    ReferralLogic,
    ShowAllShop,
    ReviewRatingServiceLogic,
    AddToFavouriteLogic,
    AddToFavouriteServiceLogic,
    AddCart,
    AccountDeletion,
    AccountDeletionAction,
    CancelDeletionAction,
    Payment,
    CartItemsLogic,
}