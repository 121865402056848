import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import global_en from './translation/en/global.json';
import global_thai from './translation/thai/global.json';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const lng = localStorage.getItem('i18nextLng');
const selectedLanguage = lng && lng.trim() !== '' ? lng : 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    lng: selectedLanguage,
    resources: {
      en: {
        global: global_en,
      },
      thai: {
        global: global_thai,
      },
    },
  });

export default i18n;
