import sharedRoutes from "./sharedRoutes";

const customerRoutes = {
  user: '/user/',
  accountDeletion: 'accounts/deletion',
  deleteRequest: 'accounts/deleted',
  cancelDeleteRequest: 'accounts/deletion/cancel',
  payment: '/payment',
  cartPayment: '/cart_payment',
  users: '/user/*',
  favouriteShop: 'shopes/favourite/add',
  favouriteService: 'services/favourite/add',
  reviewShop: 'review_shop',
  addToCart: 'add_to_cart',
  cashback: 'cashback',
  referral: 'referral',
  reviewService: 'review_service',
  successCallback: '/success_callback',
  getMobileResponse: '/mobile',
  ...sharedRoutes
};

export default customerRoutes;  