import { React, message, useRef, useState, Locale, useCallback } from '../../libraries/index';
import { UploadImageModal, getCroppedImg } from '../index';
const UploadFileModalLogic = ({
  setFormValue,
  editImageIndex,
  formValue,
}) => {
  const [imageName, setImageName] = useState('');
  const [err, setErr] = useState({});
  const fileInputRef = useRef(null);
  const [imagePath, setImgePath] = useState('');
  const [imageObject, setImageObject] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imagePath, croppedAreaPixels);
      try {
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        const file = new File([blob], imageName, { type: blob.type });
        if (formValue.shopImages.length <= 4) {
          setFormValue((prevState) => ({
            ...prevState,
            shopImages: [...prevState.shopImages, croppedImage],
            shopImagesShow: [...prevState.shopImagesShow, file],
          }));
          const formData = new FormData();
          formData.append('file', file);

        }
      } catch (error) {
        console.error('Error fetching or processing blob:', error);
      }
    } catch (e) {
      console.error(e);
    }
  }, [imagePath, croppedAreaPixels]);
  const { t } = Locale();
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (event) {
      if (
        event?.type?.includes('jpeg') ||
        event?.type?.includes('png') ||
        event?.type?.includes('jpg')
      ) {
        const maxSize = 2 * 1024 * 1024;
        if (event.size > maxSize) {
          setImgePath('');
          setImageName(`${t('field.pleaseSelect')} ${t('vendor.image')}`);
          setErr((prevState) => ({
            ...prevState,
            imageName: `${t('validation.imageSize')}`,
          }));
        } else {
          let imagePath = URL.createObjectURL(event);
          setImageName(event.name);
          setImgePath(imagePath);
          setImageObject(event);
          setErr({});
        }
      }
      if (
        selectedFile?.type?.includes('jpeg') ||
        selectedFile?.type?.includes('png') ||
        selectedFile?.type?.includes('jpg')
      ) {
        const maxSize = 2 * 1024 * 1024;
        if (selectedFile.size > maxSize) {
          setImgePath('');
          setImageName(`${t('field.pleaseSelect')} ${t('vendor.image')}`);
          setErr((prevState) => ({
            ...prevState,
            imageName: `${t('validation.imageSize')}`,
          }));
        } else {
          let imagePath = URL.createObjectURL(selectedFile);
          setImageName(selectedFile.name);
          setImgePath(imagePath);
          setImageObject(selectedFile);
          setErr({});
        }
      }
    } else {
      setImgePath('');
      setImageName(`${t('field.pleaseSelect')} ${t('vendor.image')}`);
      setErr((prevState) => ({
        ...prevState,
        imageName: 'Please upload jpg, jpeg, png type.',
      }));
    }
  };

  const handleSubmit = async (e, closeButtonObject) => {
    showCroppedImage();
    if (Object.keys(err).length === 0 && imageName) {
      if (formValue.shopImages.length >= 5) {
        message.error({
          content: `${t('validation.max')} !!!`,
          duration: 5,
          onClose: async () => {
            await closeButtonObject.click();
            setImageName('');
            setImgePath('');
            setErr('');
          },
        });
      } else {
        if (editImageIndex !== undefined && editImageIndex === 0) {
          await setFormValue((prevState) => {
            const updatedShopImages = [...prevState.shopImages];
            const updatedShopImagesShow = [...prevState.shopImagesShow];
            updatedShopImages[editImageIndex] = imagePath;
            updatedShopImagesShow[editImageIndex] = imageObject;
            return {
              ...prevState,
              shopImages: updatedShopImages,
              shopImagesShow: updatedShopImagesShow,
            };
          });
        }
        await closeButtonObject.click();
        setImageName('');
        setImgePath('');
        setErr('');
      }
    } else {
      setErr((prevState) => ({
        ...prevState,
        imageName: `${t('field.pleaseSelect')} ${t('vendor.image')}`,
      }));
    }
  };

  const handleCancel = (e, closeButtonObject) => {
    closeButtonObject.click();
    setImageName('');
    setImgePath('');
    setErr('');
  };

  return (
    <>
      <UploadImageModal
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        handleFileChange={handleFileChange}
        imageName={imageName}
        err={err}
        fileInputRef={fileInputRef}
        imagePath={imagePath}
        onCropComplete={onCropComplete}
        crop={crop}
        zoom={zoom}
        setZoom={setZoom}
        setCrop={setCrop}
      />
    </>
  );
};
export default UploadFileModalLogic;