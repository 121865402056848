import { React, Locale } from '../../libraries';
import { Modal } from 'react-bootstrap';

const DescModal = ({
    showModal,
    handleClose,
    messageDesc,
    messageTerm
}) => {
    const { t } = Locale();


    return (
        <>

            <Modal
                show={showModal}
                onHide={() => handleClose()}
                dialogClassName="custom-modal customModalDesc"
            >
                <Modal.Header
                    closeButton
                    className="headerLine"
                    onClick={() => handleClose()}
                />
                <Modal.Body>
                    <div className="text-center">
                        <div className='desc-heading-outer'>

                            <div className='desc-heading-inner'>
                                <div className='desc-heading'>
                                    {t('ServiceField.serviceDescinEng')} :-
                                </div>
                                <p className="desc-message mt-1">
                                    <div dangerouslySetInnerHTML={{ __html: messageDesc }} />
                                </p>
                            </div>
                            <div className='desc-heading-inner'>
                                <div className='desc-heading'>
                                    {t('field.termCond')}  :-
                                </div>
                                <p className="desc-message mt-1">
                                    <div dangerouslySetInnerHTML={{ __html: messageTerm }} />

                                </p>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>


        </>
    );
};

export default DescModal;
