import { configureStore } from '@reduxjs/toolkit';

import vendorSlice from '../slice/vendor/vendorSlice';
import userSlice from '../slice/user/userSlice';
import userAccountDetailsSlice from '../slice/user/userAccountDetailsSlice';
import footerSlice from '../slice/user/footerSlice';
import userReferalSlice from '../slice/user/userReferalSlice';
const store = configureStore({
  reducer: {
    favouriteServices: userSlice,
    vendor: vendorSlice,
    userAccountDetails: userAccountDetailsSlice,
    userFooterDetails: footerSlice,
    userReferralsDetails: userReferalSlice,
    userCashBackDetails: userReferalSlice,
  },
});
export default store;