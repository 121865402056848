import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Locale = () => {
  const { t, i18n } = useTranslation('global');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [count, setCount] = useState(0);

  const handleChangeLanguage = async (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    setCount((prevCount) => prevCount + 1);
  };

  return {
    t,
    selectedLanguage,
    handleChangeLanguage,
    count,
  };
};
