import { useEffect, useState ,Locale,DateTime} from '../../../libraries';
import {
  editVendorBookings,
  getBookingById,
  handleErrorResponse,
  handleSuccessResponse,
  viewMember,
  createBlockTime,
  deleteBlockTime,
  getBlockTimeMember,
  showBlockTimeMember,
  updateBlockTime,
} from '../../../services/index';
import { GetOrganizationStatus } from '../index';
const useCalender = () => {
  const { isOrganizationStatus } = GetOrganizationStatus();
  const [events, setEvents] = useState([]);
  const [editEventId, setEditEventId] = useState('');
  const [isNewEvent, setIsNewEvent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalBooking, setShowModalBooking] = useState(false);
  const [teamMembers, setTeamMembers] = useState([
    { value: 'All', lable: 'All' },
  ]);
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString('en-GB'),
  );
  const [teamMemberForFilter, setTeamMemberForFilter] = useState([]);
  const isTeamMember = localStorage.getItem('role') === 'TeamMember';
  const id = localStorage.getItem('userId');
  const [selectedEvent, setSelectedEvent] = useState({
    team_member_id: '',
    date: '',
    start: '',
    end: '',
    comment: '',
  });
  const [teamMemberFilter, setTeamMemberFilter] = useState('');
  const [formError, setFormErrore] = useState({
    team_member_id: '',
    start: '',
    end: '',
    date: '',
    comment: '',
  });
  const [formValues, setFormValues] = useState({
    customerName: '',
    serviceName: '',
    duration: '',
    listedPrice: '',
    paidAmount: '',
    discount: '',
    paymentType: '',
    status: '',
    scheduleDate: '',
    teamMember: '',
    scheduleTime: '',
  });
  const [formErrors, setFormErrors] = useState({
    customerName: '',
    serviceName: '',
    duration: '',
    listedPrice: '',
    paidAmount: '',
    discount: '',
    paymentType: '',
    status: '',
    scheduleDate: '',
    teamMember: '',
    scheduleTime: '',
  });
  const [dayName, setDayName] = useState();
  const [timeSlots, setTimeSlots] = useState();
  const [vendorOpenignHours, setVendorOpenignHours] = useState();
  const [bussinessHours, setBussinessHours] = useState();
  const [loader, setLoader] = useState(false);
  const handleChange = (name, value) => {
    if (name === 'scheduleDate') {
      const dateObj = new Date(value);
      const options = { weekday: 'long' };
      const dayName = dateObj
        .toLocaleDateString('en-US', options)
        .toLocaleLowerCase();
      setDayName(dayName);
      generateTimeSlots(dayName);
    }

    const errorLogs = validateBooking({
      ...formValues, // Pass existing form values
      [name]: value, // Update the current field being changed
    });
    setFormErrore(errorLogs);
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const generateTimeSlots = async (tempDayName) => {
    if (tempDayName) {
      setDayName(tempDayName);
    }
    const currentDayName = tempDayName || dayName;
    if (currentDayName && bussinessHours) {
      const selectedDayData =
        await bussinessHours[currentDayName.toLowerCase()];

      if (!selectedDayData || !selectedDayData.shop_open) {
        setTimeSlots([]);
        return;
      }

      const openTime = selectedDayData.open;
      const closeTime = selectedDayData.close;

      // Use Luxon to handle timezone conversion and time slot generation
      let startTime = DateTime.fromFormat(openTime, 'HH:mm', {
        zone: 'Asia/Bangkok',
      });
      const endTime = DateTime.fromFormat(closeTime, 'HH:mm', {
        zone: 'Asia/Bangkok',
      });

      const timeSlots = [];
      while (startTime <= endTime) {
        const formattedTime = startTime.toFormat('HH:mm');
        timeSlots.push(formattedTime);
        startTime = startTime.plus({ minutes: 30 });
      }

      setTimeSlots(timeSlots);
    } else {
      // Use Luxon to generate a full day's worth of time slots in Bangkok timezone
      let startTime = DateTime.now().setZone('Asia/Bangkok').startOf('day');

      const timeSlots = [];
      for (let i = 0; i < 48; i++) {
        const formattedTime = startTime.toFormat('HH:mm');
        timeSlots.push(formattedTime);
        startTime = startTime.plus({ minutes: 30 });
      }

      setTimeSlots(timeSlots);
    }
  };

  const vendorOpenignHoursFunction = (bussiness_hours) => {
    setBussinessHours(bussiness_hours);
    let newBuissnessDay = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    if (bussiness_hours) {
      setVendorOpenignHours((prevState) => ({
        ...prevState,
        monday:
          bussiness_hours.monday && bussiness_hours.monday.shop_open === 'true'
            ? true
            : false,
        tuesday:
          bussiness_hours.tuesday &&
          bussiness_hours.tuesday.shop_open === 'true'
            ? true
            : false,
        wednesday:
          bussiness_hours.wednesday &&
          bussiness_hours.wednesday.shop_open === 'true'
            ? true
            : false,
        thursday:
          bussiness_hours.thursday &&
          bussiness_hours.thursday.shop_open === 'true'
            ? true
            : false,
        friday:
          bussiness_hours.friday && bussiness_hours.friday.shop_open === 'true'
            ? true
            : false,
        saturday:
          bussiness_hours.saturday &&
          bussiness_hours.saturday.shop_open === 'true'
            ? true
            : false,
        sunday:
          bussiness_hours.sunday && bussiness_hours.sunday.shop_open === 'true'
            ? true
            : false,
      }));
    } else {
      setVendorOpenignHours(newBuissnessDay);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateBooking(formValues);
    setFormErrors(errors);
    if (Object.values(errors).every((value) => value === '')) {
      handleCloseModalBooking();
      setLoader(true);
      let params = {
        booking_date: formValues.scheduleDate,
        status: formValues.status.toLowerCase(),
        team_member_id: parseInt(formValues.teamMember),
        booking_time: formValues.scheduleTime,
      };
      try {
        const response = await editVendorBookings(editEventId, params);
        const booking = await getBookingById(editEventId);
        handleSuccessResponse(response, response.data.message);

        let date = booking.data.booking_service.booking_date.split('-');
        let year = parseInt(date[0]);
        let month = parseInt(date[1]) - 1;
        let day = parseInt(date[2]);

        let bookingTime = new Date(booking.data.booking_service.booking_time);
        // Convert booking time to Thailand time
        let thailandTime = new Date(bookingTime.getTime() + 90 * 60000); // adding 1 hour and 30 minutes
        let startHour = thailandTime.getHours();
        let startMinute = thailandTime.getMinutes();

        const newBooking = {
          id: booking.data.booking_service.id,
          title:
            booking.data.team_member_name + ` (${t('userBooking.confirmed')})`,
          checkStatus: '(confirmed)',
          start: new Date(year, month, day, startHour, startMinute),
          end: new Date(year, month, day, startHour, startMinute),
          teamMemberId: booking.data.booking_service.team_member_id,
          isBlocktime: false,
        };
        setEvents(
          events.map((event) =>
            event.id === editEventId ? newBooking : event,
          ),
        );
      } catch (err) {
        handleErrorResponse(err);
      } finally {
        setLoader(false);
      }
    }
  };

  const validateBooking = (values) => ({
    status: values.status ? '' : 'Status is required',
    teamMember: values.teamMember ? '' : 'Team member is required',
  });

  const { t } = Locale();

  const onChange = (value) => {
    setTeamMemberFilter(value);
    getMembers(currentDate, value);
  };

  const onChangeBlockTime = (type, value) => {
    if (type === 'block') handleInputChange('team_member_id', value);
    handleChange('teamMember', value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const getMembers = async (currentDate, team) => {
    setLoader(true);
    setTeamMemberFilter(team);
    let params = {
      date: currentDate,
      team_member_id: team === 'All' ? '' : team,
    };
    try {
      const blockTimeMember = await getBlockTimeMember(params);
      let updatedData = [];
      if (blockTimeMember.data.unavailable_members) {
        updatedData = blockTimeMember.data.unavailable_members.map((item) => {
          let date = item.unavailable_date.split('-');
          let year = parseInt(date[0]);
          let month = parseInt(date[1]) - 1;
          let day = parseInt(date[2]);

          let fromTime = new Date(item.from_time);
          let toTime = new Date(item.end_time);

          // Convert fromTime to Thailand time
          let thailandFromTime = new Date(fromTime.getTime() + 90 * 60000); // adding 1 hour and 30 minutes
          let startHour = thailandFromTime.getHours();
          let startMinute = thailandFromTime.getMinutes();

          // Convert toTime to Thailand time
          let thailandToTime = new Date(toTime.getTime() + 90 * 60000); // adding 1 hour and 30 minutes
          let endHour = thailandToTime.getHours();
          let endMinute = thailandToTime.getMinutes();
          return {
            id: item.id,
            title: isTeamMember
              ? `${t('calender.unavailabe')}`
              : item.team_member.name + `${t('calender.unavailabe')}`,
            checkStatus: 'red',
            start: new Date(year, month, day, startHour, startMinute),
            end: new Date(year, month, day, endHour, endMinute),
            teamMemberId: isTeamMember ? id : item.team_member.id,
            isBlocktime: true,
          };
        });
        setEvents(updatedData);
      }
      if (blockTimeMember.data.bookings) {
        const updatedDataBooking = blockTimeMember?.data?.bookings?.map((item) => {
          let date = item.booking.booking_date.split('-');
          let year = parseInt(date[0]);
          let month = parseInt(date[1]) - 1;
          let day = parseInt(date[2]);

          let bookingTime = new Date(item.booking.booking_time);

          // Convert booking time to Thailand time
          let thailandTime = new Date(bookingTime.getTime() + 90 * 60000); // adding 1 hour and 30 minutes
          let startHour = thailandTime.getHours();
          let startMinute = thailandTime.getMinutes();

          return {
            id: item.booking.id,
            title:
              !isTeamMember && item.booking.team_member_id
                ? `${item.team_member} (${item.booking.status === 'cancelled' ? 'cancel' : item.booking.status})`
                : `(${item.booking.status === 'cancelled' ? 'Cancel' : item.booking.status})`,
            checkStatus: `(${item.booking.status === 'cancelled' ? 'Cancel' : item.booking.status})`,
            start: new Date(year, month, day, startHour, startMinute),
            end: new Date(year, month, day, startHour, startMinute),
            teamMemberId: item.booking.team_member_id,
            isBlocktime: false,
          };
        });
        setEvents([...updatedData, ...updatedDataBooking]);
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getMembers(currentDate, '');
        if (!isTeamMember) {
          const response = await viewMember();
          if (response.data.team_members) {
            const data = response.data.team_members.map((item) => ({
              value: item.id,
              label: item.full_name,
            }));
            setTeamMembers((prevTeamMembers) => [...prevTeamMembers, ...data]);
            setTeamMemberForFilter(data);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    isOrganizationStatus === 'verified' && fetchData();
  }, [isOrganizationStatus]);

  const convertToThailandTime = (time) => {
    return DateTime.fromISO(time, { zone: 'Asia/Bangkok' }).toFormat('HH:mm');
  };

  const handleSelectEvent = async (event) => {
    try {
      if (event.isBlocktime) {
        const editEvent = await showBlockTimeMember(event.id);
        setEditEventId(event.id);
        setSelectedEvent({
          team_member_id: editEvent.data.unavailable_member.team_member.id,
          date: editEvent.data.unavailable_member.unavailable_date,
          start: convertToThailandTime(
            editEvent.data.unavailable_member.from_time,
          ),
          end: convertToThailandTime(
            editEvent.data.unavailable_member.end_time,
          ),
          comment: editEvent.data.unavailable_member.comment,
        });
        setShowModal(true);
      } else {
        const bookingEvent = await getBookingById(event.id);
        setEditEventId(event.id);

        // Convert booking time to Thailand timezone
        const bookingTime = bookingEvent.data.booking_service.booking_time;
        const dateTimeInBangkok = DateTime.fromISO(bookingTime, {
          zone: 'Asia/Bangkok',
        });
        const formattedTime = dateTimeInBangkok.toFormat('HH:mm');

        setFormValues({
          customerName: bookingEvent.data.customer_name,
          serviceName: bookingEvent.data.service_name_en,
          duration: bookingEvent.data.duration,
          listedPrice: bookingEvent.data.booking_service.price,
          paidAmount: bookingEvent.data.paid_amount,
          discount: bookingEvent.data.total_discount,
          paymentType: bookingEvent.data.payment_type,
          status: bookingEvent.data.booking_service.status,
          scheduleDate: bookingEvent.data.booking_service.booking_date,
          teamMember: bookingEvent.data.booking_service.team_member_id,
          scheduleTime: formattedTime,
        });

        const dateObj = new Date(
          bookingEvent.data.booking_service.booking_date,
        );
        const options = { weekday: 'long' };
        const dayName = dateObj
          .toLocaleDateString('en-US', options)
          .toLocaleLowerCase();
        setDayName(dayName);
        vendorOpenignHoursFunction(bookingEvent.data.vendor_business_hours);
        setShowModalBooking(true);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedEvent({
      start: slotInfo.start,
      end: slotInfo.end,
      title: '',
    });
    setIsNewEvent(true);
  };

  const handleCloseModal = () => {
    setFormErrore({
      team_member_id: '',
      start: '',
      end: '',
      date: '',
      comment: '',
    });
    setSelectedEvent(null);
    setShowModal(false);
    setIsNewEvent(false);
  };

  const handleCloseModalBooking = () => {
    setFormErrors({
      team_member_id: '',
      start: '',
      end: '',
      date: '',
      comment: '',
    });
    setFormValues({
      team_member_id: '',
      date: '',
      start: '',
      end: '',
      comment: '',
    });
    setShowModalBooking(false);
    setSelectedEvent(null);
  };

  const handleEventDelete = async () => {
    try {
      handleCloseModal();
      setLoader(true);
      const response = await deleteBlockTime(editEventId);
      handleSuccessResponse(response, response.data.message);
      setEvents(events.filter((event) => event.id !== editEventId));
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setLoader(false);
    }
  };

  const handleEventSave = async (e) => {
    e.preventDefault();
    const errors = validate(selectedEvent);
    setFormErrore(errors);
    if (Object.values(errors).every((value) => value === '')) {
      handleCloseModal();
      setLoader(true);
      const params = {
        team_member_id: isTeamMember ? id : selectedEvent.team_member_id,
        comment: selectedEvent.comment,
        unavailable_date: selectedEvent.date,
        from_time: selectedEvent.start,
        end_time: selectedEvent.end,
      };
      if (isNewEvent) {
        try {
          const response = await createBlockTime(params);
          let date =
            response.data.unavailable_member.unavailable_date.split('-');
          let year = date[0] * 1;
          let month = date[1] * 1 - 1;
          let day = parseInt(date[2]);
          let startDate = new Date(response.data.unavailable_member.from_time)
            .toISOString()
            .substring(11, 16)
            .split(':');
          let endTime = new Date(response.data.unavailable_member.end_time)
            .toISOString()
            .substring(11, 16)
            .split(':');
          let endh = parseInt(endTime[0]);
          let endm = parseInt(endTime[1]);
          let hour = parseInt(startDate[0]);
          let min = parseInt(startDate[1]);
          const obj = {
            id: response.data.unavailable_member.id,
            title: isTeamMember
              ? `${t('calender.unavailabe')}`
              : response.data.unavailable_member.team_member.name +
                `${t('calender.unavailabe')}`,
            checkStatus: 'red',
            start: new Date(year, month, day, hour, min),
            end: new Date(year, month, day, endh, endm),
            teamMemberId: response.data.unavailable_member.team_member.id,
            isBlocktime: true,
          };
          setEvents([...events, obj]);
          handleSuccessResponse(response, response.data.message);
        } catch (error) {
          handleErrorResponse(error);
        } finally {
          setLoader(false);
        }
      } else {
        try {
          const response = await updateBlockTime(editEventId, params);
          handleSuccessResponse(response, response.data.message);
          let date =
            response.data.unavailable_member.unavailable_date.split('-');
          let year = date[0] * 1;
          let month = date[1] * 1 - 1;
          let day = parseInt(date[2]);
          let startDate = new Date(response.data.unavailable_member.from_time)
            .toISOString()
            .substring(11, 16)
            .split(':');
          let endTime = new Date(response.data.unavailable_member.end_time)
            .toISOString()
            .substring(11, 16)
            .split(':');

          let endh = parseInt(endTime[0]);
          let endm = parseInt(endTime[1]);
          let hour = parseInt(startDate[0]);
          let min = parseInt(startDate[1]);
          const newObj = {
            id: response.data.unavailable_member.id,
            title: isTeamMember
              ? `${t('calender.unavailabe')}`
              : response.data.unavailable_member.team_member.name +
                `${t('calender.unavailabe')}`,
            checkStatus: 'red',
            start: new Date(year, month, day, hour, min),
            end: new Date(year, month, day, endh, endm),
            teamMemberId: response.data.unavailable_member.team_member.id,
            isBlocktime: true,
          };
          setEvents(
            events.map((event) => (event.id === editEventId ? newObj : event)),
          );
        } catch (error) {
          handleErrorResponse(error);
        } finally {
          setLoader(false);
        }
      }
    }
  };

  const validate = (values) => {
    const errors = {
      team_member_id: '',
      start: '',
      end: '',
      date: '',
      comment: '',
    };
    if (!isTeamMember)
      if (!values?.team_member_id) {
        errors.team_member_id = t('calenderValidation.selectTeamMember');
      }
    if (!values?.start) {
      errors.start = t('calenderValidation.startTime');
    }
    if (!values?.end) {
      errors.end = t('calenderValidation.endTime');
    }
    if (!values?.date) {
      errors.date = t('calenderValidation.date');
    }
    if (!values?.comment) errors.comment = t('calenderValidation.comment');
    if (values?.start && values?.end) {
      const startTime = new Date(`01/01/2000 ${values.start}`);
      const endTime = new Date(`01/01/2000 ${values.end}`);
      if (endTime <= startTime) {
        errors.end = t('calenderValidation.timeGreater');
      }
    }
    return errors;
  };

  const handleInputChange = (name, value) => {
    const errorLogs = validateBooking({
      ...formValues, // Pass existing form values
      [name]: value, // Update the current field being changed
    });
    setFormErrore(errorLogs);
    setSelectedEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddNewEvent = () => {
    setSelectedEvent(null);
    setIsNewEvent(true);
    setShowModal(true);
  };

  const isDatePast = (date) => {
    const currentDate = new Date();
    const eventDate = new Date(date);
    return eventDate < currentDate;
  };

  const fetchDataForMonthView = async (endDate) => {
    setCurrentDate(endDate);
    getMembers(endDate, teamMemberFilter);
  };
  useEffect(() => {
    if (dayName && isOrganizationStatus === 'verified') generateTimeSlots();
  }, [dayName]);

  return {
    loader,
    timeSlots,
    vendorOpenignHours,
    onChangeBlockTime,
    onChange,
    filterOption,
    handleCloseModalBooking,
    showModalBooking,
    handleSubmit,
    handleChange,
    formErrors,
    formValues,
    teamMemberForFilter,
    handleCloseModal,
    selectedEvent,
    teamMembers,
    showModal,
    isNewEvent,
    events,
    formError,
    handleSelectEvent,
    handleSelectSlot,
    handleEventDelete,
    handleEventSave,
    handleInputChange,
    handleAddNewEvent,
    isDatePast,
    fetchDataForMonthView,
    isOrganizationStatus,
  };
};

export default useCalender;