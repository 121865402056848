import './AddToFavourite.css';
import { React, useState, Locale, Pagination, Rate } from '../../libraries/index.js';
import { CustomSpinner, ShareButtons, Heading, EmptyComponent } from '../../common';
import {CustomShare, AddToFavouriteSettingHeaders } from '../../components';
import { VendorHeader } from '../../vendor/pages';
import { DefaultShop } from '../../assets/Images';
import { HomeRoutes } from '../../routes/index.js';
import { CustomImage } from '../../lazyComponent/index.js';
import { encryptData,organizationUrlFormat } from '../../customHooks/index.js';
const Share_url = process.env.REACT_APP_SHARE_URL;

const AddToFavourite = ({
  filterValue,
  total_pages,
  handlePaginationChange,
  isLoader,
  isErroPage,
  favouriteVendor,
  handleLike,
  viewAllFunction,
}) => {
  const { t } = Locale();
  const [shareUrl, setShareUrl] = useState();
  const [title, setTitle] = useState();
  const shareItems = [
    {
      key: '1',
      label: <ShareButtons url={shareUrl} title={title} />,
    },
  ];
  console.log('shareUrl--------:',shareUrl)
  const selectedLanguage = localStorage.getItem('i18nextLng')
  const deletionRequest = localStorage.getItem('status') === 'requested';

  const handleShareUrlChange = (service) => {
    const encryptedId = encryptData(service?.organization_id);
    console.log('encryptedId----:',encryptedId)
    const formatOrganizationName = organizationUrlFormat(service?.organization_name);
    setShareUrl(
      `${Share_url}${HomeRoutes.serviceDescription}/${encryptedId}/${formatOrganizationName}`,
    );
    setTitle(service?.organization_name);

  };

  const getOrganizationName = (shop) => {
    return (selectedLanguage === 'thai') ? ((shop.organization_thai) ? (shop.organization_thai) : (shop.organization_name)) : (shop.organization_name)
  }

  return (
    <>
      <div
        className={`heading-outer-div user-outer-div ${deletionRequest && 'deletionRequest-user-header'}`}
      >
        <VendorHeader />
        <Heading text={t('vendor.favorite')} icon="bi-suit-heart" />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="user-common-box-fix-content row-box">
                <AddToFavouriteSettingHeaders />
              </div>
              <div
                className={
                  !isLoader
                    ? `add-to-fav-container add-to-inner-fav-container mt-2 user-inner-content-scrollar user-inner-content-scrollar-after-fix-content`
                    : 'd-none'
                }
              >
                <div className="container-fluid add-to-fav-container3 mb-4">
                  <div className="mb-4  h-auto">
                    <>
                      {favouriteVendor && favouriteVendor.length > 0 && (
                        <div className="row mb-2 user-favourite-shop-row">
                          {favouriteVendor.map((service, index) => (
                            <div
                              className="col-lg-6 col-md-6 mt-3"
                              key={index}
                            >
                              <div className="shop-cart-dd">
                                <div className="user-img-review">
                                  <div className="image-shop-outer-div">
                                    <CustomImage
                                      src={service?.image_url || DefaultShop}
                                      alt="f"
                                      width={'unset'}
                                      height={'unset'}
                                      onClick={() =>
                                        viewAllFunction(
                                          service?.organization_id,
                                          service?.organization_name,
                                        )
                                      }
                                      className="cursor-point-class"
                                    />
                                  </div>
                                  <div className="user-text-review">
                                    <p
                                      onClick={() =>
                                        viewAllFunction(
                                          service?.organization_id,
                                          service?.organization_name,
                                        )
                                      }
                                      className="display-service-heading cursor-point-class"
                                    >
                                      {getOrganizationName(service)}
                                    </p>
                                    <div className="shop-review-addres-rating shop-favourite-outer-p">
                                      <div className="shop-card-address review-shop-rating-web">
                                        <Rate
                                          allowHalf
                                          disabled
                                          value={service.vendor_average_rating}
                                        ></Rate>
                                      </div>
                                      <p>
                                        <span className="shop-card-address">
                                          {service?.location}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="shop-icons favourite-shop-action">
                                      <span className="shop-card-heart-shop">
                                        <i
                                          className={`bi bi-suit-heart-fill  ${service?.isLiked ? 'liked' : ''
                                            }`}
                                          onClick={() =>
                                            handleLike(service?.favorite_id)
                                          }
                                        ></i>
                                      </span>
                                      <span>
                                        <CustomShare
                                          className="shop-card-share-shop"
                                          placement={'bottom'}
                                          shareChange={() =>
                                            handleShareUrlChange(service)
                                          }
                                          items={shareItems}
                                        />
                                      </span>

                                      <div className="review-shop-rating-mobile">
                                        <Rate
                                          allowHalf
                                          disabled
                                          value={service.vendor_average_rating}
                                        ></Rate>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {total_pages > 15 && (
                        <div className="text-center">
                          <Pagination
                            current={filterValue?.page_number}
                            pageSize={filterValue?.per_page}
                            total={total_pages}
                            showSizeChanger={false}
                            defaultCurrent={3}
                            onChange={handlePaginationChange}
                          />
                        </div>
                      )}
                      {isErroPage && (
                        <>
                          <EmptyComponent type="isShop" />
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoader && (
          <>
            <CustomSpinner />
          </>
        )}
      </div>
    </>
  );
};
export default AddToFavourite;