import { apiCall } from '../base';

const getPayoutOneYear = async () => {
  let response = await apiCall('business/payouts', 'GET');
  return response;
};

const getPayoutMonthlyReport = async (params) => {
  let response = await apiCall(
    `business/payouts/monthly?month=${params.month}`,
    'GET',
  );
  return response;
};

const getPayoutDaillyTransaction = async (params) => {
  let response = await apiCall(
    `business/payouts/transaction?${`month=${params.month}`}&${`page_number=${params.page_number}`}&${`per_page=${params.per_page}`}`,
    'GET',
  );
  return response;
};

const downloadRecordFile = async (params) => {
  let response = await apiCall(
    `business/payouts/reports?month=${params}`,
    'GET',
  );
  return response;
};

export {
  getPayoutOneYear,
  getPayoutMonthlyReport,
  getPayoutDaillyTransaction,
  downloadRecordFile,
};
