import { React, useEffect, useState } from "../../libraries";
import { CustomSpinner, Footer } from "../../common";
import { NavbarLogic } from "../../components";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb.js";
import { CustomImage } from "../../lazyComponent";
import { MetaTag } from "../../customHooks/index.js";
const TermConditionPage = ({ data, BradCrumbData, isLoading }) => {
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const [privacy, setPrivacy] = useState(0);
  useEffect(() => { }, [selectedLanguage, privacy]);

  const removeHtmlTags = (input) => {
    // Replace <br> tags with actual newlines
    let formattedInput = input.replace(/<br\s*\/?>/gi, '\n');

    // Replace <p> tags with newlines for proper paragraph separation
    formattedInput = formattedInput.replace(/<\/?p[^>]*>/gi, '\n');

    // Remove <wbr> tags
    formattedInput = formattedInput.replace(/<wbr\s*\/?>/gi, '');

    // Replace &nbsp; with regular spaces
    formattedInput = formattedInput.replace(/&nbsp;/gi, ' ');

    // Parse the rest of the string and remove other tags
    const doc = new DOMParser().parseFromString(formattedInput, "text/html");

    // Return clean text with preserved line breaks and spaces
    return doc.body.textContent || "";
  };


  const getBusinessPartnerPolicyDescitpion = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? removeHtmlTags(data?.terms_and_condition?.description_in_thai)
      : removeHtmlTags(data?.terms_and_condition?.description);
  };

  const getBusinessPartnerPolicyTitle = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? data?.terms_and_condition?.title_in_thai
      : data?.terms_and_condition?.title;
  };



  const getSubContentSubHeading = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? removeHtmlTags(data?.sub_content?.sub_heading_in_thai)
      : removeHtmlTags(data?.sub_content?.sub_heading);
  };

  const getSubContentDesc = (data) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? removeHtmlTags(data?.sub_content?.description_in_thai)
      : removeHtmlTags(data?.sub_content?.description);
  };

  return (
    <>
      <MetaTag custom_title={data?.terms_and_condition?.title} custom_description={data?.terms_and_condition?.description} />
      <NavbarLogic setPrivacy={setPrivacy} />
      <div className="container about-us-outer-container">
        <BreadCrumb data={BradCrumbData} />
        {!(isLoading) ? <>
          <div className="aboutus-img-div">
            <div className="row">
              {data?.terms_and_condition_image_url && (
                <CustomImage
                  src={data?.terms_and_condition_image_url}
                  className="img-fluid"
                  alt="business_partner_image"
                  width={"unset"}
                  height={"unset"}
                />
              )}
              <div
                className={`${data?.terms_and_condition_image_url
                  ? "aboutus-title text-center"
                  : "text-center title-business"
                  }`}
              >
                {data && getBusinessPartnerPolicyTitle(data)}
              </div>
            </div>
          </div>

          <div className="row mt-3">
            {data && (
              <div>
                {getBusinessPartnerPolicyDescitpion(data)
                  .split('\n')
                  .map((line, index) => (
                    <p key={index} className="description_in_thai">{line}</p>
                  ))}
              </div>
            )}
          </div>
          {data?.terms_and_condition_sub_content_data?.map((data, index) => (
            <div key={index} className="row mt-5 mb-5">
              {data?.sub_content_image_url && (
                <>
                  <div
                    className={`col-md-6 ${index % 2 === 0 ? "" : "order-md-2"}`}
                  >
                    <CustomImage
                      src={data?.sub_content_image_url}
                      className="img-fluid"
                      alt="sub_content_image_url"
                      width={"unset"}
                      height={"unset"}
                    />
                  </div>
                  <div
                    className={`col-md-6 ${index % 2 === 0 ? "" : "order-md-1"}`}
                  >
                    <div>
                      {getSubContentSubHeading(data)
                        .split('\n')
                        .map((line, index) => (
                          <p key={index} className="sub-heading-content">{line}</p>
                        ))}
                      {getSubContentDesc(data)
                        .split('\n')
                        .map((line, index) => (
                          <p key={index} className="description_in_thai">{line}</p>
                        ))}
                    </div>
                  </div>
                </>
              )}
              {!data?.sub_content_image_url && (
                <div className="col-md-12">
                  <div>
                    {getSubContentSubHeading(data)
                      .split('\n')
                      .map((line, index) => (
                        <p key={index} className="sub-heading-content">{line}</p>
                      ))}
                    {getSubContentDesc(data)
                      .split('\n')
                      .map((line, index) => (
                        <p key={index} className="description_in_thai">{line}</p>
                      ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </> : <>
          <CustomSpinner />
        </>}
      </div>
      <div className={`${(isLoading) ? 'loader-blog-section' : ''}`}>
        <Footer />
      </div>
    </>
  );
};
export default TermConditionPage;
