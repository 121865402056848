import { apiCall } from '../base';

const topServices = async (param) => {
  const response = await apiCall(
    !param
      ? 'business/dashboards/best_selling_services'
      : `business/dashboards/best_selling_services?filter=${param.filter}`,
    'GET',
  );
  return response;
};

const upComingBooking = async () => {
  const response = await apiCall('business/dashboards/upcoming_booking', 'GET');
  return response;
};

const totalRevenue = async () => {
  const response = await apiCall('business/dashboards/revenue', 'GET');
  return response;
};

const filtertotalRevenue = async (year) => {
  const response = await apiCall(
    `business/dashboards/revenue?year=${year}`,
    'GET',
  );
  return response;
};

const customerRatings = async () => {
  const response = await apiCall('business/dashboards/customer_rating', 'GET');
  return response;
};

export {
  topServices,
  upComingBooking,
  customerRatings,
  totalRevenue,
  filtertotalRevenue,
};
