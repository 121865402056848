import './EditBookingModal.css';
import { React, useEffect,PropTypes,DateTime,useCallback, DatePicker, Option, Select,Locale, dayjs, moment } from '../../../../libraries';
import { CustomModal } from '../../../../common';
import { getBookingById } from '../../../../services';
import { useEditBooking } from '../../../component';

const EditBooking = ({
  setLoader,
  teamMemberArrayForm,
  blankFeild,
  services,
  customers,
  isNewBooking,
  id,
  bookings,
  setBookings,
  closeEditBookingModal,
}) => {
  const { t } = Locale();
  const {
    setFormErrors,
    handleChangeNewBooking,
    onChange,
    formErrorsNewBooking,
    formValuesNewBooking,
    handleSubmitBooking,
    formValues,
    setFormValues,
    handleChange,
    handleSubmit,
    setFormErrorsNewBooking,
    setFormValuesNewBooking,
    onChangeNewBooking,
    formErrors,
    vendorOpenignHoursFunction,
    timeSlots,
    setDayName,
    vendorOpenignHours,
  } = useEditBooking({
    id,
    bookings,
    setBookings,
    closeEditBookingModal,
    setLoader,
  });

  const editBooking = async () => {
    const showBooking = await getBookingById(id);
    const bookingTime = showBooking.data.booking_service.booking_time;
    const bookingDate = showBooking.data.booking_service.booking_date;
    const dateTimeInBangkok = DateTime.fromISO(bookingTime, {
      zone: 'Asia/Bangkok',
    });
    const formattedTime = dateTimeInBangkok.toFormat('HH:mm');
    setFormValues({
      customerName: showBooking.data.customer_name,
      serviceName: showBooking.data.service_name_en,
      duration: showBooking.data.duration,
      listedPrice: showBooking.data.booking_service.price,
      paidAmount: showBooking.data.paid_amount,
      discount: showBooking.data.total_discount,
      paymentType: showBooking.data.payment_type,
      status: showBooking.data.booking_service.status,
      scheduleDate: bookingDate,
      teamMember: showBooking.data.booking_service.team_member_id || '',
      scheduleTime: formattedTime,
    });

    const dateObj = new Date(bookingDate);
    const options = { weekday: 'long' };
    const dayName = dateObj
      .toLocaleDateString('en-US', options)
      .toLocaleLowerCase();
    setDayName(dayName);

    if (showBooking.data.vendor_business_hours) {
      vendorOpenignHoursFunction(showBooking.data.vendor_business_hours);
    }
  };

  const setFormErrorsFun = () => {
    setFormErrors({
      customerName: '',
      serviceName: '',
      duration: '',
      listedPrice: '',
      paidAmount: '',
      discount: '',
      paymentType: '',
      status: '',
      scheduleDate: '',
      teamMember: '',
    });
  };

  useEffect(() => {
    setFormErrorsFun();
    editBooking();
  }, [id]);

  const setFormValuesAndErr = useCallback(() => {
    setFormValuesNewBooking({
      customerName: '',
      serviceName: '',
      duration: '',
      listedPrice: '',
      paidAmount: '',
      discount: '',
      paymentType: 'online',
      status: 'confirmed',
      scheduleDate: '',
      teamMember: '',
      time: '',
    });
    setFormErrorsNewBooking({
      customerName: '',
      serviceName: '',
      duration: '',
      listedPrice: '',
      paidAmount: '',
      discount: '',
      paymentType: '',
      status: '',
      scheduleDate: '',
      teamMember: '',
      time: '',
    });
  }, [setFormErrorsNewBooking, setFormValuesNewBooking]);

  useEffect(() => {
    setFormValuesAndErr();
  }, [blankFeild, setFormValuesAndErr]);
  return (
    <>
      {isNewBooking ? (
        <CustomModal
          text={
            <form className="form">
              <div className="edit-booking-header">
                <h5 className="title">{t('venderBooking.newBooking')}</h5>
              </div>
              <div className="container mt-2">
                <div className="row">
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span className="">{t('venderBooking.customer')}</span>
                      <span className="custom-estric">*</span>
                      <Select
                        placeholder={`${t('header.select')} ${t('venderBooking.customer')}`}
                        value={formValuesNewBooking?.customerName || undefined}
                        onChange={(value) =>
                          onChangeNewBooking('customerName', value)
                        }
                        className="vendor-edit-ant-select-form mt-1"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {customers &&
                          customers.map((item) => (
                            <Select.Option
                              key={item.customer.id}
                              value={item.customer.id}
                            >
                              {item.customer.first_name +
                                ' ' +
                                item.customer.last_name}
                            </Select.Option>
                          ))}
                      </Select>
                      <div className="error">
                        {formErrorsNewBooking?.customerName}
                      </div>
                    </label>
                  </div>
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span>{t('venderBooking.service')}</span>
                      <span className="custom-estric">*</span>
                      <Select
                        placeholder={`${t('header.select')} ${t('venderBooking.service')}`}
                        value={formValuesNewBooking?.serviceName || undefined}
                        onChange={(value) =>
                          onChangeNewBooking('serviceName', value)
                        }
                        className="vendor-edit-ant-select-form mt-1"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {services &&
                          services.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                      <div className="error">
                        {formErrorsNewBooking?.serviceName}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span className="">{t('ServiceField.duration')}</span>
                      <input
                        disabled={true}
                        id="duration"
                        type="text"
                        placeholder={t('ServiceField.duration')}
                        value={formValuesNewBooking?.duration}
                        onChange={(e) =>
                          handleChangeNewBooking('duration', e.target.value)
                        }
                        className="form-input-booking"
                      />
                    </label>
                  </div>
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span>{t('venderBooking.listedPrice')}</span>
                      <input
                        disabled={true}
                        id="listedPrice"
                        type="text"
                        placeholder={t('venderBooking.listedPrice')}
                        value={formValuesNewBooking?.listedPrice}
                        onChange={(e) =>
                          handleChangeNewBooking('listedPrice', e.target.value)
                        }
                        className="form-input-booking"
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span className="">{t('cart.discount')}</span>
                      <input
                        disabled={true}
                        id="discount"
                        type="text"
                        placeholder={t('cart.discount')}
                        value={formValuesNewBooking?.discount}
                        onChange={(e) =>
                          handleChangeNewBooking('discount', e.target.value)
                        }
                        className="form-input-booking"
                      />
                    </label>
                  </div>
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span>{t('venderBooking.paidAmount')}</span>
                      <input
                        disabled={true}
                        id="paidAmount"
                        type="text"
                        placeholder={t('venderBooking.paidAmount')}
                        value={formValuesNewBooking?.paidAmount}
                        onChange={(e) =>
                          handleChangeNewBooking('paidAmount', e.target.value)
                        }
                        className="form-input-booking"
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span className="">{t('venderBooking.paymentType')}</span>
                      <span className="custom-estric">*</span>
                      <Select
                        placeholder={t('header.select')}
                        value={formValuesNewBooking?.paymentType || undefined}
                        onChange={(value) =>
                          handleChangeNewBooking('paymentType', value)
                        }
                        className="vendor-edit-ant-select-form mt-1"
                      >
                        <Select.Option value="online">
                          {t('userBooking.payAtShop')}
                        </Select.Option>
                        <Option value="online">
                          {t('userBooking.payOnline')}
                        </Option>
                      </Select>
                      <div className="error">
                        {formErrorsNewBooking?.paymentType}
                      </div>
                    </label>
                  </div>
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span>{t('ServiceField.status')}</span>
                      <span className="custom-estric">*</span>
                      <Select
                        value={formValuesNewBooking?.status || undefined}
                        onChange={(value) =>
                          handleChangeNewBooking('status', value)
                        }
                        className="vendor-edit-ant-select-form mt-1"
                        placeholder="Select"
                      >
                        <Select.Option value="confirmed">
                          {t('userBooking.confirmed')}
                        </Select.Option>
                        <Select.Option value="completed">
                          {t('userBooking.completed')}
                        </Select.Option>
                      </Select>
                      <div className="error">
                        {formErrorsNewBooking?.status}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span className="">
                        {t('venderBooking.scheduledDate')}
                      </span>
                      <input
                        id="schedule-date"
                        type="date"
                        value={formValuesNewBooking?.scheduleDate}
                        onChange={(e) =>
                          handleChangeNewBooking('scheduleDate', e.target.value)
                        }
                        className="form-input-booking"
                        placeholder="Select Date"
                        min={new Date().toISOString().split('T')[0]}
                      />
                      <div className="error">
                        {formErrorsNewBooking?.scheduleDate}
                      </div>
                    </label>
                  </div>
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span>{t('venderBooking.teamMember')}</span>
                      <span className="custom-estric">*</span>
                      <Select
                        placeholder={t('calender.selectTeamMember')}
                        value={formValuesNewBooking?.teamMember || undefined}
                        onChange={(value) =>
                          onChangeNewBooking('teamMember', value)
                        }
                        className="vendor-edit-ant-select-form mt-1"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {teamMemberArrayForm &&
                          teamMemberArrayForm.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.full_name}
                            </Select.Option>
                          ))}
                      </Select>
                      <div className="error">
                        {formErrorsNewBooking?.teamMember}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="form-group mt-2 col-12">
                  <label className="width100">
                    <span>{t('venderBooking.time')}</span>
                    <span className="custom-estric">*</span>
                    <input
                      id="time"
                      type="time"
                      value={formValuesNewBooking?.time}
                      onChange={(e) =>
                        handleChangeNewBooking('time', e.target.value)
                      }
                      className="form-input-booking mt-1"
                    />
                    <div className="error">{formErrorsNewBooking?.time}</div>
                  </label>
                </div>
              </div>

              <div className="form-group mt-5">
                <center>
                  <input
                    type="button"
                    value={t('button.cancelButton')}
                    className="form-cancel-booking"
                    onClick={closeEditBookingModal}
                  />
                  <input
                    disabled={formValues?.status === 'cancelled_by_customer'}
                    type="submit"
                    onClick={handleSubmitBooking}
                    value={t('button.update')}
                    className="form-submit-booking"
                  />
                </center>
              </div>
            </form>
          }
        />
      ) : (
        <CustomModal
          text={
            <form className="form">
              <div className="edit-booking-header">
                <h5 className="title">{t('venderBooking.editBooking')}</h5>
              </div>
              <div className="container mt-2">
                <div className="row">
                  <div className="form-group mt-2 col-md-6 col-12">
                    <label className="width100">
                      <span className="">
                        {t('venderBooking.scheduledDate')}
                      </span>
                      <span className="custom-estric">*</span>
                    </label>
                    <DatePicker
                      placeholder="Select Date"
                      onChange={(date, dateString) =>
                        handleChange('scheduleDate', dateString)
                      }
                      value={
                        formValues?.scheduleDate &&
                        dayjs(formValues?.scheduleDate, {
                          format: 'YYYY-MM-DD',
                        })
                      }
                      disabledDate={(current) => {
                        if (current && current < moment().startOf('day')) {
                          return true;
                        }
                        if (
                          (current &&
                            !vendorOpenignHours?.sunday &&
                            current.day() === 0) ||
                          (!vendorOpenignHours.monday && current.day() === 1) ||
                          (!vendorOpenignHours.tuesday &&
                            current.day() === 2) ||
                          (!vendorOpenignHours.wednesday &&
                            current.day() === 3) ||
                          (!vendorOpenignHours.thursday &&
                            current.day() === 4) ||
                          (!vendorOpenignHours.friday && current.day() === 5) ||
                          (!vendorOpenignHours.saturday && current.day() === 6)
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      className="user-profile-date-picker-ant form-control mt-1"
                    />
                    <div className="error">{formErrors?.scheduleDate}</div>
                  </div>
                  <div className="form-group mt-2 col-lg-6 col-md-6 col-12">
                    <label>
                      <span>{t('ServiceField.time')}</span>
                      <span className="custom-estric">*</span>
                    </label>
                    <Select
                      value={formValues?.scheduleTime}
                      onChange={(value) => handleChange('scheduleTime', value)}
                      className="mt-1 user-edit-booking-time-dropdown"
                      placeholder="Select Schedule Time"
                    >
                      {timeSlots &&
                        timeSlots.map((time, index) => (
                          <Select.Option key={index} value={time}>
                            {time}
                          </Select.Option>
                        ))}
                    </Select>
                    <div className="error">{formErrors?.scheduleTime}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mt-2 col-12">
                      <label>
                        <span>{t('venderBooking.teamMember')}</span>
                        <span className="custom-estric">*</span>
                      </label>
                      <Select
                        placeholder={t('calender.selectTeamMember')}
                        value={formValues?.teamMember || undefined}
                        onChange={onChange}
                        className="vendor-edit-ant-select-form mt-1"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {teamMemberArrayForm &&
                          teamMemberArrayForm.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.full_name}
                            </Select.Option>
                          ))}
                      </Select>
                      <div className="error">{formErrors?.teamMember}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mt-5">
                <center>
                  <input
                    type="button"
                    value={t('button.cancelButton')}
                    className="form-cancel-booking"
                    onClick={closeEditBookingModal}
                  />
                  <input
                    disabled={formValues?.status === 'cancelled_by_customer'}
                    type="submit"
                    onClick={handleSubmit}
                    value={t('button.update')}
                    className="form-submit-booking"
                  />
                </center>
              </div>
            </form>
          }
        />
      )}
    </>
  );
};
EditBooking.propTypes = {
  teamMemberArrayForm: PropTypes.array,
  blankFeild: PropTypes.any,
  services: PropTypes.array,
  customers: PropTypes.array,
  isNewBooking: PropTypes.bool,
  id: PropTypes.number,
  bookings: PropTypes.array,
  setBookings: PropTypes.func,
  closeEditBookingModal: PropTypes.func,
};
export default EditBooking;