import { useEffect, useState, useLocation } from '../../../libraries';
import { filterVendorReview, getAllVendorReview, handleErrorResponse } from '../../../services/index';
import { GetOrganizationStatus } from '../index';
import { Review } from '../../pages';

const ReviewLogic = ({deletionRequest}) => {
  const location = useLocation();
  const { isOrganizationStatus } = GetOrganizationStatus();
  const [isReviewRating, setIsReviewRating] = useState(false);
  const [vendorRatings, setVendorRatings] = useState();
  const [vendorReview, setVendorReview] = useState([]);
  const [totalReview, setTotalReview] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const [isEmptyPage, setIsEmptyPage] = useState(false);
  let organization_id = localStorage.getItem('orgId');
  const [filter, setFilter] = useState('recent');
  const isSection = localStorage.getItem('isSection');

  useEffect(() => {
    if (isSection !== 'isBusinessReview') {
      localStorage.setItem('isSection', 'isBusinessReview');
    }
  }, [location.pathname]);

  const fetchVendorReview = async (organization_id) => {
    let response;
    try {
      setIsEmptyPage(false);
      setIsLoader(true);
      response = await getAllVendorReview(organization_id);
      if (response.status === 204) {
        setIsEmptyPage(true);
      } else {
        setIsReviewRating(true);
        setVendorRatings(response?.data?.ratings);
        setVendorReview(response?.data?.review_comment);
        setTotalReview(response?.data?.verfied_reviews_count);
      }
    } catch (err) {
      if (err?.response?.data?.status_code === 204) {
        setIsReviewRating(false);
        setIsEmptyPage(true);
      }
      if (err?.response?.data?.status_code === 404) {
        handleErrorResponse(err);
      }
    } finally {
      setIsLoader(false);
    }
  };
  const handleFilter = async (e, filterType) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      setFilter(filterType);
      let param = {};
      param.id = localStorage.getItem('orgId');
      if (filterType === 'lowest') param.filterValue = 'low';
      else if (filterType === 'highest') param.filterValue = 'high';
      else param.filterValue = 'all';
      let response = await filterVendorReview(param);
      setIsReviewRating(true);
      setVendorRatings(response?.data?.ratings);
      setVendorReview(response?.data?.review_comment);
      setTotalReview(response?.data?.verfied_reviews_count);
    } catch (err) {
      if (err?.response?.data?.error === 'No Reviews Found !!!') {
        setIsReviewRating(false);
      }
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (isOrganizationStatus === 'verified') fetchVendorReview(organization_id);
    else setIsLoader(false);
  }, [isOrganizationStatus]);

  return (
    <>
      <Review
      deletionRequest={deletionRequest}
        isEmptyPage={isEmptyPage}
        setIsEmptyPage={setIsEmptyPage}
        isReviewRating={isReviewRating}
        isOrganizationStatus={isOrganizationStatus}
        filter={filter}
        handleFilter={handleFilter}
        isLoader={isLoader}
        totalReview={totalReview}
        vendorRatings={vendorRatings}
        vendorReview={vendorReview}
      />
    </>
  );
};
export default ReviewLogic;
