import { apiCall } from '../base.js';

const userNotification = async () => {
    const response = await apiCall(`broadcast/notifications`, 'GET');
    return response;
};

const markAllRead = async () => {
    const response = await apiCall(`broadcast/mark_all_read`, 'PUT');
    return response;
};
const accountInformation = async () => {
    const response = await apiCall('authentication/user_details', 'GET');
    return response;
};
const editProfile = async (params) => {
    const response = await apiCall('customer/me', 'PATCH', params);
    return response;
};
const showUserDetails = async () => {
    const response = await apiCall('authentication/user_details', 'GET');
    return response;
};
const showVendorRatings = async (params) => {
    let response = await apiCall(
      `/public/ratings?organization_id=${params}`,
      'GET',
    );
    return response;
  };
  const changePassword = async (params) => {
    const response = await apiCall(
      'authentication/change_password',
      'PATCH',
      params,
    );
    return response;
  };
  
export {changePassword, userNotification, markAllRead, accountInformation, editProfile, showUserDetails ,showVendorRatings};