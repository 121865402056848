import {
  React,
  Locale,
  useEffect,
  useLocation,
  useState,
  toast,
} from '../../../libraries/index';
import { Finance } from '../../pages/index';
import {
  isRequired,
  isRequiredImage,
  isRequiredSelect,
  isValidEmail1,
  isValidNumber,
  isValidPhoneNumber,
} from '../../pages/Validation/Validation';
import {
  handleErrorResponse,
  handleSuccessResponse,
  createBillingInformation,
  fetchBillingInformation,
  updateBillingInformation,
} from '../../../services/index';
import { AddressJson, CountryCodeJson } from '../../../jsonData';
import { CancelDeletionAction } from '../../../lazyComponent';

const Finances = ({deletionRequest,setDeletionRequest}) => {
  const { t } = Locale();
  const location = useLocation();
  const initialValues = {
    title: '',
    taxId: '',
    billingAddress: '',
    country: '',
    contactName: '',
    email: '',
    phone: '',
    billingContactPhone: '',
    accountHolderName: '',
    accountNumber: '',
    bankBranchCode: '',
    iban: '',
    bankName: '',
    bankAddress: '',
    bankCountry: '',
    postal_code: '',
    bank_postal_code: '',
    countrycode: '+66(Thailand)',
    ccountrycode: '+66(Thailand)',
    cheque: '',
    chequeName: '',
  };

  const previousValue = {
    title: '',
    taxId: '',
    billingAddress: '',
    country: '',
    contactName: '',
    email: '',
    phone: '',
    billingContactPhone: '',
    accountHolderName: '',
    accountNumber: '',
    bankBranchCode: '',
    iban: '',
    bankName: '',
    bankAddress: '',
    bankCountry: '',
    postal_code: '',
    bank_postal_code: '',
    countrycode: '+66(Thailand)',
    ccountrycode: '+66(Thailand)',
    cheque: '',
    chequeName: '',
  };
  const [previousFormValue, setPreviousFormValue] = useState(previousValue)
  const [billingInformationResponse, setBillingInformationResponse] = useState(null);
  const [formValue, setFormValue] = useState(initialValues);
  const [formError, setFormError] = useState({});
  const [uploadedCheque, setUploadedCheque] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [countryCode, setCountryCode] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [country, setCountry] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isActiveBtn, setIsActiveBtn] = useState(false);
  const isSection = localStorage.getItem('isSection');
  const deletionRequestStatus = localStorage.getItem('deletionRequestStatus') === 'requested'

  useEffect(() => {
    if (isSection !== 'isBusinessFinance') {
      localStorage.setItem('isSection', 'isBusinessFinance');
    }
  }, [location.pathname]);

  useEffect(() => {
    billingInformation();
    const countryCodeArray = CountryCodeJson.map((item) => {
      return {
        value: item.dial_code,
        label: item.dial_code + ' (' + item.name + ')',
      };
    });

    const countryArray = CountryCodeJson.map((item) => {
      return { value: item.name, label: item.name };
    });

    const uniquePostalCodes = [
      ...new Set(AddressJson.map((item) => item.postalCode)),
    ];
    const postalCodeArray = uniquePostalCodes.map((postalCode) => ({
      value: postalCode,
      label: postalCode.toString(),
    }));

    setPostalCode(postalCodeArray);
    setCountry(countryArray);
    setCountryCode(countryCodeArray);
  }, []);

  useEffect(() => {
    if (
      billingInformationResponse &&
      billingInformationResponse?.billing_information
    ) {
      let phone_number =
        billingInformationResponse?.billing_information &&
        billingInformationResponse?.billing_information &&
        billingInformationResponse?.billing_information?.phone_number &&
        billingInformationResponse?.billing_information?.phone_number.split(' ')
          .length > 1 &&
        billingInformationResponse.billing_information.phone_number

          .split(' ')[1]
          .toString();

      let c_phone_number =
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information.contact_number &&
        billingInformationResponse.billing_information.contact_number &&
        billingInformationResponse.billing_information.contact_number.split(' ')
          .length > 1 &&
        billingInformationResponse.billing_information.contact_number
          .split(' ')[1]
          .toString();
      let tempCountryCode =
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information.phone_number &&
        billingInformationResponse.billing_information.phone_number.split(' ')
          .length > 1 &&
        billingInformationResponse.billing_information.phone_number
          .split(' ')[0]
          .toString();
      let tempConCountryCode =
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information &&
        billingInformationResponse.billing_information.contact_number &&
        billingInformationResponse.billing_information.contact_number.split(' ')
          .length > 1 &&
        billingInformationResponse.billing_information.contact_number
          .split(' ')[0]
          .toString();

      const updateValue = {
        accountHolderName:
          billingInformationResponse.billing_information.account_holder_name,
        title: billingInformationResponse.billing_information.company_name,
        taxId: billingInformationResponse.billing_information.vat_number,
        billingAddress:
          billingInformationResponse.billing_information.billing_address,
        country: billingInformationResponse.billing_information.country,
        contactName:
          billingInformationResponse.billing_information.contact_name,
        email: billingInformationResponse.billing_information.email,
        phone: phone_number,
        billingContactPhone: c_phone_number,
        accountNumber:
          billingInformationResponse.billing_information.account_number,
        bankBranchCode:
          billingInformationResponse.billing_information.bank_branch_code,
        iban: billingInformationResponse.billing_information.iban,
        bankName: billingInformationResponse.billing_information.bank_name,
        bankAddress:
          billingInformationResponse.billing_information.bank_address,
        bankCountry:
          billingInformationResponse.billing_information.bank_country,
        postal_code: billingInformationResponse.billing_information.postal_code,
        bank_postal_code:
          billingInformationResponse.billing_information.bank_postal_code,
        countrycode: tempCountryCode ? tempCountryCode : '+66',
        ccountrycode: tempConCountryCode ? tempConCountryCode : '+66',

        cheque:
          billingInformationResponse.image_url &&
          billingInformationResponse.image_url,
        chequeName: billingInformationResponse?.file_name,
      }
      setFormValue(updateValue);
      setPreviousFormValue(updateValue)
    }
  }, [billingInformationResponse]);

  const billingInformation = async () => {
    try {
      let response = await fetchBillingInformation();
      setBillingInformationResponse(response?.data);
      if (response?.data?.status === 200 || response?.data?.status === 201)
        setIsUpdate(true);
      setIsLoader(false);
      handleSuccessResponse(response);
    } catch (err) {
      setIsLoader(false);
      if (err?.response?.data?.status_code === 404) {
        handleErrorResponse(err);
      }
    }
  };

  const handleChangeSelect = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
    const error = getValidationHandleChange(name, value);
    setFormError(error);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = getValidationHandleChange(name, value);
    setFormError(error)
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Check if file is an image (excluding PDFs)
      if (!file.type.includes('pdf')) {
        const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
        if (file.size > maxSize) {
          // File size is greater than the maximum limit
          const errorMessage = `${t('validation.imageSize')}`;
          setFormError((prevErrors) => ({
            ...prevErrors,
            cheque: errorMessage,
          }));
          setUploadedCheque(null);
          setFormValue((prev) => ({
            ...prev,
            cheque: null,
          }));

          return;
        }
      }

      setFormValue((prevValues) => ({
        ...prevValues,
        cheque: file,
      }));
      setUploadedCheque(URL.createObjectURL(file));
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const checkIsActiveBtn = (error) => {
    // Check if there are no errors
    const hasNoErrors = Object.keys(error).length === 0;

    // Check if all fields are filled
    const allFieldsFilled = Object.keys(formValue)
      .filter(key => key !== 'chequeName') // Exclude 'chequeName'
      .every(key => formValue[key] !== '');

    // Set button active if there are no errors and all fields are filled
    setIsActiveBtn(hasNoErrors && allFieldsFilled);
  };

  // Call checkIsActiveBtn whenever formValue or error changes
  useEffect(() => {
    checkIsActiveBtn(formError);
  }, [formValue, formError]);

  const getValidationHandleChange = (name, value, uploadedCheque) => {
    // Make a copy of the current form errors
    let error = { ...formError };

    // Clear the error for the specific field before validating it
    if (error.hasOwnProperty(name)) {
      delete error[name];
    }

    // Validate the current field and update errors
    switch (name) {
      case 'billingContactPhone':
        error = isValidPhoneNumber(
          value,
          error,
          'billingContactPhone',
          `${t('financeField.billContactPhoneNumber')}`,
          '',
          t
        );
        break;
      case 'phone':
        error = isValidPhoneNumber(
          value,
          error,
          'phone',
          `${t('field.phoneNumber')}`,
          '',
          t
        );
        break;
      case 'title':
        error = isRequired(
          value,
          error,
          'title',
          `${t('financeField.companyTitle')}`,
          t
        );
        break;
      case 'taxId':
        error = isValidNumber(
          value,
          error,
          'taxId',
          `${t('financeField.taxId')}`,
          t
        );
        break;
      case 'postal_code':
        error = isRequiredSelect(
          value,
          error,
          'postal_code',
          `${t('field.postalCode')}`,
          t
        );
        break;
      case 'billingAddress':
        error = isRequired(
          value,
          error,
          'billingAddress',
          `${t('financeField.billingAddress')}`,
          t
        );
        break;
      case 'country':
        error = isRequiredSelect(
          value,
          error,
          'country',
          `${t('financeField.country')}`,
          t
        );
        break;
      case 'contactName':
        error = isRequired(
          value,
          error,
          'contactName',
          `${t('financeField.contactName')}`,
          t
        );
        break;
      case 'email':
        error = isValidEmail1(value, error, 'email', t);
        break;
      case 'accountHolderName':
        error = isRequired(
          value,
          error,
          'accountHolderName',
          `${t('financeField.accountHolderName')}`,
          t
        );
        break;
      case 'accountNumber':
        error = isRequired(
          value,
          error,
          'accountNumber',
          `${t('financeField.accountNumber')}`,
          t
        );
        break;
      case 'bankBranchCode':
        error = isRequired(
          value,
          error,
          'bankBranchCode',
          `${t('financeField.bankBranchCode')}`,
          t
        );
        break;
      case 'iban':
        error = isRequired(value, error, 'iban', 'IBAN', t);
        break;
      case 'bankName':
        error = isRequired(
          value,
          error,
          'bankName',
          `${t('financeField.bankName')}`,
          t
        );
        break;
      case 'bank_postal_code':
        error = isRequiredSelect(
          value,
          error,
          'bank_postal_code',
          `${t('financeField.postalCode')}`,
          t
        );
        break;
      case 'bankAddress':
        error = isRequired(
          value,
          error,
          'bankAddress',
          `${t('financeField.bankAddress')}`,
          t
        );
        break;
      case 'bankCountry':
        error = isRequiredSelect(
          value,
          error,
          'bankCountry',
          `${t('financeField.country')}`,
          t
        );
        break;
      case 'cheque':
        error = isRequiredImage(
          uploadedCheque,
          error,
          'cheque',
          `${t('financeField.cheque')}`,
          value,
          t
        );
        break;
      default:
        break;
    }

    return error;
  };

  const checkIfUpdated = () => {
    return JSON.stringify(formValue) !== JSON.stringify(previousFormValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    errors = validate(formValue, uploadedCheque);
    setFormError(errors);
    if (Object.keys(errors).length === 0) {
      setButtonDisabled(true);
      const formData = await appendData(formValue);
      try {
        let response;
        if (!isUpdate) {
          try {
            response = await createBillingInformation(formData);
            handleSuccessResponse(response, response.data.message);
            setIsUpdate(true);
            setButtonDisabled(false);
          } catch (err) {
            handleErrorResponse(err);
          }
        } else {
          setButtonDisabled(true);
          if (checkIfUpdated()) {
            response = await updateBillingInformation(formData);
            handleSuccessResponse(response, response.data.message);
          }
          else {
            toast.error(t('userEdit.noUpdateMessage'));
        }
          setButtonDisabled(false);
        }
      } catch (err) {
        handleErrorResponse(err);
        setButtonDisabled(false);
      }
    }
  };

  const validate = (formValue, uploadedCheque) => {
    let error;

    error = isValidPhoneNumber(
      formValue.billingContactPhone,
      { ...error },
      'billingContactPhone',
      `${t('financeField.billContactPhoneNumber')}`,
      '',
      t,
    );
    error = isValidPhoneNumber(
      formValue.phone,
      error,
      'phone',
      `${t('field.phoneNumber')}`,
      '',
      t
    );
    error = isRequired(
      formValue.title,
      { ...error },
      'title',
      `${t('financeField.companyTitle')}`,
      t,
    );
    error = isValidNumber(
      formValue.taxId,
      { ...error },
      'taxId',
      `${t('financeField.taxId')}`,
      t,
    );
    error = isRequiredSelect(
      formValue.postal_code,
      { ...error },
      'postal_code',
      `${t('field.postalCode')}`,
      t,
    );
    error = isRequired(
      formValue.billingAddress,
      { ...error },
      'billingAddress',
      `${t('financeField.billingAddress')}`,
      t,
    );
    error = isRequiredSelect(
      formValue.country,
      { ...error },
      'country',
      `${t('financeField.country')}`,
      t,
    );
    error = isRequired(
      formValue.contactName,
      { ...error },
      'contactName',
      `${t('financeField.contactName')}`,
      t,
    );
    error = isValidEmail1(formValue.email, { ...error }, 'email', t);
    error = isRequired(
      formValue.accountHolderName,
      { ...error },
      'accountHolderName',
      `${t('financeField.accountHolderName')}`,
      t,
    );
    error = isRequired(
      formValue.accountNumber,
      { ...error },
      'accountNumber',
      `${t('financeField.accountNumber')}`,
      t,
    );
    error = isRequired(
      formValue.bankBranchCode,
      { ...error },
      'bankBranchCode',
      `${t('financeField.bankBranchCode')}`,
      t,
    );
    error = isRequired(formValue.iban, { ...error }, 'iban', 'IBAN', t);
    error = isRequired(
      formValue.bankName,
      { ...error },
      'bankName',
      `${t('financeField.bankName')}`,
      t,
    );
    error = isRequiredSelect(
      formValue.bank_postal_code,
      { ...error },
      'bank_postal_code',
      `${t('financeField.postalCode')}`,
      t,
    );
    error = isRequired(
      formValue.bankAddress,
      { ...error },
      'bankAddress',
      `${t('financeField.bankAddress')}`,
      t,
    );
    error = isRequiredSelect(
      formValue.bankCountry,
      { ...error },
      'bankCountry',
      `${t('financeField.country')}`,
      t,
    );
    error = isRequiredImage(
      uploadedCheque,
      { ...error },
      'cheque',
      `${t('financeField.cheque')}`,
      formValue.cheque,
      t,
    );
    return error;
  };

  const appendData = (formValue) => {
    const formData = new FormData();
    formData.append('company_name', formValue.title);
    formData.append('vat_number', formValue.taxId);
    formData.append('postal_code', formValue.postal_code);
    formData.append('country', formValue.country);
    formData.append('billing_address', formValue.billingAddress);
    formData.append('contact_name', formValue.contactName);
    formData.append('email', formValue.email);
    let removeCountryNamePhoneCountryCode = formValue.countrycode
      ? formValue.countrycode.split('(')[0].trim() // Extract country code and remove leading/trailing whitespace
      : '+66';

    formData.append(
      'phone_number',
      removeCountryNamePhoneCountryCode + ' ' + formValue.phone,
    );

    let removeCountryNameConCountryCode = formValue.ccountrycode
      ? formValue.ccountrycode.split('(')[0].trim() // Extract country code and remove leading/trailing whitespace
      : '+66';

    formData.append(
      'contact_number',
      removeCountryNameConCountryCode + ' ' + formValue.billingContactPhone,
    );

    formData.append('account_holder_name', formValue.accountHolderName);
    formData.append('account_number', formValue.accountNumber);
    formData.append('bank_branch_code', formValue.bankBranchCode);
    formData.append('bank_name', formValue.bankName);
    formData.append('iban', formValue.iban);
    formData.append('bank_address', formValue.bankAddress);
    formData.append('bank_postal_code', formValue.bank_postal_code);
    formData.append('bank_country', formValue.bankCountry);
    if (typeof formValue.cheque !== 'string') {
      formData.append('cheque', formValue.cheque);
    }
    return formData;
  };

  const onChange = (name, value) => handleChangeSelect(name, value);

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());



  return (
    <>
      {deletionRequestStatus ? (
        <>
          <CancelDeletionAction
            setDeletionRequest={setDeletionRequest}
            deletionRequest={deletionRequest}
          />
        </>
      ) : (
      <Finance
      deletionRequest={deletionRequest}
        isActiveBtn={isActiveBtn}
        isLoader={isLoader}
        postalCode={postalCode}
        country={country}
        onChange={onChange}
        filterOption={filterOption}
        countryCode={countryCode}
        isUpdate={isUpdate}
        formError={formError}
        formValue={formValue}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        uploadedCheque={uploadedCheque}
        buttonDisabled={buttonDisabled}
      />
    )}

    </>
  );
};
export default Finances;
