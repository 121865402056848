import './customDropDown.css';
import { React, useEffect, Link, Dropdown,Locale, Space, useNavigate } from '../../libraries/index';
const CustomDropDown = ({
  isSection,
  items,
  color,
  fontSize,
  placement,
  className,
}) => {
  const { t } = Locale();
  const navigate = useNavigate();
  useEffect(() => { }, [t]);

  const setLocalStorageSection = (e, path, section) => {
    e.preventDefault();
    isSection && localStorage.setItem('isSection', section);
    navigate(path);
  };
  const dropdownMenu = (
    <div className={'custom-dropdown-menu'}>
      {items &&
        items.map((item, index) => (
          <div key={item.key} className="custom-dropdown-item">
            {item.onClick ? (
              <span onClick={item.onClick}>{item.label}</span>
            ) : (
              <Link
                onClick={(e) =>
                  setLocalStorageSection(e, item.path, item.section)
                }
                className="navbar-signin-dropdow-toggle"
              >
                {item.label}
              </Link>
            )}
            {index < items.length - 1 && (
              <hr className={'custom-dropdown-divider mt-2'} />
            )}
          </div>
        ))}
    </div>
  );
  return (
    <>
      <Dropdown overlay={dropdownMenu} placement={placement}>
        <div className="web-navbar-language-image-tag">
          <i class="bi bi-translate"></i>
          <Space
            className={className}
            style={{ color: color, fontSize: fontSize }}
          >
            <div className="downArrow" style={{ color: color }}>
              <i className="bi bi-chevron-down"></i>
            </div>
          </Space>
        </div>
      </Dropdown>
    </>
  );
};
export default CustomDropDown;