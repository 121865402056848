import { Locale, Select, useSelector,useEffect, useState } from '../../../libraries';
import { handleChangeValue } from '../../component';

const WorkingHours = ({
  openingHourseFormValue,
  setOpeningHourseFormValue,
}) => {
  const { t } = Locale();
  const [value, setValue] = useState([]);
  const [day, setDay] = useState([]);
  const [thaiDay, setThaiDay] = useState([]);
  const { vendorAccoutnDetails } = useSelector((state) => state.vendor);

  useEffect(() => {
    setOpeningHourseFormValue((prevFormValue) => ({
      ...prevFormValue,
      bussiness_hours: vendorAccoutnDetails.organization.bussiness_hours,
    }));
  }, [vendorAccoutnDetails, setOpeningHourseFormValue]); // Include missing dependencies

  useEffect(() => {
    let changeDay = [
      t('ServiceField.mon'),
      t('ServiceField.tue'),
      t('ServiceField.wed'),
      t('ServiceField.thu'),
      t('ServiceField.fri'),
      t('ServiceField.sat'),
      t('ServiceField.sun'),
    ];

    let newDay = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    let newValue = [
      '00:00',
      '00:30',
      '01:00',
      '01:30',
      '02:00',
      '02:30',
      '03:00',
      '03:30',
      '04:00',
      '04:30',
      '05:00',
      '05:30',
      '06:00',
      '06:30',
      '07:00',
      '07:30',
      '08:00',
      '08:30',
      '09:00',
      '09:30',
      '10:00',
      '10:30',
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00',
      '19:30',
      '20:00',
      '20:30',
      '21:00',
      '21:30',
      '22:00',
      '22:30',
      '23:00',
      '23:30',
    ];

    setValue(newValue);
    setDay(newDay);
    setThaiDay(changeDay);
  }, [t]); // Include missing dependency 't'

  const getClassName = (currentDay) => {
    const isOpen =
      (vendorAccoutnDetails?.organization?.bussiness_hours &&
        vendorAccoutnDetails?.organization?.bussiness_hours[
          currentDay.toLowerCase()
        ]?.shop_open === 'true') ||
      vendorAccoutnDetails?.organization?.bussiness_hours?.[
        currentDay.toLowerCase()
      ]?.shop_open === true;
    return `${!isOpen ? 'd-none' : ''}`;
  };

  return (
    <div className="workingHourseDayDiv">
      {day &&
        day.length > 0 &&
        day.map((currentDay, key) => (
          <div className={`row mt-2 ${getClassName(currentDay)}`} key={key}>
            <div className="col-4 openingHourseDayDiv  mt-2">
              <input
                type="checkbox"
                readOnly
                className={`form-check-input openingHourse-day-checkbox `}
                id={`myCheckbox-${currentDay}`}
                name={`bussiness_hours[${currentDay?.toLowerCase()}].shop_open`}
                checked={
                  openingHourseFormValue?.bussiness_hours?.[
                    currentDay.toLowerCase()
                  ]?.['shop_open'] === 'true' ||
                  openingHourseFormValue?.bussiness_hours?.[
                    currentDay.toLowerCase()
                  ]?.['shop_open'] === true
                }
                onChange={(e) =>
                  handleChangeValue(
                    e,
                    setOpeningHourseFormValue,
                    '',
                    '',
                    () => {},
                  )
                }
                disabled={
                  (vendorAccoutnDetails?.organization?.bussiness_hours &&
                    vendorAccoutnDetails?.organization?.bussiness_hours[
                      currentDay.toLowerCase()
                    ]?.shop_open === 'true') ||
                  vendorAccoutnDetails?.organization?.bussiness_hours?.[
                    currentDay.toLowerCase()
                  ]?.shop_open === true
                    ? false
                    : true
                }
              />
              <span className={` mx-2 ${getClassName(currentDay)}`}>
                {thaiDay[key]}
              </span>
            </div>
            <div className={'col-3 '}>
              <Select
                className={`${getClassName(currentDay)}`}
                placeholder={t('header.select')}
                disabled={
                  (openingHourseFormValue.bussiness_hours &&
                    openingHourseFormValue.bussiness_hours[
                      currentDay.toLowerCase()
                    ]?.shop_open === 'true') ||
                  openingHourseFormValue.bussiness_hours?.[
                    currentDay.toLowerCase()
                  ]?.shop_open === true
                    ? false
                    : true
                }
                value={
                  openingHourseFormValue.bussiness_hours &&
                  openingHourseFormValue.bussiness_hours[
                    currentDay.toLowerCase()
                  ]?.open
                }
                onChange={(value) =>
                  handleChangeValue(
                    {
                      target: {
                        name: `bussiness_hours[${currentDay.toLowerCase()}].open`,
                        value,
                      },
                    },
                    setOpeningHourseFormValue,
                    '',
                    '',
                    () => {},
                  )
                }
              >
                {value.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div
              className={`col-2 opening-hourse-to-div ${getClassName(currentDay)} text-center`}
            >
              <div>{t('vendor.to')}</div>
            </div>
            <div className="col-3 vendor-opening-horse-close-time">
              <Select
                className={`${getClassName(currentDay)}`}
                placeholder={t('header.select')}
                disabled={
                  (openingHourseFormValue.bussiness_hours &&
                    openingHourseFormValue.bussiness_hours[
                      currentDay.toLowerCase()
                    ]?.shop_open === 'true') ||
                  openingHourseFormValue.bussiness_hours?.[
                    currentDay.toLowerCase()
                  ]?.shop_open === true
                    ? false
                    : true
                }
                value={
                  openingHourseFormValue.bussiness_hours &&
                  openingHourseFormValue.bussiness_hours[
                    currentDay.toLowerCase()
                  ]?.close
                }
                onChange={(value) =>
                  handleChangeValue(
                    {
                      target: {
                        name: `bussiness_hours[${currentDay.toLowerCase()}].close`,
                        value,
                      },
                    },
                    setOpeningHourseFormValue,
                    '',
                    '',
                    () => {},
                  )
                }
              >
                {value
                  .filter(
                    (item) =>
                      openingHourseFormValue &&
                      openingHourseFormValue?.bussiness_hours &&
                      item >
                        openingHourseFormValue?.bussiness_hours[
                          currentDay.toLowerCase()
                        ]?.open,
                  )
                  .map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
        ))}
    </div>
  );
};
export default WorkingHours;