import { React, Select, Option } from '../../../../libraries/index';
const ServiceFilterRow = ({
  t,
  filterValue,
  handlefilterInputChange,
  categories,
  subCategories,
  clearFilterfunction,
}) => {
  return (
    <tr>
      <th className="thclass thclass-width15">
        <div className="filter-input-feild-div">
          <input
            className="filter-input-feild-service"
            placeholder={t('header.search')}
            value={filterValue.serviceId}
            onChange={(e) =>
              handlefilterInputChange('serviceId', e.target.value)
            }
          />
        </div>
      </th>
      <th className="thclass thclass-width15">
        <Select
          placeholder={t('header.select')}
          value={filterValue?.category || undefined}
          onChange={(value) => handlefilterInputChange('category', value)}
          className="service-filter-select"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {categories &&
            categories.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name_en}
              </Option>
            ))}
        </Select>
      </th>
      <th className="thclass thclass-width15">
        <Select
          placeholder={t('header.select')}
          value={filterValue?.subCategory || undefined}
          onChange={(value) => handlefilterInputChange('subCategory', value)}
          className="service-filter-select"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {subCategories &&
            subCategories.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name_en}
              </Option>
            ))}
        </Select>
      </th>
      <th className="thclass thclass-width9">
        <div className="filter-input-feild-div">
          <input
            className="filter-input-feild-service"
            placeholder={t('header.search')}
            value={filterValue.duration}
            onChange={(e) =>
              handlefilterInputChange('duration', e.target.value)
            }
          />
        </div>
      </th>
      <th className="thclass w-12">
        <div className="filter-input-feild-div">
          <input
            className="filter-input-feild-service form-input-number"
            placeholder={t('header.search')}
            type="number"
            value={filterValue.price}
            onChange={(e) => handlefilterInputChange('price', e.target.value)}
          />
        </div>
      </th>
      <th className="thclass w-12">
        <div className="filter-input-feild-div">
          <input
            className="filter-input-feild-service form-input-number"
            placeholder={t('header.search')}
            type="number"
            value={filterValue.specialPrice}
            onChange={(e) =>
              handlefilterInputChange('specialPrice', e.target.value)
            }
          />
        </div>
      </th>
      <th className="thclass w-10">
        <Select
          id="status"
          placeholder={t('header.select')}
          value={filterValue?.status || undefined}
          onChange={(value) => handlefilterInputChange('status', value)}
          className="service-filter-select"
          optionFilterProp="children"
        >
          <Option value="">{t('ServiceField.all')}</Option>
          <Option value={true}>{t('ServiceField.active')}</Option>
          <Option value={false}>{t('ServiceField.deActive')}</Option>
        </Select>
      </th>
      <th className="thclass  text-center w-12">
        <button onClick={clearFilterfunction} className="btn clear-filter">
          {t('button.clearButton')} {t('ServiceField.filter')}
        </button>
      </th>
    </tr>
  );
};

export default ServiceFilterRow;
