import './empty.css';
import { React, Locale, Link, useNavigate } from '../../libraries/index';
import {
  EmptyCart,
  EmptyServiceImage,
  EmptyShopImage,
} from '../../assets/Images';
import { BusinessRoutes, HomeRoutes } from '../../routes/index'
import { CustomImage } from '../../lazyComponent';
import { urlFormat } from '../../customHooks/index';
const EmptyComponent = ({ type }) => {
  const { t } = Locale();
  const navigate = useNavigate()
  const isOrganization = localStorage.getItem('role') === 'Vendor';
  let content;
  const isImage = () => {
    if (type === 'isCart') {
      content = `${t('breadcrumb.cart')}`;
      return EmptyCart;
    } else if (type === 'isShop') {
      content = `${t('favouritePage.shop')}`;
      return EmptyShopImage;
    } else if (type === 'isService') {
      content = `${t('favouritePage.service')}`;
      return EmptyServiceImage;
    } else if (type === 'isFavourite') {
      content = `${t('header.favourite')}`;
      return EmptyShopImage;
    } else if (type === 'isBooking') {
      content = `${t('vendor.bookings')}`;
      return EmptyShopImage;
    } else if (type === 'pageNotFound') {
      content = `${t('orderHistory.pageNotFound')}`;
      return EmptyShopImage;
    } else if (type === 'notFound') {
      content = `${t('orderHistory.dataNotFound')}`;
      return EmptyShopImage;
    } else if (type === 'isBranch') {
      content = `${t('vendor.branch')}`;
      return EmptyShopImage;
    } else if (type === 'vendorReview') {
      content = `${t('vendorReview.reviews')}`;
      return EmptyShopImage;
    } else if (type === 'teammember') {
      content = `${t('ServiceField.team')} ${t('field.member')}`;
      return EmptyShopImage;
    } else if (type === 'isBlog') {
      content = `${t('field.blog')}`;
      return EmptyShopImage;
    }
  };

  const navigateFunction = (e) => {
    e.preventDefault();
    if (!isOrganization && (type !== 'notFound')) {
      const urlFormateData = urlFormat("Best Sellers")
      navigate(`${HomeRoutes.services}/${urlFormateData}`,
        {
          state:
          {
            showCategoryName: "Best Sellers",
            showCategoryNameThai: 'ขายดี'
          }
        });
    }
    else if (type === 'notFound') {
      navigate(HomeRoutes.home)
    }
    else {
      navigate(`${BusinessRoutes.bussiness}${BusinessRoutes.dashboard}`)
    }
  };

  return (
    <>
      <div className="container-fluid emty-card">
        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="card-body cart mt-4">
                <div className="col-sm-12 empty-cart-cls text-center">
                  <CustomImage
                    alt={'empty_image'}
                    src={isImage()}
                    width="230"
                    height="230"
                    className="img-fluid mb-4 mr-3"
                  />
                  <h4>
                    <strong>
                      {type === 'notFound' || type === 'pageNotFound' ? (
                        content
                      ) : (
                        <>
                          {(type !== 'isBlog') ? <>
                            {t('payment.your')} {content} {t('payment.isEmpty')}
                          </> : <>
                            {t('field.blogEmpty')}
                          </>}
                        </>
                      )}
                    </strong>
                  </h4>
                  <i className="bi bi-arrow-left mt-4 mb-4 "></i>&nbsp;
                  <Link
                    onClick={(e) => navigateFunction(e)}
                    className="back-to-home fa-empty-card"
                  >
                    {isOrganization
                      ? t('button.backToDashboard')
                      : ((type === 'notFound') || (type === 'isBlog')) ? t('button.backToHome')
                        : t('button.backToService')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmptyComponent;