import { CryptoJS, pako } from '../libraries/index'

const cryptoSecretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY;

// Utility to convert array buffer to URL-safe base64
const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

// Compress and encode to URL-safe base64
const compressData = (data) => {
    const jsonData = JSON.stringify(data);
    const compressed = pako.deflate(jsonData, { to: 'string' });
    return arrayBufferToBase64(compressed); // Convert to base64
};

// Utility to convert URL-safe base64 to array buffer
const base64ToArrayBuffer = (base64) => {
    const binaryString = atob(base64.replace(/-/g, '+').replace(/_/g, '/'));
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

// Decompress data after decoding from URL-safe base64
const decompressData = (compressedData, setIsPageNotFound) => {
    const compressedArrayBuffer = base64ToArrayBuffer(compressedData);
    try {
        const decompressed = pako.inflate(new Uint8Array(compressedArrayBuffer), { to: 'string' });
        return JSON.parse(decompressed);
    } catch (error) {
        setIsPageNotFound && setIsPageNotFound(true)
        throw new Error("Data could not be decompressed.");
    }
};

// Encrypt the compressed data
export const encryptData = (data) => {
    const secretKey = cryptoSecretKey;
    const compressedData = compressData(data);
    const encrypted = CryptoJS.AES.encrypt(compressedData, secretKey).toString();
    return encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // URL-safe Base64 encoding
};

// Decrypt the data and decompress
export const decryptData = (encryptedData, setIsPageNotFound) => {
    const secretKey = cryptoSecretKey;
    const decoded = encryptedData?.replace(/-/g, '+').replace(/_/g, '/') + '='; // Add padding
    const bytes = CryptoJS.AES.decrypt(decoded, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    return decompressData(decryptedData, setIsPageNotFound); // Decompress after decryption
};
