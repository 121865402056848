import { React } from '../../libraries';
const CustomDiv = ({ id, text, tag, className, alltitle }) => {
  return (
    <>
      <div id={id} className={className} title={alltitle}>
        {text}
        {tag}{' '}
      </div>
    </>
  );
};
export default CustomDiv;