import React from 'react';
import './PeakDiscountModal.css';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Locale } from '../Locale/Locale';

export const PeakDiscountModal = ({
  showModalDiscount,
  handleCloseDiscountModal,
}) => {
  const { t } = Locale();

  return (
    <>
      <Modal
        show={showModalDiscount}
        onHide={() => handleCloseDiscountModal({ status: 'cancel' })}
        dialogClassName="custom-modal customModal"
      >
        <Modal.Header
          closeButton
          className="headerLine"
          onClick={() => handleCloseDiscountModal({ status: 'cancel' })}
        >
          <p className="discount-modal-heading">Peak Off Discount</p>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="form-floating mb-3">
              <textarea
                name="cancel_eason"
                className="form-control height100px"
                id="floatingTextArea"
              />
              <label htmlFor="floatingTextArea">
                {t('signMessage.commentHere')}
                <span className="gl-form-asterisk">
                  <span className="small-err">*</span>
                </span>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center footerLine">
          <Button
            className="btn btn-light cancelbutton"
            onClick={() => handleCloseDiscountModal({ status: 'cancel' })}
          >
            {t('button.no')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
PeakDiscountModal.propTypes = {
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
  deleteServices: PropTypes.func,
  message: PropTypes.string,
  deleteItemId: PropTypes.string,
};
