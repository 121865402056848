import './ReviewAndRatingsHeader.css';
import { React, PropTypes, Locale } from '../../../libraries';
import { CustomerRoutes } from '../../../routes/index';
const ReviewAndRatingsHeader = ({ isPage, navigateFun }) => {
  const { t } = Locale();
  return (
    <>
      <div className="user-booking-tab-outer-div">
        <span
          onClick={(e) => {
            navigateFun(e, `${CustomerRoutes.user}${CustomerRoutes.reviewShop}`);
          }}
          className={`user-bookings-tab btn ms-1 ${isPage.accountEditPage ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'}`}
        >
          {t('favouritePage.shop')}
        </span>
        <span
          onClick={(e) => {
            navigateFun(e, `${CustomerRoutes.user}${CustomerRoutes.reviewService}`);
          }}
          className={`user-bookings-tab btn ms-1 ${isPage.openingHours ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'}`}
        >
          {t('favouritePage.service')}
        </span>
      </div>
    </>
  );
};
ReviewAndRatingsHeader.propTypes = {
  isPage: PropTypes.shape({
    accountEditPage: PropTypes.bool,
    openingHours: PropTypes.bool,
  }),
};
export default ReviewAndRatingsHeader;