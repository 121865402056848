import { Locale } from "../libraries"

const DiscountPercent = ({ value }) => {
  const { t } = Locale();

  return <>
    <div className="discount-percent-div">
      {value ? (
        <>
          {value} % {t('field.off')}
        </>
      ) : <><br /> </>}
    </div>
  </>
}

export default DiscountPercent