import { apiCall } from '../base.js';

const addMember = async (params) => {
  const response = await apiCall('business/team_members', 'POST', params);
  return response;
};
const viewAllTeamMember = async (params) => {
  const response = await apiCall(
    `business/team_members?page_number=${params.page_number}&per_page=${params.per_page}`,
    'GET',
  );
  return response;
};
const showTeamMember = async (param) => {
  const response = await apiCall(`business/team_members/${param}`, 'GET');
  return response;
};
const updateTeamMember = async (id, params) => {
  const response = await apiCall(`business/team_members/${id}`, 'PUT', params);
  return response;
};
const deleteTeamMember = async (params) => {
  const response = await apiCall(`business/team_members/${params}`, 'DELETE');
  return response;
};
const viewMember = async () => {
  const response = await apiCall('business/members', 'GET');
  return response;
};
export {
  addMember,
  viewAllTeamMember,
  showTeamMember,
  updateTeamMember,
  deleteTeamMember,
  viewMember,
};
