import { useState } from '../../../libraries';
import { handleErrorResponse, handleSuccessResponse, suggestService } from '../../../services';

const SuggestServiceModal = ({ setSpinner }) => {
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const handleSuggestionShow = () => setShowSuggestionModal(true);
  const handleSuggestionClose = () => setShowSuggestionModal(false);

  const handleSuggestion = async (values) => {
    handleSuggestionClose();
    setSpinner(true);
    try {
      const response = await suggestService(values);
      handleSuccessResponse(response, response.data.message);
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setSpinner(false);
    }
  };

  return {
    showSuggestionModal,
    handleSuggestionShow,
    handleSuggestionClose,
    handleSuggestion,
  };
};

export default SuggestServiceModal;
