import './Style.css';
import { React, Locale, Link, PropTypes, useState } from '../../libraries';
import { CustomImage } from '../../common';
const SearchField = ({
  setIsActiveSearch,
  name,
  value,
  placeholder,
  onChange,
  onSubmit,
}) => {
  const { t } = Locale();
  const [isActive, setIsActive] = useState(false);
  const imgSrc =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMCAwaDI0djI0SDB6Ii8+PHBhdGggZmlsbD0iIzc1NzU3NSIgZD0iTTIwIDExSDcuOGw1LjYtNS42TDEyIDRsLTggOCA4IDggMS40LTEuNEw3LjggMTNIMjB6Ii8+PC9nPjwvc3ZnPg==';
  return (
    <>
      <div
        className={`search-box-section ${setIsActiveSearch && 'isTempSearchBoxSeciont'}`}
      >
        <input
          className="search-input"
          autoComplete='off'
          type="search"
          placeholder={placeholder}
          name={name}
          value={value}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSubmit();
            }
          }}
          onChange={onChange}
        />
        <button onClick={onSubmit} className="search-btn">
          <i className="bi bi-search"></i>
        </button>
      </div>
      {setIsActiveSearch && (
        <>
          <div className="search-icon">
            {!isActive && (
              <>
                <i
                  className="bi bi-search search-icon-style"
                  onClick={() => {
                    setIsActive(true);
                    setIsActiveSearch(true);
                  }}
                ></i>
              </>
            )}
            <div className={isActive ? `activeSearch` : `deactiveSearch`}>
              <div className="search-form">
                <Link
                  onClick={() => {
                    setIsActive(false);
                    setIsActiveSearch(false);
                  }}
                  className="_1je_xh"
                >
                  <CustomImage
                    src={imgSrc}
                    alt={'img'}
                    width={'unset'}
                    height={'unset'}
                  />
                </Link>
                <div className="search-box-section-active ">
                  <input
                    className="search-input"
                    type="search"
                    autoComplete='off'
                    placeholder={t("header.placeholderheader")}
                    name={name}
                    value={value}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                    onChange={onChange}
                  />
                  <button onClick={onSubmit} className="search-btn">
                    <i className="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
SearchField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};
export default SearchField;