import { AccountDeletion, AccountDeletionAction, AddCart, AddToFavouriteLogic, AddToFavouriteServiceLogic, CancelDeletionAction, CashbackLogic, ReferralLogic, ReviewRatingServiceLogic, ShowAllShop, UserBookingsList, UserEditProfileForm } from "../../lazyComponent";
import { CustomerRoutes } from "../routes";
const customerRouting = [
    { path: `${CustomerRoutes.editProfile}`, component: UserEditProfileForm },

    { path: `${CustomerRoutes.accountDeletion}`, component: AccountDeletion },
    { path: `${CustomerRoutes.deleteRequest}`, component: AccountDeletionAction },
    { path: `${CustomerRoutes.cancelDeleteRequest}`, component: CancelDeletionAction },

    
    { path: `${CustomerRoutes.bookings}`, component: UserBookingsList },
    { path: `${CustomerRoutes.cashback}`, component: CashbackLogic },
    { path: `${CustomerRoutes.referral}`, component: ReferralLogic },
    { path: `${CustomerRoutes.reviewShop}`, component: ShowAllShop },
    { path: `${CustomerRoutes.reviewService}`, component: ReviewRatingServiceLogic },
    { path: `${CustomerRoutes.favouriteService}`, component: AddToFavouriteServiceLogic },
    { path: `${CustomerRoutes.favouriteShop}`, component: AddToFavouriteLogic },
    { path: `${CustomerRoutes.addToCart}`, component: AddCart },
];
export default customerRouting;