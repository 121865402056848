import { React } from '../../libraries';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  CalendarFilled,
  ClockCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EnvironmentTwoTone,
  FacebookOutlined,
  FilterOutlined,
  GiftOutlined,
  GooglePlusOutlined,
  HeartFilled,
  HeartOutlined,
  HomeOutlined,
  InstagramOutlined,
  ItalicOutlined,
  LeftOutlined,
  LinkedinOutlined,
  LoginOutlined,
  MailOutlined,
  MenuUnfoldOutlined,
  PhoneOutlined,
  RightOutlined,
  ScanOutlined,
  SearchOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';

const CustomIcon = {
  cart: <ShoppingCartOutlined />,
  profile: <UserOutlined />,
  leftArrow: <LeftOutlined />,
  linkedIn: <LinkedinOutlined />,
  facebook: <FacebookOutlined />,
  google: <GooglePlusOutlined />,
  instagram: <InstagramOutlined />,
  like: <HeartOutlined />,
  home: <HomeOutlined />,
  gift: <GiftOutlined />,
  phone: <PhoneOutlined />,
  logout: <LoginOutlined />,
  search: <SearchOutlined />,
  location: <EnvironmentTwoTone />,
  downarrow: <DownOutlined />,
  menu: <MenuUnfoldOutlined />,
  setting: <SettingOutlined />,
  delete: <DeleteOutlined />,
  alignCenter: <AlignCenterOutlined />,
  alignLeft: <AlignLeftOutlined />,
  alignRight: <AlignRightOutlined />,
  bold: <BoldOutlined />,
  italic: <ItalicOutlined />,
  zoomIn: <ZoomInOutlined />,
  zoomOut: <ZoomOutOutlined />,
  scan: <ScanOutlined />,
  calender: <CalendarFilled />,
  mail: <MailOutlined />,
  edit: <EditOutlined />,
  rightArrow: <RightOutlined />,
  clock: <ClockCircleOutlined />,
  heart: <HeartFilled />,
  filter: <FilterOutlined />,
};
export default CustomIcon;