import './shareButton.css'; // Your custom styles
import { React, FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, LineShareButton } from 'react-share'; // Correct path

const ShareButtons = ({ url, title, body, section, referralCode }) => {
  const shareUrl = process.env.REACT_APP_SHARE_URL;
  const image = "https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg";

  // Check if body is provided or not
  const bodyContent = section === 'isRefral' ? `
🌟 Sura360 : Relax, Refresh, Rejuvenate. 🌟
Book your favorite spa or massage services and enjoy exclusive rewards! 💆‍♀️💆‍♂️
📩 Use referral code: ${referralCode || '[Your Referral Code]'}

${`https://www.sura360.com/services/Best-Sellers`}

How to use:
1️⃣ Choose your preferred service.
2️⃣ Register or log in.
3️⃣ Select your preferred date and time.
4️⃣ Use the referral code on the checkout page.
5️⃣ Collect your cashback!

Don’t wait—start sharing the joy of relaxation today!
💌 Download the app now: https://apps.apple.com/us/app/sura360/id6502856298
`
    : `Book your service at Sura360: ${shareUrl}`;

  const separator = "\n\n"; // Custom separator for Whatsapp and Email

  const whatsappTitle = (section === 'isRefral') ?
    `
🌟 Sura360 : Relax, Refresh, Rejuvenate. 🌟
Book your favorite spa or massage services and enjoy exclusive rewards! 💆‍♀️💆‍♂️
📩 Use referral code: ${referralCode || '[Your Referral Code]'}

${`https://www.sura360.com/services/Best-Sellers`}

How to use:
1️⃣ Choose your preferred service.
2️⃣ Register or log in.
3️⃣ Select your preferred date and time.
4️⃣ Use the referral code on the checkout page.
5️⃣ Collect your cashback!

Don’t wait—start sharing the joy of relaxation today!
💌 Download the app now: https://apps.apple.com/us/app/sura360/id6502856298
`
    : title



  return (
    <div className="share-buttons">
      <FacebookShareButton url={(section === 'isRefral') ? 'https://sura360.com/services/Best-Sellers' : url} quote={(section === 'isRefral') ? whatsappTitle : title} hashtag="#Sura360" media={image}>
        <span className="share-button facebook-button">
          <i className="bi bi-facebook"></i>
        </span>
      </FacebookShareButton>

      <TwitterShareButton url={url} title={(section === 'isRefral') ? whatsappTitle : title} via="Sura360" media={image}>
        <span className="share-button twitter-button">
          <i className="bi bi-twitter"></i>
        </span>
      </TwitterShareButton>

      <WhatsappShareButton
        url={url}
        title={whatsappTitle}
        media={image}
        imageUrl="https://www.sura360.com/logo.png"
        imgAlt="sura-logo"
      >
        <span className="share-button whatsapp-button">
          <i className="bi bi-whatsapp"></i>
        </span>
      </WhatsappShareButton>

      <EmailShareButton url={url} subject={title} body={bodyContent} separator={separator} media={image}>
        <span className="share-button email-button">
          <i className="bi bi-envelope-fill"></i>
        </span>
      </EmailShareButton>

      <LineShareButton url={(section === 'isRefral') ? 'https://sura360.com/services/Best-Sellers' : url} title={(section === 'isRefral') ? whatsappTitle : title} media={image}>
        <span className="share-button line-button">
          <i className="bi bi-line"></i>
        </span>
      </LineShareButton>
    </div>
  );
};

export default ShareButtons;
