import { React, PropTypes, Navigate } from '../../libraries';
import { HomeRoutes, AuthRoutes } from '../routes/index';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('access_token');
  const role = localStorage.getItem('role');
  const isServer = localStorage.getItem("isServer");

  if (isServer === "off") {
    return <Navigate to={HomeRoutes.home} />;
  }
  else if (token) {
    return children;
  }
  else {
    return <Navigate to={role ? AuthRoutes.signIn : HomeRoutes.home} />;
  }
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;