const CustomPrevArrow=(props)=>{
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', top: '103%', left: '47%', right: 'unset', transform: 'unset' }}
        onClick={onClick}
      >
        Prev
      </div>
    );
  }
  export  default CustomPrevArrow;