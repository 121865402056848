
export const saveToken = (token) => {
    localStorage.setItem('access_token', token);
};

export const clearStorageExcept = (keysToKeep = []) => {
    const itemsToKeep = {};
    keysToKeep.forEach(key => {
        const value = localStorage.getItem(key);
        if (value) itemsToKeep[key] = value;
    });
    localStorage.clear();
    Object.keys(itemsToKeep).forEach(key => {
        localStorage.setItem(key, itemsToKeep[key]);
    });
};
