import './radioButton.css';
import  {React, useState, useEffect } from '../../libraries';

const RadioButton = ({
  value,
  name,
  onChange,
  checked,
  bgValue,
  disabled,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleOnClick = (e) => {
    if (isChecked) {
      setIsChecked(false);
      onChange(null);
    } else {
      setIsChecked(true);
      onChange(value);
    }
  };

  // Ensure bgValue is a string before attempting string operations
  const displayValue = typeof bgValue === 'string' ? bgValue.charAt(0).toUpperCase() + bgValue.slice(1) : bgValue;

  return (
    <div className="custom-radio">
      <input
        disabled={disabled}
        className="radio-button-input"
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={isChecked}
        onChange={() => { }} // No action on input change
        onClick={handleOnClick}
      />
      <label className="radio-button-label" htmlFor={value}>
        {displayValue}
      </label>
    </div>
  );
};
export default RadioButton;