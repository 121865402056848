import './Finance.css';
import {React,PropTypes, Locale } from '../../../libraries/index';
const PreviewFileModal = ({ formValue, uploadedCheque }) => {
  const { t } = Locale();
  return (
    <>
      <div
        className="modal fade preview-pile-modal-outer-div"
        id="previewFileModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="mt-2 w-100">
                <h5 className="modal-title passwordchangemodal-heading d-flex justify-content-center">
                  {t('previewModal.your_Cheque')}
                </h5>
              </div>
              <div>
                <button
                  id="preview_file_close_button"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
            </div>
            <div className="modal-body">
              <div className="containerOuter p-2">
                <div>
                  {formValue?.cheque?.type === 'application/pdf' ||
                  (!(formValue?.cheque instanceof File) &&
                    formValue?.cheque?.endsWith('.pdf')) ? (
                    <>
                      <iframe
                        className="w-100 preview-modal-img"
                        title="Preview File"
                        src={uploadedCheque || formValue?.cheque}
                      ></iframe>
                    </>
                  ) : (
                    <>
                      <img
                        className="img-fluid w-100 preview-modal-img"
                        src={
                          uploadedCheque ? uploadedCheque : formValue?.cheque
                        }
                        alt="Preview"
                        width={'unset'}
                        height={'unset'}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
PreviewFileModal.propTypes = {
  formValue: PropTypes.shape({
    cheque: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.string]),
  }),
  uploadedCheque: PropTypes.string,
};

export default PreviewFileModal;