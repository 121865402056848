import { apiCall } from "../base";

const deleteUser = async (params) => {
    const response = await apiCall(
        'authentication/delete_request',
        'PATCH',
        params,
    );
    return response;
};

const cancelDeleteUserRequest = async (params) => {
    const response = await apiCall(
        'authentication/cancel_request',
        'PATCH',
        params,
    );
    return response;
};

export {
    deleteUser, cancelDeleteUserRequest
}