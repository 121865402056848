import { useEffect,axios } from '../libraries'
const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

export const useGeolocation = () => {
    useEffect(() => {
        if (!sessionStorage.getItem('location') && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };
                    sessionStorage.setItem('location', JSON.stringify(userLocation));
                }
            );
        }
    }, []);
};
export const getGeoAddress = async (lat, lng) => {
    let removeCommaGoogleMapApiKey = googleMapApiKey.replace(/"/g, '');
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${removeCommaGoogleMapApiKey}`;
    try {
        const response = await axios.get(url);
        const results = response.data.results;
        let location = {};

        if (results.length > 0) {
            location.address = results[0].formatted_address;

            const addressComponents = results[0].address_components;
            const landmark = addressComponents.find(
                (component) =>
                    component.types.includes('point_of_interest') ||
                    component.types.includes('establishment'),
            );
            const city = addressComponents.find((component) =>
                component.types.includes('locality'),
            );
            const postcode = addressComponents.find((component) =>
                component.types.includes('postal_code'),
            );
            location.landmark = landmark ? landmark.long_name : '';
            location.city = city ? city.long_name : '';
            location.postcode = postcode ? postcode.long_name : '';
        } else {
            location.address = '';
            location.landmark = '';
            location.city = '';
            location.postcode = '';
        }
        return location;
    } catch (error) {
        console.error('Error fetching geocode data: ', error);
        return null;
    }
};

export const getLatLngByAddress = async (address) => {
    try {
      let removeCommaGoogleMapApiKey = googleMapApiKey.replace(/"/g, '');
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${removeCommaGoogleMapApiKey}`;
      const response = await axios.get(apiUrl);
      if (response.data.results && response.data.results.length > 0) {
        const lat = response.data.results[0].geometry.location.lat;
        const lng = response.data.results[0].geometry.location.lng;
        return { lat, lng }; // Return an object with lat and lng
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  