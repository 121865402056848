import './EditBranch.css';
import { React, PropTypes, useEffect, useState, Button, Select, Locale } from '../../../libraries';
import { CustomModal, Label, Small,DragDropImage } from '../../../common';
import { User } from '../../../assets/Images.js';
const EditBranch = ({
  subDistrictOption,
  onChange,
  onSearch,
  filterOption,
  postalCode,
  editModalOpenData,
  updatedData,
  formErrors,
  setFormValues,
  formValues,
  setSelectedImages,
  setSelectedImage,
  selectedImage,
  selectedImages,
  handleSubmit,
  handleImageUpload,
  handleDeselectImage,
  handleChange,
  setEditBookingModalOpen,
}) => {
  const closeButtonElement =
    document.getElementsByClassName('ant-modal-close')[0];
  const [images, setImages] = useState({});
  const { t } = Locale();

  useEffect(() => {
    if (updatedData) {
      setFormValues((pre) => ({
        ...pre,
        branchName: updatedData.branch.branch_name,
        subDistrict: updatedData.branch.district,
        postalCode: updatedData.branch.postal_code,
        address: updatedData.branch.address,
        is_enabled: updatedData.branch.is_enabled,
      }));
      let transformedData = Object.entries(updatedData.image_urls).map(
        ([key, value]) => ({ [value.id]: value.url }),
      );
      setImages(transformedData);
      let newSelectedImage = [];
      newSelectedImage = Object.values(updatedData.image_urls).map(
        (item) => item.url,
      );
      setSelectedImages(newSelectedImage);
      setSelectedImage(newSelectedImage[newSelectedImage.length - 1]);
    }
  }, [
    updatedData,
    editModalOpenData,
    setFormValues,
    setSelectedImage,
    setSelectedImages,
  ]);

  return (
    <>
      <CustomModal
        text={
          <>
            <form className="form">
              <div className="edit-branch-header">
                <h5 className="title">{t('vendor.editbranch')}</h5>
              </div>
              <div>
                <div className="form-row-branch mt-4">
                  <div className="form-group edit-profile edit-branch-image-section">
                    {!selectedImage && (
                      <div className="avatar-edit">
                        <img
                          src={User}
                          alt="Selected"
                          className="branch-avatar"
                        />
                      </div>
                    )}
                    {selectedImage && (
                      <div className="avatar-edit">
                        <img
                          src={selectedImage}
                          alt="Selected"
                          className="branch-avatar"
                        />
                      </div>
                    )}
                    <div className="avatar-edit">
                      <label htmlFor="imageUpload">
                        <div className="edit-branch-plus-icon">
                          <div className="branch-circle-background">
                            <i className="bi bi-plus-lg" aria-hidden="true"></i>
                          </div>
                        </div>
                      </label>
                    </div>
                    <DragDropImage
                      handleChange={handleImageUpload}
                      accept={['JPG', 'PNG', 'JPEG', 'WEBP']}
                      className={'hideInput'}
                    />
                  </div>
                </div>
                <div className="mt-4 multiImage-div">
                  <div className="multiImage-div-inner">
                    {selectedImages &&
                      selectedImages.map((image, index) => (
                        <div key={index} className="multiImage-div-inner-main">
                          <img
                            src={image}
                            alt={`Selected ${index}`}
                            className="selected-image"
                          />
                          <div
                            className="deselect-icon"
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              handleDeselectImage(
                                index,
                                images,
                                setImages,
                                updatedData,
                              )
                            }
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                handleDeselectImage(
                                  index,
                                  images,
                                  setImages,
                                  updatedData,
                                );
                              }
                            }}
                          >
                            <i
                              className="bi bi-x edit-branch-cross-icon"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="form-row-branch mt-4">
                <div className="form-group">
                  <label className="width100">
                    <span className="">{t('vendor.branchName')}</span>
                    <span className="custom-estric">*</span>
                    <input
                      id="branchName"
                      type="text"
                      placeholder={t('vendor.branchName')}
                      value={formValues && formValues.branchName}
                      onChange={(e) =>
                        handleChange('branchName', e.target.value)
                      }
                      className={
                        'form-control vendor-account-details-input-field'
                      }
                    />
                  </label>
                  <div className="error">{formErrors?.branchName}</div>
                </div>
              </div>
              <div className="row edit-branch-postal-code-outer-div">
                <div className="col-lg-6 col-md-12 mt-4">
                  <div className="accountDetails_Address_Inner_Div">
                    <Label
                      text={
                        <>
                          {t('field.postalCode')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />

                    <Select
                      className="finace-ant-select-form"
                      showSearch
                      placeholder={`${t('header.select')} ${t('field.postalCode')}`}
                      optionFilterProp="children"
                      onChange={(value) => onChange('postalCode', value)}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={postalCode}
                      value={formValues.postalCode || undefined}
                    />
                    <Small name={'postcode'} err={formErrors?.postalCode} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-4">
                  <div className="accountDetails_Address_City_Div">
                    <Label
                      text={
                        <>
                          {t('field.subDistrict')}
                          <small className="small-err">*</small>
                        </>
                      }
                    />
                    <Select
                      className="finace-ant-select-form"
                      placeholder={`${t('header.select')} ${t('field.subDistrict')}`}
                      optionFilterProp="children"
                      onChange={(value) => onChange('subDistrict', value)}
                      options={subDistrictOption}
                      value={formValues?.subDistrict || undefined}
                    />
                    <Small name={'city'} err={formErrors?.subDistrict} />
                  </div>
                </div>
              </div>
              <div className="form-row-branch mt-3">
                <div className="form-group">
                  <label className="width100">
                    <span className="">{t('field.address')}</span>
                    <span className="custom-estric">*</span>
                    <input
                      id="address"
                      type="text"
                      placeholder={t('field.address')}
                      value={formValues && formValues.address}
                      onChange={(e) => handleChange('address', e.target.value)}
                      className={
                        'form-control vendor-account-details-input-field'
                      }
                    />
                  </label>
                  <div className="error">{formErrors?.address}</div>
                </div>
              </div>
              <div className="form-group mt-5">
                <center>
                  <Button
                    className="form-cancel-branch"
                    onClick={() => setEditBookingModalOpen(false)}
                  >
                    {t('button.cancelButton')}
                  </Button>
                  <input
                    type="submit"
                    onClick={(e) => {
                      handleSubmit(e, closeButtonElement, updatedData,setEditBookingModalOpen);
                    }}
                    value={t('button.update')}
                    className="form-submit-branch"
                  />
                </center>
              </div>
            </form>
          </>
        }
      />
    </>
  );
};
EditBranch.propTypes = {
  subDistrictOption: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  filterOption: PropTypes.func,
  postalCode: PropTypes.array,
  editModalOpenData: PropTypes.any,
  updatedData: PropTypes.object,
  formErrors: PropTypes.object,
  setFormValues: PropTypes.func,
  formValues: PropTypes.object,
  setSelectedImages: PropTypes.func,
  setSelectedImage: PropTypes.func,
  selectedImage: PropTypes.string,
  selectedImages: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleImageUpload: PropTypes.func,
  handleDeselectImage: PropTypes.func,
  handleChange: PropTypes.func,
};
export default EditBranch;