import './CashBackPage.css';
import { React, PropTypes, Locale,useSelector } from '../../libraries';
import { CashBackImage } from '../../assets/Images';
import { VendorHeader } from '../../vendor/pages';
import { Heading } from '../../common';
import { CurrencyDisplay } from '../../jsonData';
import { CustomImage } from '../../lazyComponent/index.js';

const CashBackPage = () => {
  const userCashBackDetails = useSelector(
    (state) => state?.userCashBackDetails?.userCashBackDetails,
  );
  const { t } = Locale();
  const deletionRequest = localStorage.getItem('status') === 'requested';

  return (
    <>
      <div
        className={`heading-outer-div user-outer-div ${deletionRequest && 'deletionRequest-user-header'}`}
      >
        <VendorHeader />
        <Heading text={t('userEdit.cashback')} icon="bi bi-wallet" />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="cashback-outer-container user-cashback-content user-inner-content-scrollar">
                <div className="scroller-container1 services-table-inner-div  mb-3 ">
                  <div className="row">
                    <div className="col-md-5 col-sm-12 col-12 cashback-container text-left">
                      <div className="container">
                        <div className="section custom-bg-color">
                          <h2 className="section-title">
                            {t('userEdit.totalReceived')}
                          </h2>
                          <div className="amount">
                            <CurrencyDisplay
                              value={
                                userCashBackDetails?.cashback?.total_received ??
                                0
                              }
                            />
                          </div>
                        </div>
                        <div className="section custom-bg-color2">
                          <h2 className="section-title">
                            {t('userEdit.pending')}
                          </h2>
                          <div className="amount">
                            <CurrencyDisplay
                              value={
                                userCashBackDetails?.cashback?.total_received -
                                userCashBackDetails?.cashback
                                  ?.available_balance ?? 0
                              }
                            />
                          </div>
                        </div>
                        <div className="section custom-bg-color3">
                          <h2 className="section-title">
                            {t('userEdit.availableCashback')}
                          </h2>
                          <div className="amount">
                            <CurrencyDisplay
                              value={
                                userCashBackDetails?.cashback
                                  ?.available_balance ?? 0
                              }
                            />
                          </div>
                          <div className="available-cashback">
                            *{t('userEdit.remainingMsg')}.
                          </div>
                        </div>
                        <div className="row mx-0 text-sm mt-2 cashbackMsg-text mb-4">
                          {t('userEdit.cashbackMsg')}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 d-flex justify-content-center">
                      <CustomImage
                        src={CashBackImage}
                        className="img-fluid"
                        alt="cashback"
                        width={'unset'}
                        height={'unset'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
CashBackPage.propTypes = {
  getCashPrice: PropTypes.number,
  availableAmount: PropTypes.number,
  totalReceived: PropTypes.number,
};

export default CashBackPage;