import './Header.css';
import { React } from '../../../libraries';
import { NavbarLogic } from '../../../components';
const VendorHeader = ({ handleAddToCartCount }) => {
  return (
    <>
      <div className="vendor_header_outerDiv">
        <div className="user-functuanilty">
          <NavbarLogic
            handleAddToCartCount={handleAddToCartCount}
            showInnerHeader={true}
          />
        </div>
      </div>
    </>
  );
};
export default VendorHeader;