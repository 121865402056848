import './CancelDeletionRequest.css';
import { React, Locale } from '../../libraries';
import { AccountDeletionImage2 } from '../../assets/Images';
import { CustomButton } from '../../common/customButton/customButton';
import { DeletionRequestHeader } from '../index';
import { VendorHeader } from '../../vendor/pages';
import { CustomImage } from '../../lazyComponent';
const CancelDeletionRequest = ({ handleSubmit }) => {
  const { t } = Locale();
  const deletionRequest = localStorage.getItem('status') === 'requested';
  return (
    <>
      <div
        className={`heading-outer-div user-outer-div ${deletionRequest && 'deletionRequest-user-header'}`}
      >
        <VendorHeader />
        <DeletionRequestHeader />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="user-inner-content-scrollar-deletion-request">
                <div>
                  <div className="heading">
                    <h5>{t('userEdit.accountDeletionRequestMsg')}</h5>
                  </div>
                  <div className="row cancel_deletion_timerbox mt-2 ">
                    <div className="col-1  mt-4">
                      <CustomImage
                        className="clockImage"
                        alt="clockimage"
                        src={AccountDeletionImage2}
                        width={'unset'}
                        height={'unset'}
                      />
                    </div>
                    <div className="col-11  mt-4 mb-5 ">
                      <p className="cancel-deletion-request-containt-div">
                        {t('userEdit.accountDeletionRequestPoint')}
                      </p>
                    </div>
                    <div className="accountDelete_confirmDeletionButton_Div mb-5">
                      <CustomButton
                        className="userprofileform-save-button"
                        name={`${t('button.cancelButton')} ${t('userEdit.deletionRequest')}`}
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CancelDeletionRequest;