import './Review.css';
import { React, Locale, Rate } from '../../../libraries';
import { VendorHeader } from '../index.js';
import { CustomSpinner, Heading, EmptyComponent } from '../../../common';
import { UnderReviewLogic } from '../../component';
import { User } from '../../../assets/Images';
import { CustomImage } from '../../../lazyComponent/index.js';

const Review = ({
  setIsEmptyPage,
  isEmptyPage,
  isReviewRating,
  isOrganizationStatus,
  handleFilter,
  filter,
  isLoader,
  vendorRatings,
  vendorReview,
  totalReview,
  deletionRequest
}) => {
  const filteredReviews = () => {
    let filteredList = vendorReview && [...vendorReview];
    if (filter === 'lowest') {
      filteredList?.sort((a, b) => a.average_rating - b.average_rating);
    } else if (filter === 'highest') {
      filteredList?.sort((a, b) => b.average_rating - a.average_rating);
    } else if (filter === 'recent') {
      filteredList?.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    if (filteredList?.length <= 0) {
      setIsEmptyPage(true);
    } else {
      setIsEmptyPage(false);
    }
    return filteredList;
  };

  const ratingProgressBar = vendorRatings === undefined ? 0 : vendorRatings;
  let ambienceRating = ratingProgressBar?.ambience_rating || 0;
  let formattedAmbienceRating = parseFloat(ambienceRating).toFixed(1);
  let cleanlinessRating = ratingProgressBar?.cleanliness_rating || 0;
  let formattedCleanlinessRating = parseFloat(cleanlinessRating).toFixed(1);
  let staffRating = ratingProgressBar?.staff_rating || 0;
  let formattedStaffRating = parseFloat(staffRating).toFixed(1);
  let valueRating = ratingProgressBar?.value_rating || 0;
  let formattedValueRating = parseFloat(valueRating).toFixed(1);
  let venueRatings = vendorRatings?.vanue_rating;
  const { t } = Locale();
  return (
    <>
      <div className={`heading-outer-div  ${deletionRequest && 'vendor-deletionRequest-user-header'}`}>
        <VendorHeader />
        <Heading
          text={t('vendorReview.reviews')}
          icon={'bi bi-hand-thumbs-up'}
        />
        <div className="after-heading-outer-div">
          {isOrganizationStatus !== 'verified' ? (
            <>
              <UnderReviewLogic />
            </>
          ) : (
            <>
              <div className="user-common-box">
                <div className="user-common-box-inner">
                  <div className="user-common-box-fix-content">
                    <div className="row mt-2 calendar-row">
                      <div className="vendor-review-btn-outer-div">
                        <div className="user-booking-tab-outer-div">
                          <span
                            className={`user-bookings-tab btn ${filter === 'recent' ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'
                              } ${!isReviewRating && 'disabled'}`}
                            onClick={(e) =>
                              isReviewRating && handleFilter(e, 'recent')
                            }
                          >
                            {t('vendorReview.mostRecent')}
                          </span>
                          <span
                            className={`user-bookings-tab btn ${filter === 'lowest' ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'
                              } ${!isReviewRating && 'disabled'}`}
                            onClick={(e) =>
                              isReviewRating && handleFilter(e, 'lowest')
                            }
                          >
                            {t('vendorReview.lowestRating')}
                          </span>
                          <span
                            className={`user-bookings-tab btn ${filter === 'highest' ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'
                              } ${!isReviewRating && 'disabled'}`}
                            onClick={(e) =>
                              isReviewRating && handleFilter(e, 'highest')
                            }
                          >
                            {t('vendorReview.highestRating')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${!isLoader ? 'vendor_review_container user-inner-content-scrollar user-inner-content-scrollar-after-fix-content' : 'd-none'}`}
                  >
                    {filteredReviews()?.length > 0 ? (
                      <>
                        <div className="row vendor_review_row">
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className='m-1'>
                              <div className='inner-review'>
                                <div className="row">
                                  <div className="col-md-5 col-sm-6 col-6">
                                    <p>{t('vendorReview.totalReviews')}</p>
                                    <h2>
                                      {' '}
                                      {vendorRatings === undefined
                                        ? 0
                                        : totalReview}{' '}
                                    </h2>
                                  </div>
                                  <div className="col-md-5 col-sm-6 col-6">
                                    <p>{t('vendorReview.averageRating')}</p>
                                    <h2>
                                      {vendorRatings === undefined
                                        ? 0
                                        : venueRatings?.toFixed(2)}{' '}
                                      <i
                                        className="bi bi-star-fill vendor-average-star"
                                        aria-hidden="true"
                                      ></i>
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className='m-1'>
                              <div className='vendor_review_progress_bar'>
                                <div>
                                  <div className="row review-progressbar">
                                    <div className="col-md-4 col-sm-6 col-6">
                                      <div>{t('globalItems.ambience')}</div>
                                    </div>
                                    <div className="col-md-6 col-sm-10 col-10">
                                      <div className="vendor-search-display-desc-container4-progress1">
                                        <div
                                          className="vendor-search-display-desc-container4-progress1-done"
                                          style={{
                                            width: `${ratingProgressBar &&
                                              ratingProgressBar?.ambience_rating
                                              ? (ratingProgressBar?.ambience_rating /
                                                5) *
                                              100
                                              : 0
                                              }%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="col-md-2 col-sm-2 col-2">
                                      <div className="vendor_review_rating_avg">
                                        {formattedAmbienceRating}{' '}
                                        <i className="bi bi-star-fill vendor_review-star"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-6">
                                      <div>{t('globalItems.cleanliness')}</div>
                                    </div>
                                    <div className="col-md-6 col-sm-10 col-10">
                                      <div className="vendor-search-display-desc-container4-progress1">
                                        <div
                                          className="vendor-search-display-desc-container4-progress1-done"
                                          style={{
                                            width: `${ratingProgressBar &&
                                              ratingProgressBar.cleanliness_rating
                                              ? (ratingProgressBar.cleanliness_rating /
                                                5) *
                                              100
                                              : 0
                                              }%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="col-md-2 col-sm-2 col-2">
                                      <div className="vendor_review_rating_avg">
                                        {formattedCleanlinessRating}{' '}
                                        <i className="bi bi-star-fill vendor_review-star"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-6">
                                      <div>{t('globalItems.staff')}</div>
                                    </div>
                                    <div className="col-md-6 col-sm-10 col-10">
                                      <div className="vendor-search-display-desc-container4-progress1">
                                        <div
                                          className="vendor-search-display-desc-container4-progress1-done"
                                          style={{
                                            width: `${ratingProgressBar &&
                                              ratingProgressBar.staff_rating
                                              ? (ratingProgressBar.staff_rating /
                                                5) *
                                              100
                                              : 0
                                              }%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="col-md-2 col-sm-2 col-2">
                                      <div className="vendor_review_rating_avg">
                                        {formattedStaffRating}{' '}
                                        <i className="bi bi-star-fill vendor_review-star"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6 col-6">
                                      <div>{t('globalItems.value')}</div>
                                    </div>
                                    <div className="col-md-6 col-sm-10 col-10">
                                      <div className="vendor-search-display-desc-container4-progress1">
                                        <div
                                          className="vendor-search-display-desc-container4-progress1-done"
                                          style={{
                                            width: `${ratingProgressBar &&
                                              ratingProgressBar.value_rating
                                              ? (ratingProgressBar.value_rating /
                                                5) *
                                              100
                                              : 0
                                              }%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="col-md-2 col-sm-2 col-2">
                                      <div className="vendor_review_rating_avg">
                                        {formattedValueRating}{' '}
                                        <i className="bi bi-star-fill vendor_review-star"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {filteredReviews()?.length > 0 && <hr />}
                        <div className="row">
                          {filteredReviews()?.map((item, index) => {
                            return (
                              <>
                                <div
                                  className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pb-2"
                                  key={index}
                                >
                                  <div className="shop-cart-dd-service">
                                    <div className="cart-image">
                                      <div className="vendor-review-img-div">
                                        <CustomImage
                                          src={
                                            item?.image_url
                                              ? item?.image_url
                                              : User
                                          }
                                          alt="f"
                                          width={'unset'}
                                          height={'unset'}
                                        />
                                      </div>
                                      <div className="col-9 p-3">
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <div className="review-user-name venue-name-h5">
                                              {item.user_name}{' '}
                                            </div>
                                          </div>
                                          <div className="display-service-start">
                                            <Rate
                                              allowHalf
                                              disabled
                                              value={item.average_rating}
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <div className="vendor_review_review">
                                            {item.review}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        {isEmptyPage && <EmptyComponent type="vendorReview" />}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {isLoader && <CustomSpinner />}
      </div>
    </>
  );
};
export default Review;