import { apiCall } from '../base';

const addCartItem = async (params) => {
  const response = await apiCall('customer/cart_services', 'POST', params);
  return response;
};
const showCartList = async () => {
  const response = await apiCall('customer/cart_services', 'GET');
  return response;
 

};
const deleteCartItem = async (params) => {
  const response = await apiCall(`customer/cart_services`, 'DELETE', params);
  return response;

};

export { addCartItem, showCartList, deleteCartItem };