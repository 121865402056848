import { Checkbox } from 'antd';
import { InputField, RadioButton } from '../../common';
import { Accordion, Locale } from '../../libraries/index';
import { AddressJson } from '../../jsonData/index.js';
import { useState } from 'react';

export const SidebarFilter = ({
  handleCategoryCheckboxChange,
  handleFeaturedServicesChange,
  handleSortChange,
  setSearchTermArea,
  handleAllSubcategoriesCheck,
  sortBy,
  allData,
  checkedCityCount,
  open,
  areaSearch,
  handleCityCheckboxChange,
  selectedCity,
  th,
  searchTerm,
  setSearchTerm,
  defaultOpenAccordian,
  defaultOpenAccordianA,
  serviceType,
  subCategoryId,
  categoryId,
  sortOptions,
  isChecked,
  useAllData,
}) => {
  const { t } = Locale();
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const [isAccordianOpen, setIsAccordianOpen] = useState(false);

  // Arrange Address in  Ascending order logic here
  const AddressJsonAscending = AddressJson?.sort((a, b) =>
    a.districtNameEn.localeCompare(b.districtNameEn),
  );

  // Arrange service in  Ascending order logic here
  const serviceTypeAscending =
    serviceType.length > 0 &&
    serviceType.sort((a, b) => {
      return a?.category?.name?.localeCompare(b?.category?.name);
    });

  return (
    <div className={`container-fluid display-service-sidebar`}>
      <div className="row filter-header">
        <h5 className="mt-2 display-service-sidebar-row1">
          {t('field.filters')}
        </h5>
      </div>
      <div className="filter-content">
        <Checkbox
          className="checkbox-button showFeaturedServiceTag"
          onChange={(e) => handleFeaturedServicesChange(e.target.checked)}
        >
          {t('header.showFeaturedService')}
        </Checkbox>
        <div className="row">
          <div className="container display-service-sidebar-container1">
            <div className="row mt-2">
              <p className="showFeaturedService">{t('header.sortBy')}</p>
            </div>
            <div className="sort-filter">
              {sortOptions.map((option, index) => (
                <div className="row" key={index}>
                  <RadioButton
                    className="checkbox-button"
                    onChange={() =>
                      handleSortChange( { [option.value]: option.order })
                    }
                    checked={sortBy[option.value] === option.order}
                    bgValue={`${t(option.label)}`}
                    name="sortOption"
                  />
                </div>
              ))}

            </div>
          </div>
          <div>
          <hr className="mt-3" />
          </div>
          <div className="container-fluid display-service-sidebar-container2Outer">
            <p className="mt-2 showFeaturedService">
              {t('header.area')} ({checkedCityCount})
            </p>
            <div>
              <InputField
                placeholder={`${t('header.search')} ${t('header.area')} ${t('header.here')}`}
                value={areaSearch}
                onChange={(e) => setSearchTermArea(e.target.value)}
              />
            </div>
            <div className="display-service-sidebar-container2 mb-3">
              <div className="row mt-2 mb-3">
                {AddressJsonAscending?.filter((item) =>
                  item.districtNameEn
                    .toLowerCase()
                    .includes(areaSearch.toLowerCase()),
                ).map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="display-service-sidebar-container2-row mt-2"
                    >
                      <Checkbox
                        className="checkbox-button"
                        onChange={() =>
                          handleCityCheckboxChange(item.districtNameEn)
                        }
                        ischecked={
                          selectedCity.includes(item.districtNameEn)
                            ? 'true'
                            : 'false'
                        }
                      >
                        {th === 'thai' && th !== undefined
                          ? item.districtNameTh
                          : item.districtNameEn}
                      </Checkbox>
                      <span></span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
          <hr className="mt-3" />
          </div>
          <div className="container-fluid display-service-sidebar-container3Outer">
            <p className="mt-2 showFeaturedService">
              {t('header.serviceType')} (
              {allData.length > 0
                ? allData.length
                : subCategoryId
                  ? 1
                  : categoryId
                    ? categoryId.length
                    : 0}
              )
            </p>
            <div className="mb-2">
              <InputField
                placeholder={`${t('header.search')} ${t('header.serviceType')} ${t('header.here')}`}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  defaultOpenAccordian();
                }}
              />
            </div>
            <div className="display-service-filter-service-outer container-fluid  display-service-sidebar-container2">
              <div className="row">
                <Accordion
                  onClick={() => setIsAccordianOpen(!isAccordianOpen)}
                  defaultActiveKey={defaultOpenAccordianA?.map((a) =>
                    a.toString(),
                  )}
                >
                  {serviceTypeAscending.length > 0 &&
                    serviceTypeAscending
                      ?.filter((item) => {
                        let status = false;
                        item?.subcategory?.some((sub) => {
                          if (
                            sub?.name_en
                              ?.toLowerCase()
                              ?.includes(searchTerm.toLowerCase())
                          ) {
                            status = true;
                            return true; // Return true when the condition is met
                          }
                          return false; // Ensure that false is returned when the condition is not met
                        });
                        return status;
                      })
                      ?.map((category, index) => {
                        return (
                          <Accordion.Item
                            key={category.category.id}
                            eventKey={index.toString()}
                            className="accordion-header"
                          >
                            <Accordion.Header className="accordion-header-icon mt-0 m-0 p-0">
                              <Checkbox
                                className="checkbox-button"
                                onChange={(e) =>
                                  handleAllSubcategoriesCheck(
                                    e,
                                    category.category.id,
                                    selectedLanguage === 'thai' &&
                                      selectedLanguage !== undefined &&
                                      selectedLanguage !== null
                                      ? category.category.name_thai
                                      : category.category.name_en,
                                  )
                                }
                                checked={category.subcategory.every((sub) =>
                                  categoryId?.includes(sub.id),
                                )}
                              >
                                {selectedLanguage === 'thai' &&
                                  selectedLanguage !== undefined &&
                                  selectedLanguage !== null ? (
                                  <> {category.category.name_thai}</>
                                ) : (
                                  <>{category.category.name_en}</>
                                )}
                              </Checkbox>
                            </Accordion.Header>
                            <Accordion.Body className="accordion-body mt-0 mb-0">
                              {category.subcategory
                                .sort((a, b) =>
                                  a.name_en.localeCompare(b.name_en),
                                )
                                .map((subCategory) => (
                                  <div key={subCategory.id}>
                                    <Checkbox
                                      className="mt-0 checkbox-button"
                                      onChange={() =>
                                        handleCategoryCheckboxChange(
                                          subCategory.id,
                                        )
                                      }
                                      checked={
                                        subCategoryId === subCategory.id
                                          ? true
                                          : categoryId?.includes(subCategory.id)
                                      }
                                    >
                                      {selectedLanguage === 'thai' &&
                                        selectedLanguage !== undefined &&
                                        selectedLanguage !== null ? (
                                        <>{subCategory.name_thai} </>
                                      ) : (
                                        <> {subCategory.name_en}</>
                                      )}
                                    </Checkbox>
                                    <br />
                                  </div>
                                ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                </Accordion>
              </div>
            </div>
            <hr
              className={`hr-transition ${isAccordianOpen && 'sidebar-filter-hr-tag'}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
