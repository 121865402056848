
import { useEffect, useState, useCallback, React } from '../../libraries/index';
import { UserSidebar } from '../../pages';
import { NavbarLogic } from '../index';

const UserSidebars = ({
  screen,
  children,
  deletionRequest,
  setDeletionRequest,
  mobileView,
}) => {
  const [isOpenUser, setIsOpen] = useState(false);
  const [mobileScreen, setMobileScreen] = useState(window.innerWidth <= 768);
  const [ipadScreen, setIpadScreen] = useState(window.innerWidth <= 1126 && window.innerWidth > 768);

  const changeState = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth <= 768);
      setIpadScreen(window.innerWidth <= 1126 && window.innerWidth > 768);
    };

    const debounceResize = debounce(handleResize, 200);

    window.addEventListener('resize', debounceResize);
    return () => window.removeEventListener('resize', debounceResize);
  }, []);

  return (
    <>
      {screen === 'iPad' || ipadScreen ? (
        <NavbarLogic>{children}</NavbarLogic>
      ) : mobileView || mobileScreen ? (
        <NavbarLogic>{children}</NavbarLogic>
      ) : (
        <UserSidebar
          deletionRequest={deletionRequest}
          setDeletionRequest={setDeletionRequest}
          isOpenUser={isOpenUser}
          changeState={changeState}
          setIsOpen={setIsOpen}
        >
          {children}
        </UserSidebar>
      )}
    </>
  );
};

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default React.memo(UserSidebars);
