import { React, useEffect, useState } from '../../../libraries';
import { VendorSidebar } from '../../pages';
import { NavbarLogic } from '../../../components';
const Sidebar = ({ children,deletionRequest,setDeletionRequest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const changeState = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const [mobileScreen, setMobileScreen] = useState(window.innerWidth < 768);
  const [ipadScreen, setIpadScreen] = useState(
    window.innerWidth <= 1126 && window.innerWidth > 715,
  );
  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth < 768);
      setIpadScreen(window.innerWidth <= 1125 && window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {ipadScreen ? (
        <>
          <NavbarLogic children={children} />
        </>
      ) : mobileScreen ? (
        <>
          <NavbarLogic children={children} />
        </>
      ) : (
        <>
          <VendorSidebar
            isOpen={isOpen}
            children={children}
            changeState={changeState}
            setIsOpen={setIsOpen}
            deletionRequest={deletionRequest}
            setDeletionRequest={setDeletionRequest}
          />
        </>
      )}
    </>
  );
};
export default Sidebar;