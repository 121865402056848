import './MyProfile.css';
import { React, Locale, useEffect, useState, DatePicker, Select, dayjs, moment, useLocation, Link } from '../../../libraries';
import { CustomSpinner, InputField, Label, Small } from '../../../common';
import { VendorHeader } from '../index.js';
import { User } from '../../../assets/Images';
import { CountryCodeJson } from '../../../jsonData';
import { handleErrorResponse } from '../../../services';
import { PasswordChangeLogic, PasswordChangePage, Heading, DragDropImage, ImageCropper } from '../../../common';
import { UserMyProfileHeader } from '../../../pages';
import { TeamMemberWorkingHoursLogic, VendorProfileLogic } from '../../component';
import { BusinessRoutes, CustomerRoutes } from '../../../routes/index.js';
import { CancelDeletionAction } from '../../../components/index.js';
const VendorProfile = ({ deletionRequest,setDeletionRequest }) => {
  const { t } = Locale();
  const location = useLocation();
  const [countryCode, setCountryCode] = useState([]);
  const [currentPage, setCurrentPage] = useState('myProfile');
  const {
    passFormValue,
    handleChangePass,
    handlePassChangeSubmit,
    passErr,
    setPassFormValue,
    setPassErr,
  } = PasswordChangeLogic(setCurrentPage);
  const {
    workingHour,
    teamMemberServices,
    isLoader,
    onChange,
    filterOption,
    fatchVendorProfileDetails,
    selectedFile,
    formValue,
    handleChange,
    handleSubmit,
    formErrors,
    profileVisible,
    handleDateSelect,
    onCropComplete,
    crop,
    zoom,
    setZoom,
    setCrop,
    teamMemberBranch
  } = VendorProfileLogic();
  const isSection = localStorage.getItem('isSection');
  const deletionRequestStatus = localStorage.getItem('deletionRequestStatus') === 'requested'
  useEffect(() => {
    if (isSection !== 'isVendorProfile') {
      localStorage.setItem('isSection', 'isVendorProfile');
    }
  }, [location.pathname]);

  useEffect(() => {
    const countryArray = CountryCodeJson.map((item) => {
      return {
        value: item.dial_code,
        label: item.dial_code + ' (' + item.name + ')',
      };
    });
    setCountryCode(countryArray);
    try {
      fatchVendorProfileDetails();
    } catch (err) {
      handleErrorResponse(err);
    }
  }, []);

  const userName = localStorage.getItem("name")

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const genderOption = [
    { value: 'male', label: `${t('teamSetupField.male')}` },
    { value: 'female', label: `${t('teamSetupField.female')}` },
  ];

  useEffect(() => {
    const initialValues = {
      currentPassword: '',
      newPassword: '',
      conformPassword: '',
    };
    setPassFormValue(initialValues);
    setPassErr({});
  }, [currentPage]);


  return (
    <>
      {deletionRequestStatus ? (
        <>
          <CancelDeletionAction
            setDeletionRequest={setDeletionRequest}
            deletionRequest={deletionRequest}
          />
        </>
      ) : (
        <div className={`heading-outer-div ${deletionRequest && 'vendor-deletionRequest-user-header'}`}>
          <VendorHeader />
          <Heading text={t('header.myProfile')} icon={'bi bi-person'} />
          <div className="after-heading-outer-div">
            <div className="user-common-box">
              <div className="user-common-box-inner">
                <div className="user-common-box-fix-content team-member-working-hour-tab row-box m-0 mb-3">
                  <UserMyProfileHeader
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                <div className="useredit-container container-fluid  user-inner-content-scrollar user-inner-content-scrollar-after-fix-content">
                  {currentPage === 'myProfile' ? (
                    <>
                      <div
                        className={
                          !isLoader ? `row user-my-profile` : 'd-none'
                        }
                      >
                        <div className="col-md-3 mb-3 mb-sm-0 useredit-profilebox ">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title text-center">{`${userName}`}</h5>
                              <div className="form-group edit-profile vendor-team-list-modal mt-3 mb-5 user-profile-form-image-croper">
                                <img
                                  src={
                                    selectedFile
                                      ? selectedFile
                                      : formValue.image
                                        ? formValue.image
                                        : User
                                  }
                                  className={profileVisible ? "avatar my-profile-avtar-img" : "d-none"}
                                  alt="User"
                                />


                                <div className="avatar-edit">
                                  <label htmlFor="imageUpload">
                                    <div className="edit-profile-plus-icon">
                                      <div className="circle-background-my-profile">
                                        <i
                                          className={selectedFile ? "bi bi-pencil-fill" : formValue.image
                                            ? "bi bi-pencil-fill"
                                            : "bi bi-plus"}
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                {!profileVisible &&
                                  <ImageCropper uploadImage={selectedFile} onCropComplete={onCropComplete} crop={crop} zoom={zoom} setZoom={setZoom} setCrop={setCrop} handleChange={handleChange} className="avatar my-profile-avtar-img hide-file" />
                                }
                                <DragDropImage
                                  handleChange={handleChange}
                                  accept={['JPG', 'PNG', 'JPEG', 'WEBP']}
                                  files={selectedFile}
                                  className={'d-none'}
                                  name={'image'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9 mb-sm-0">
                          <div className="useredit-form card vendor-profile-right-part mb-3">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6 vendor-my-profile-div-60-1">
                                  <Label
                                    className="form-label"
                                    text={
                                      <>
                                        {t('field.fname')}
                                        <small className="small-err">*</small>
                                      </>
                                    }
                                  />
                                  <InputField
                                    name="first_name"
                                    placeholder="Enter first name"
                                    type="text"
                                    className="form-control vendor-account-details-input-field"
                                    value={formValue.first_name}
                                    onChange={handleChange}
                                  />
                                  <Small
                                    name={'first_name'}
                                    err={formErrors.first_name}
                                  />
                                </div>
                                <div className="col-6 vendor-my-profile-div-6-2 finance-col-field">
                                  <Label
                                    className="form-label"
                                    text={
                                      <>
                                        {t('field.lName')}
                                        <small className="small-err">*</small>
                                      </>
                                    }
                                  />
                                  <InputField
                                    name="last_name"
                                    placeholder="Enter last name"
                                    type="text"
                                    className="form-control vendor-account-details-input-field"
                                    value={formValue.last_name}
                                    onChange={handleChange}
                                  />
                                  <Small
                                    name={'last_name'}
                                    err={formErrors.last_name}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12  mt-2">
                                  <Label
                                    className="form-label"
                                    text={
                                      <>
                                        {t('field.phoneNumber')}
                                        <small className="small-err">*</small>
                                      </>
                                    }
                                  />
                                  <div className="row vendor-my-profile-mobile-number-row">
                                    <div className="col-sm-5 mb-1">
                                      <Select
                                        className="finace-ant-select-form"
                                        showSearch
                                        placeholder={`${t('header.select')} ${t('field.countryCode')}`}
                                        optionFilterProp="children"
                                        onChange={(value) =>
                                          onChange('country_code', value)
                                        }
                                        filterOption={filterOption}
                                        options={countryCode}
                                        value={
                                          formValue.country_code || undefined
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-7">
                                      <InputField
                                        id="phoneNumber"
                                        name="phone_number"
                                        placeholder={`${t('field.enter')} ${t('field.phoneNumber')}`}
                                        type="text"
                                        className="form-control vendor-account-details-input-field"
                                        value={
                                          formValue.phone_number &&
                                            formValue.phone_number.split(' ')
                                              .length > 1
                                            ? formValue.phone_number.split(' ')[1]
                                            : formValue.phone_number
                                        }
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <Small
                                      name={'phone_number'}
                                      err={formErrors.phone_number}
                                    />
                                  </div>
                                </div>
                                <div className=" mt-2 col-lg-6 col-md-6  col-sm-12 user-profile-form-input-common ">
                                  <label className="form-label">
                                    {t('field.semail')}
                                  </label>
                                  <InputField
                                    type="text"
                                    className="form-control vendor-account-details-input-field"
                                    placeholder={`${t('field.placeholder')} ${t('field.semail')}`}
                                    name="email"
                                    value={formValue.email}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6  mt-2 responsive-gender">
                                  <Label
                                    className="form-label"
                                    text={
                                      <>
                                        {t('field.gender')}
                                        <small className="small-err">*</small>
                                      </>
                                    }
                                  />
                                  <Select
                                    className="finace-ant-select-form"
                                    placeholder={`${t('header.select')} ${t('field.gender')}`}
                                    optionFilterProp="children"
                                    onChange={(value) =>
                                      onChange('gender', value)
                                    }
                                    options={genderOption}
                                    value={formValue.gender || undefined}
                                  />
                                  <Small
                                    name={'gender'}
                                    err={formErrors.gender}
                                  />
                                </div>
                                <div className="mt-2 col-6 responsive-gender">
                                  <Label
                                    className="form-label"
                                    text={
                                      <>
                                        {t('field.dob')}
                                        <small className="small-err">*</small>
                                      </>
                                    }
                                  />
                                  <DatePicker
                                    onChange={handleDateSelect}
                                    disabledDate={disabledFutureDate}
                                    value={
                                      formValue.date_of_birth &&
                                      dayjs(formValue.date_of_birth, {
                                        format: 'YYYY-MM-DD',
                                      })
                                    }
                                    className="form-control vendor-my-profile-date-picker-field"
                                  />
                                  <Small
                                    name={'date_of_birth'}
                                    err={formErrors.date_of_birth}
                                  />
                                </div>
                              </div>
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3 vendor-my-profile-button-div">
                                <Link
                                  disabled={deletionRequest}
                                  to={`${BusinessRoutes.bussiness}${CustomerRoutes.accountDeletion}`}
                                  className={`btn me-md-2 vendor-delete-account-btn ${deletionRequest && 'disable-btn'}`}
                                  type="button"
                                >
                                  {t("userEdit.deleteAccount")}
                                </Link>
                                <button
                                  className="btn userprofileform-save-button"
                                  onClick={(e) => handleSubmit(e)}
                                  type="submit"
                                >
                                  {t('button.saveButton')}
                                </button>
                              </div>
                              {/* <div className="d-grid gap-2 d-md-flex  mt-3 vendor-my-profile-button-div">
                              <button
                                className="btn userprofileform-save-button"
                                onClick={(e) => handleSubmit(e)}
                                type="submit"
                              >
                                {t('button.saveButton')}
                              </button>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : currentPage === 'passwordChange' ? (
                    <>
                      <div className={`row mt-3`}>
                        <PasswordChangePage
                          passFormValue={passFormValue}
                          handleChangePass={handleChangePass}
                          handlePassChangeSubmit={handlePassChangeSubmit}
                          passErr={passErr}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <TeamMemberWorkingHoursLogic
                        teamMemberBranch={teamMemberBranch}
                        working_hours={workingHour}
                        teamMemberServices={teamMemberServices}
                      />
                    </>
                  )}
                </div>
              </div>
              {isLoader && (
                <>
                  <CustomSpinner />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VendorProfile;
