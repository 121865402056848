import { apiCall } from '../base';

const getAllVendorReview = async (params) => {
  let response = await apiCall(
    `public/ratings?organization_id=${params}`,
    'GET',
  );
  return response;
};

const filterVendorReview = async (params) => {
  let response = await apiCall(
    `/public/ratings?organization_id=${params.id}&ratings=${params.filterValue}`,
    'GET',
  );
  return response;
};

export { getAllVendorReview, filterVendorReview };