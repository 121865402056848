import { React, PropTypes } from '../../../../libraries';
import { handleSuccessResponse, handleErrorResponse, deleteTeamMember } from '../../../../services';
import { DeleteTeamMember } from '../../../pages';
const DeleteTeamMemberProfile = ({
  setLoader,
  deleteFromFarray,
  id,
}) => {
  const handleSubmit = async (e, closeDeleteModal) => {
    closeDeleteModal();
    setLoader(true);
    try {
      const response = await deleteTeamMember(id);
      deleteFromFarray(id);
      handleSuccessResponse(response, response.data.message);
      deleteFromFarray(id);
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  const handleCancel = (e, closeDeleteModal) => {
    closeDeleteModal();
  };

  return (
    <DeleteTeamMember
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
    ></DeleteTeamMember>
  );
};
DeleteTeamMemberProfile.propTypes = {
  deleteFromFarray: PropTypes.func,
  id: PropTypes.number,
};

export default DeleteTeamMemberProfile;