import "./FAQ.css";
import { React, Locale, useEffect, useState } from "../../../libraries";
import { CustomSpinner, Footer } from "../../../common";
import { NavbarLogic } from "../../../components";
import { HomeRoutes } from "../../../routes/index.js";
import "./FAQSetting.css";
import { MetaTag } from "../../../customHooks/index.js";
const DesignFAQ = ({ fAQData, isLoading }) => {
  let displayContent = "";
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const { t } = Locale();
  const [privacy, setPrivacy] = useState(0);
  const defaultIndex = fAQData.findIndex((faq) => faq?.faq_content?.length > 0);
  const [selectedIndex, setSelectedIndex] = useState(
    defaultIndex !== -1 ? defaultIndex : 0
  ); // Default selection
  const [content, setContent] = useState([]);
  useEffect(() => {
    setContent(fAQData[selectedIndex]?.faq_content);
  }, [selectedLanguage, privacy, selectedIndex]);

  const handleNavigate = (e, index, path, title, content, titleThai) => {
    e.preventDefault();
    setContent(content);
    setSelectedIndex(index);
    window.scrollTo(0, 0);
  };
  const removeHtmlTags = (input) => {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.body.textContent || "";
  };

  const getTitle = (item) => {
    return selectedLanguage === "thai" &&
      selectedLanguage !== undefined &&
      selectedLanguage !== null
      ? item.faq.title_in_thai
      : item.faq.title;
  };

  if (content && content.length > 0) {
    displayContent = content;
  } else {
    for (let i = 0; i < fAQData.length; i++) {
      if (fAQData[i]?.faq_content && fAQData[i]?.faq_content.length > 0) {
        displayContent = fAQData[i]?.faq_content;
        break;
      }
    }
  }

  displayContent = displayContent || [];

  return (
    <>
      <MetaTag custom_title={`FAQ - ${fAQData[0]?.faq?.title}`} custom_description={fAQData[0]?.faq_content[0].answer} />

      <NavbarLogic setPrivacy={setPrivacy} />
      {!(isLoading) ? <>
        <div className="faq-img-div row">
          {fAQData &&
            fAQData.length > 0 &&
            fAQData.map((item, index) => {
              if (item.faq_content && item.faq_content.length === 0) {
                return null;
              }

              return (
                <div
                  key={index}
                  onClick={(e) =>
                    handleNavigate(
                      e,
                      index,
                      `${HomeRoutes.faqs}${HomeRoutes.faqsSetting}`,
                      item.faq.title,
                      item.faq_content,
                      item.faq.title_in_thai
                    )
                  }
                  className={
                    selectedIndex === index
                      ? "faq-item col-sm active"
                      : "faq-item col-sm "
                  }
                >
                  {getTitle(item)}
                </div>
              );
            })}
        </div>
        <div className="container faq_container after-category-new-div">
          {/* <BreadCrumb data={breadCrumbData} /> */}
          <div className="row faq_img_container  no-gutters">
            <div className="faq-setting-contain">
              {displayContent &&
                displayContent.length > 0 &&
                displayContent.map((item, index) => {
                  const question = item.question;
                  const plainText = item.answer;
                  return (
                    <React.Fragment key={index}>
                      {selectedLanguage === "thai" ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.question_in_thai,
                          }}
                        ></p>
                      ) : (
                        <p
                         className="faq-setting-question"
                          dangerouslySetInnerHTML={{
                            __html:  item.question,
                          }}
                        ></p>
                      )}

                      {selectedLanguage === "thai" ? (
                        <p
                          className="faq-setting-answer-thai"
                          dangerouslySetInnerHTML={{
                            __html: item?.answer_in_thai,
                          }}
                        ></p>
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.answer,
                          }}
                        ></p>
                      )}


                      {index !== displayContent.length - 1 && (
                        <hr className="faq_hr" />
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </> : <>
        <CustomSpinner />
      </>}
      <div className={`${(isLoading) ? 'loader-faq-section ' : ''}`}>
        <div className="mt-5">
          <Footer />

        </div>
      </div>
    </>
  );
};

export default DesignFAQ;
