import './App.css';
import { React, useEffect, Suspense, useMemo, ToastContainer, Route, Routes, useDispatch, Helmet, ScrollToTop } from './libraries';
import { BrowserRouter as Router } from 'react-router-dom';
import { fetchUserFooterInformation } from './redux/index.js';
import { useUserRoleAndSection, MetaTag, useGeolocation } from './customHooks/index.js';
import { PaymentDone, PaymentFailed, PageNotFound, CustomSpinner } from './pages/index.js';
import { AppRouting } from './routes/index.js';
import HomeDataProvider from './contextApi/home/homeContextApi.js';

const App = () => {
  const dispatch = useDispatch();
  const { role, isSection } = useUserRoleAndSection();

  useEffect(() => {
    if ((role === 'Customer' && !isSection) || !role) {
      dispatch(fetchUserFooterInformation());
    }
  }, [dispatch, role, isSection]);

  useGeolocation();

  const memoizedRoutes = useMemo(() => AppRouting.map(({ path, element, exact = false }) => (
    <>
      <Route
        index={path}
        key={path}
        exact={exact}
        path={path}
        element={
          <Suspense fallback={<CustomSpinner />}>
            {React.createElement(element)}
          </Suspense>
        }
      />
      <Route path={'/payment-done'} element={<PaymentDone />} />
      <Route path='/payment-fail' element={<PaymentFailed />} />
      <Route path="*" element={<PageNotFound />} />
    </>
  )), []);

  return (
    <>
      <Helmet>
        <link rel="icon" href="/images.png" />
      </Helmet>
      <MetaTag />
      <HomeDataProvider>
        <Router>
          <Routes>
            {memoizedRoutes}
          </Routes>
          <ScrollToTop smooth color="white" height="50%" />
        </Router>
      </HomeDataProvider>
      <ToastContainer />
    </>
  );
};

export default App;
