import { apiCall } from '../base';

const createBranch = async (params) => {
  const response = await apiCall('business/branches', 'POST', params);
  return response;
};

const updateBranch = async (params) => {
  const response = await apiCall(
    `business/branches/${params.id}`,
    'PUT',
    params.data,
  );
  return response;
};

const filterBranch = async (params) => {
  let isBranchName = params?.branch_name;
  let isBranchAddress = params?.address;
  let is_District = params?.district;
  let is_PostalCode = params?.postal_code;
  let is_Search = params?.search;
  let isPageNumber = params?.page_number;
  let isPerPage = params?.per_page;
  let response;
  if (params) {
    let url = `business/branches${isBranchName && `?branch_name=${isBranchName}`}${isBranchAddress && `${isBranchName ? '&' : '?'}address=${isBranchAddress}`}${is_District && `${isBranchName || isBranchAddress ? '&' : '?'}district=${is_District}`}${is_PostalCode && `${isBranchName || isBranchAddress || is_District ? '&' : '?'}postal_code=${is_PostalCode}`}${is_Search && `${isBranchName || isBranchAddress || is_District || is_PostalCode ? '&' : '?'}search=${is_Search}`}${isPageNumber && `${isBranchName || isBranchAddress || is_District || is_PostalCode || is_Search ? '&' : '?'}page_number=${isPageNumber}`}${isPerPage && `${isBranchName || isBranchAddress || is_District || is_PostalCode || is_Search || isPageNumber ? '&' : '?'}per_page=${isPerPage}`}${(params.is_enabled || params.is_enabled === false) && params.is_enabled !== 'all' ? `${isBranchName || isBranchAddress || is_District || is_PostalCode || is_Search || isPageNumber || isPerPage ? '&' : '?'}is_enabled=${params.is_enabled}` : ''}`;
    response = await apiCall(`${url}`, 'GET');
  }

  return response;
};
const branchDeleteImage = async (params) => {
  const response = await apiCall(
    `business/branches/${params.branchId}/images/${params.imageId}`,
    'DELETE',
  );
  return response;
};
const showBranch = async () => {
  const response = await apiCall('business/branches', 'GET');
  return response;
};


export {
  filterBranch,
  branchDeleteImage,
  createBranch,
  updateBranch,
  showBranch,
};
