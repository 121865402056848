import './passwordChange.css';
import { React, Locale } from '../../libraries';
import { ChangePasswordImage } from '../../assets/Images';
import { PasswordField } from '../index';
import { Small } from '../index';
import { CustomImage } from '../../lazyComponent';
const PasswordChangePage = ({
  passFormValue,
  disableBtn,
  handleChangePass,
  handlePassChangeSubmit,
  passErr,
}) => {
  const { t } = Locale();

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="useredit-form card vendor-profile-right-part mb-3 password-change-content">
            <div className="card-body d-flex justify-content-center ">
              <CustomImage
                alt="change_password_image"
                className="password-change-img img-fluid"
                src={ChangePasswordImage}
                width={'unset'}
                height={'unset'}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="useredit-form card vendor-profile-right-part mb-3 password-change-content">
            <div className="card-body">
              <div className="mt-1 changePassword_new_password_Div">
                <label>{t('userEdit.oldPassword')}</label>
                <div className="user-profile-field-outer">
                  <PasswordField
                    placeholder={t('userEdit.oldPasswordPlaceHolder')}
                    component="userProfile"
                    className="changePassword_cureent_password_Field"
                    name={'currentPassword'}
                    value={passFormValue.currentPassword}
                    onChange={handleChangePass}
                  />
                </div>
                <Small
                  name={'currentPassword'}
                  err={passErr?.currentPassword}
                />
              </div>
              <div className="changePassword_new_password_Div mt-2">
                <label>{t('field.newPassword')}</label>
                <div className="user-profile-field-outer">
                  <PasswordField
                    placeholder={t('userEdit.newPasswordPlaceHolder')}
                    component="userProfile"
                    className="changePassword_cureent_password_Field"
                    name={'newPassword'}
                    value={passFormValue.newPassword}
                    onChange={handleChangePass}
                  />
                </div>
                <Small name={'password'} err={passErr?.newPassword} />
              </div>
              <div className="changePassword_new_password_Div mt-2">
                <label>{t('field.confirmPassword')}</label>
                <div className="user-profile-field-outer">
                  <PasswordField
                    placeholder={t('userEdit.confrimNewPassword')}
                    component="userProfile"
                    className="changePassword_cureent_password_Field"
                    name={'conformPassword'}
                    value={passFormValue.conformPassword}
                    onChange={handleChangePass}
                  />
                </div>
                <Small
                  name={'confirmPassword'}
                  err={passErr?.conformPassword}
                />
              </div>
              <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                <button
                  onClick={(e) => handlePassChangeSubmit(e)}
                  className={`btn userprofileform-save-button ${disableBtn&&'disabled'}`}
                  type="submit"
                >
                  {t('button.saveButton')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PasswordChangePage;
