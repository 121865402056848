import {
  addToFavourite,
  deleteFavourites,
  handleErrorResponse,
  handleSuccessResponse,
} from '../../services/index';

export const AddOnFavouriteList = async (favoritable_id, favoritable_type) => {
  try {
    const response = await addToFavourite({
      favoritable_id: favoritable_id,
      favoritable_type: favoritable_type,
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

export const RemoveFromFavouriteList = async (service_id) => {
  try {
    const response = await deleteFavourites({ service_id: service_id });
    handleSuccessResponse(response, response.data.message);
    return response;
  } catch (err) {
    if (err.response.data.status_code === 404) {
      handleErrorResponse(err);
    }
    return err;
  }
};
