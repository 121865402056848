import { apiCall } from '../base';

const createBlockTime = async (params) => {
  const response = await apiCall(
    'business/member_unavailabilities',
    'POST',
    params,
  );
  return response;
};
const updateBlockTime = async (id, params) => {
  const response = await apiCall(
    `business/member_unavailabilities/${id}`,
    'PUT',
    params,
  );
  return response;
};
const deleteBlockTime = async (params) => {
  const response = await apiCall(
    `business/member_unavailabilities/${params}`,
    'DELETE',
  );
  return response;
};

const getBlockTimeMember = async (params) => {
  const response = await apiCall(
    `business/member_unavailabilities/filter?date=${params.date}&team_member_id=${params.team_member_id}`,
    'GET',
  );
  return response;
};
const showBlockTimeMember = async (params) => {
  const response = await apiCall(
    `business/member_unavailabilities/${params}`,
    'GET',
  );
  return response;
};

export {
  createBlockTime,
  updateBlockTime,
  deleteBlockTime,
  getBlockTimeMember,
  showBlockTimeMember,
};
