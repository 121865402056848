import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Rate } from 'antd';
import { CustomModal, Small } from '../../../common';
import './ShopModal.css';
import { Locale } from '../../Locale/Locale';

export const ShopModal = ({
  reviewText,
  ratingNotShow,
  shopRating,
  setShopRating,
  reviewValidation,
  ratingValidation,
  handleStarClick,
  ambienceRating,
  cleanlinessRating,
  staffRating,
  valueRating,
  setReviewText,
  handleReviewSubmit,
}) => {
  const { t } = Locale();
  let ambienceRatingStars;
  let cleanlinessRatingStars;
  let staffRatingStars;
  let valueRatingStars;
  let shopReview;
  shopRating &&
    shopRating.map((item) => {
      ambienceRatingStars =
        item && item?.ambience_rating && Math.round(item?.ambience_rating);
      cleanlinessRatingStars =
        item && item.cleanliness_rating && Math.round(item.cleanliness_rating);
      staffRatingStars =
        item && item.staff_rating && Math.round(item.staff_rating);
      valueRatingStars =
        item && item.value_rating && Math.round(item.value_rating);
      shopReview = item && item.comment;
    });

  const [closeButtonElement, setCloseButtonElement] = useState();
  useEffect(() => {
    const newcloseButtonElement =
      document.getElementsByClassName('ant-modal-close')[0];
    setCloseButtonElement(newcloseButtonElement);
  }, []);

  const handleClose = (closeButtonElement) => {
    if (closeButtonElement) {
      const closeButtonObject = {
        element: closeButtonElement,
        click: function () {
          setShopRating(0);
          closeButtonElement.click();
        },
      };
      closeButtonObject.click();
    } else {
    }
  };

  return (
    <>
      <CustomModal
        className="reviewShop-rating-modal"
        text={
          <>
            <div className="container-fluid">
              <div className="row">
                <h5 className="shop-modal-header">
                  {t('globalItems.qualityOfShop')}
                </h5>
              </div>
              <div className="row">
                <h6 className="shop-modal-rating fw-bold">
                  {t('globalItems.rating')}
                </h6>
              </div>
            </div>

            {shopRating && !ratingNotShow > 0 ? (
              <>
                <div className="container-fluid shop-modal-rating-div">
                  <div className="row  mt-3">
                    <div className="col-md-6">
                      <p className="shop-modal-text">
                        {t('globalItems.ambience')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <Rate
                        allowHalf
                        disabled
                        value={ambienceRatingStars}
                      ></Rate>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <p className="shop-modal-text">
                        {t('globalItems.cleanliness')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <Rate
                        allowHalf
                        disabled
                        value={cleanlinessRatingStars}
                      ></Rate>
                      <div className="shop-modal-star1"></div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <p className="shop-modal-text">
                        {t('globalItems.staff')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <Rate allowHalf disabled value={staffRatingStars}></Rate>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <p className="shop-modal-text">
                        {t('globalItems.value')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <Rate allowHalf disabled value={valueRatingStars}></Rate>
                    </div>
                  </div>
                </div>
                <Small err={ratingValidation} />
                <div className="container-fluid mt-3">
                  <div className="row">
                    <p className="shop-modal-review fw-bold">
                      {t('vendor.review')}
                    </p>
                  </div>
                  <div className="row">
                    <form>
                      <div className="form-group shop-modal-textarea-div">
                        <textarea
                          className="form-control status-box shop-modal-textarea"
                          rows="3"
                          value={shopReview}
                          readOnly
                        ></textarea>
                        <Small err={reviewValidation} />
                      </div>
                    </form>
                  </div>
                  <div className="row shop-modal-button">
                    {!shopRating ? (
                      <>
                        <Button
                          onClick={() =>
                            handleReviewSubmit({
                              closeButtonElement: closeButtonElement,
                            })
                          }
                        >
                          {t('globalItems.submit')}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={() => handleClose(closeButtonElement)}>
                          {t('button.close')}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="container-fluid shop-modal-rating-div">
                  <div className="row  mt-3">
                    <div className="col-md-6">
                      <p className="shop-modal-text">
                        {t('globalItems.ambience')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="shop-modal-star1">
                        {[1, 2, 3, 4, 5].map((index) => (
                          <span
                            key={index}
                            onClick={() => handleStarClick('ambience', index)}
                          >
                            {index <= ambienceRating ? (
                              <i className="bi bi-star-fill"></i>
                            ) : (
                              <i className="bi bi-star cursor-point-class icon-color-grey"></i>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <p className="shop-modal-text">
                        {t('globalItems.cleanliness')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="shop-modal-star1">
                        {[1, 2, 3, 4, 5].map((index) => (
                          <span
                            key={index}
                            onClick={() =>
                              handleStarClick('cleanliness', index)
                            }
                          >
                            {index <= cleanlinessRating ? (
                              <i className="bi bi-star-fill"></i>
                            ) : (
                              <i className="bi bi-star cursor-point-class icon-color-grey"></i>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <p className="shop-modal-text">
                        {t('globalItems.staff')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="shop-modal-star1">
                        {[1, 2, 3, 4, 5].map((index) => (
                          <span
                            key={index}
                            onClick={() => handleStarClick('staff', index)}
                          >
                            {index <= staffRating ? (
                              <i className="bi bi-star-fill"></i>
                            ) : (
                              <i className="bi bi-star cursor-point-class icon-color-grey"></i>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <p className="shop-modal-text">
                        {t('globalItems.value')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="shop-modal-star1">
                        {[1, 2, 3, 4, 5].map((index) => (
                          <span
                            key={index}
                            onClick={() => handleStarClick('value', index)}
                          >
                            {index <= valueRating ? (
                              <i className="bi bi-star-fill"></i>
                            ) : (
                              <i className="bi bi-star cursor-point-class icon-color-grey"></i>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <Small err={ratingValidation} />
                <div className="container-fluid mt-3">
                  <div className="row">
                    <p className="shop-modal-review fw-bold">
                      {t('vendor.review')}
                    </p>
                  </div>
                  <div className="row">
                    <form>
                      <div className="form-group shop-modal-textarea-div">
                        <textarea
                          value={reviewText}
                          className="form-control status-box shop-modal-textarea"
                          rows="3"
                          onChange={(e) => setReviewText(e.target.value)}
                        ></textarea>
                        <Small err={reviewValidation} />
                      </div>
                    </form>
                  </div>
                  <div className="row shop-modal-button">
                    <Button
                      onClick={() =>
                        handleReviewSubmit({
                          closeButtonElement: closeButtonElement,
                        })
                      }
                    >
                      {t('globalItems.submit')}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        }
      />
    </>
  );
};
ShopModal.propTypes = {
  reviewText: PropTypes.string,
  ratingNotShow: PropTypes.bool,
  shopRating: PropTypes.array,
  setShopRating: PropTypes.func,
  reviewValidation: PropTypes.string,
  ratingValidation: PropTypes.string,
  handleStarClick: PropTypes.func,
  ambienceRating: PropTypes.number,
  cleanlinessRating: PropTypes.number,
  staffRating: PropTypes.number,
  valueRating: PropTypes.number,
  setReviewText: PropTypes.func,
  handleReviewSubmit: PropTypes.func,
};
