import { React, ReactCodeInput } from '../../libraries';

const VarificationCodeInput = ({ name, value, onChange }) => {
  return (
    <ReactCodeInput
      className="code-input"
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};
export default VarificationCodeInput;