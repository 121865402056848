import { useEffect, useState } from '../../../libraries';
const GetOrganizationStatus = () => {
  const [isOrganizationStatus, setIsOrganizationStatus] = useState();
  const status = localStorage.getItem('status');
  useEffect(() => {
    setIsOrganizationStatus(status);
  }, [status]);
  return {
    isOrganizationStatus,
    setIsOrganizationStatus,
  };
};
export default GetOrganizationStatus;