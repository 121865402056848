import './ServiceModal.css';
import { React, PropTypes, Button, Locale, useEffect, useState } from '../../../libraries';
import { CustomModal, Small } from '../../../common';
import { ServiceModalLogic } from '../../../components';

const ServiceModal = ({
  setUpdateReviewRating,
  rating,
  serviceIdRating,
  count
}) => {
  const [serviceRating, setServiceRating] = useState(false);
  const { handleSubmit, handleClick, totalStarsClicked, ratingValidation ,setRatingValidation} =
    ServiceModalLogic(setUpdateReviewRating);
  const { t } = Locale();
  const [closeButtonElement, setCloseButtonElement] = useState();

  useEffect(() => {
    const newcloseButtonElement =
      document.getElementsByClassName('ant-modal-close')[0];
    setCloseButtonElement(newcloseButtonElement);
  }, []);

  const ratingStars = Math.round(rating);
  const handleClose = (e, closeButtonElement) => {
    e.preventDefault();
    if (closeButtonElement) {
      const closeButtonObject = {
        element: closeButtonElement,
        click: function () {
          closeButtonElement.click();
        },
      };
      setRatingValidation("")
      closeButtonObject.click();


    } else {
    }
  };

  useEffect(() => {
    if (rating === 0) {
      setServiceRating(true);
    }
  }, [serviceRating]);

  useEffect(()=>{
    setRatingValidation('')
  },[count])

  const getRatingBtn = () => {
    return (
      <>
        {rating === null ? (
          <>
            <Button
              onClick={() =>
                handleSubmit({
                  serviceId: serviceIdRating,
                  closeButtonElement: closeButtonElement,
                })
              }
            >
              {t('globalItems.submit')}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={(e) => handleClose(e, closeButtonElement)}>
              {t('button.close')}
            </Button>
          </>
        )}
      </>
    );
  };

  const getServiceRattin = (rating) => {
    return (
      <>
        {rating === null ? (
          <>
            <div>
              {[1, 2, 3, 4, 5].map((index) => (
                <span key={index} onClick={() => handleClick(index)}>
                  {index <= totalStarsClicked ? (
                    <i className="bi bi-star-fill"></i>
                  ) : (
                    <i className="bi bi-star cursor-point-class icon-color-grey-font"></i>
                  )}
                </span>
              ))}
            </div>
          </>
        ) : (
          <>
            <div>
              {[1, 2, 3, 4, 5].map((index) => (
                <span key={index}>
                  {index <= ratingStars ? (
                    <i className="bi bi-star-fill service-modal-start"></i>
                  ) : (
                    <i className="bi bi-star cursor-point-class icon-color-grey-font"></i>
                  )}
                </span>
              ))}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <CustomModal
      text={
        <>
          <div className="container-fluid">
            <div className="row">
              <h5 className="service-modal-h5">
                {t('globalItems.opinionMattersToUs')}
              </h5>
            </div>
          </div>
          <div className="container-fluid service-modal-div">
            <div className="row">
              <p className="service-modal-p">
                {t('globalItems.qualityOfThisService')}
              </p>
            </div>
            <div className="row service-modal-star">
              {getServiceRattin(rating)}
              <Small err={ratingValidation} />
            </div>
          </div>
          <div className="container-fluid">
            <div className="row service-modal-button">
              {getRatingBtn(rating)}
            </div>
          </div>
        </>
      }
    />
  );
};
ServiceModal.propTypes = {
  rating: PropTypes.number,
  serviceIdRating: PropTypes.any,
};
export default ServiceModal;