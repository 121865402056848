import { React, useLocation, useNavigate } from '../../libraries';
import { CancelDeletionHeader } from '../../pages/index';
import { handleErrorResponse, handleSuccessResponse, cancelDeleteUserRequest } from '../../services/index';

const CancelDeletionHeaderLogic = ({ setDeletionRequest }) => {
  const navigate=useNavigate();
  const location=useLocation();
  const isCustomer = localStorage.getItem('role') === 'Customer'
  const handleSubmit = (e) => {
    e.preventDefault();
    let param = {
      status: 'activated',
    };
    cancelDeleteUserRequest(param)
      .then((response) => {
        handleSuccessResponse(response, response.data.message);
        if(isCustomer){
          localStorage.setItem('status', 'activated');localStorage.setItem('deletionRequestStatus', 'activated')
        }
        else{
          localStorage.setItem('deletionRequestStatus', 'activated');
        }
        navigate(`${location.pathname}`)
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };
  return (
    <>
      <CancelDeletionHeader handleSubmit={handleSubmit} />
    </>
  );
};
export default CancelDeletionHeaderLogic;