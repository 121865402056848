import "./PaymentFailed.css";
import { React, useNavigate, Locale } from "../../libraries";
import { FailedImage } from "../../assets/Images";
const PaymentFailed = () => {
  const { t } = Locale();
  const navigate = useNavigate();
  const navigatePage = () => {
    navigate("/");
  };
  return (
    <div className="payment-fail-continer">
      <div class="card">
        <div className="payment-fail-page">
          <img
            src={FailedImage}
            alt="failed"
            className="checkmark img-fluid"
          ></img>
        </div>
        <h1 className="mt-4">{t("button.failed")}</h1>
        <p>{t("signMessage.failedMsg")}</p>
        <div className="row">
          <div className="col-6">
            {" "}
            <button
              className="btn btn-outline-success mt-2 w-100"
              onClick={navigatePage}
            >
              Pay Again
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-outline-danger mt-2 w-100"
              onClick={navigatePage}
            >
             Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentFailed;
