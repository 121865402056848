import './SearchAndDisplayDesc.css';
import { React, PropTypes, Button, Locale, useEffect, useState, Link, useLocation, Flex, Rate } from '../../libraries';
import { CustomIcon, Footer, ShareButtons, CustomSpinner, PageNotFound } from '../../common';
import { BookNowModalLogic, NavbarLogic, CustomShare } from '../../components';
import { SignIn } from '../../lazyComponent/index.js';
import { showShopServiceRatings, showVendorRatings } from '../../services';
import { ImageGallery } from '..';
import { RatingSection } from './RatingSection.js';
import { HomeRoutes } from '../../routes/index.js';
import { CurrencyDisplay, DiscountPercent } from '../../jsonData';
import { PeakDiscountModal } from '../PeakDiscountModal/PeakDiscountModal.js';
import { DisplayServiceMap } from './DisplayServiceMap.js';
import { User } from '../../assets/Images.js';
import DescModal from './DescModal.js';
import { CustomImage } from '../../lazyComponent/index.js';
import { MetaTag } from '../../customHooks/metaKeywords.js';

const SearchAndDisplayDesc = ({
  cartItem,
  previousPath,
  handleLikeClick,
  handleLikeClickShop,
  handleAddToCart,
  showModal,
  setShowModal,
  filteredServices,
  vendor_id,
  type,
  commonId,
  userType,
  handleAddToCartCount,
  previousBreadCrumb,
  isPageNotFound
}) => {
  const location = useLocation();
  const { t } = Locale();
  const [serviceRating, setServiceRating] = useState();
  const [vendorRating, setVendorRating] = useState();
  const [showModalDiscount, setShowModalDiscount] = useState(false);
  const [perticullarRating, setPerticullarRating] = useState({});
  const [activeButton, setActiveButton] = useState("service");
  const [displayedItems, setDisplayedItems] = useState(4);
  const [displayedReview, setDisplayedReview] = useState(4);
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const deletionRequest = localStorage.getItem("status") === "requested";
  filteredServices = filteredServices?.[0] ? filteredServices?.[0] : [];
  // const shareUrl = `${process.env.REACT_APP_SHARE_URL}${serviceDescription}/${vendor_id}/${filteredServices?.vendor_info?.venue_name}`;
  const shareUrl = `${process.env.REACT_APP_SHARE_URL}${window.location.pathname}`;
  const title = `${filteredServices?.vendor_info?.venue_name}`;
  const role = localStorage.getItem("role");
  const desc = ["Bad", "Normal", "Good", "Wonderful"];
  const ratingVenue = vendorRating?.ratings?.vanue_rating;
  const showReview = vendorRating?.review_comment;
  const error = false;
  const shareItems = [
    { key: "1", label: <ShareButtons url={shareUrl} title={title} /> },
  ];

  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const [currentPage, setCurrentPage] = useState("service");
  const ratingProgressBar = vendorRating?.ratings;
  const ratings = [
    { type: "ambience_rating", label: "ambience" },
    { type: "cleanliness_rating", label: "cleanliness" },
    { type: "staff_rating", label: "staff" },
    { type: "value_rating", label: "value" },
  ];
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setCurrentPage(buttonName);
  };

  const progressBar =
    ratingProgressBar?.ambience_rating ||
      ratingProgressBar?.value_rating ||
      ratingProgressBar?.staff_rating ||
      ratingProgressBar?.cleanliness_rating_rating
      ? "search-display-desc-container4-progress1"
      : "search-display-desc-container4-progress1 d-flext justify-content-center";

  useEffect(() => {
    vendor_id && fetchDataVendor(vendor_id);
    window.scroll(0, 0);
  }, [vendor_id]);

  useEffect(() => {
    if (serviceRating) {
      const ratingsObject = serviceRating?.map((service) => ({
        service_id: service?.service_id,
        ratings: service?.ratings,
      }));
      setPerticullarRating(ratingsObject);
    }
  }, [serviceRating]);

  useEffect(() => {
    if (previousPath && previousPath.length > 0) {
      const newPath = [...previousPath];
      newPath[newPath.length - 1] = {
        ...newPath[newPath.length - 1],
        breadCrumbNamePath: location.pathname,
      };
    }
  }, [previousPath]);

  const fetchData = async (vendor_id) => {
    try {
      const response = await showShopServiceRatings(vendor_id);
      setServiceRating(response.data.service_ratings);
    } catch (err) { }
  };

  const fetchDataVendor = async (vendor_id) => {
    await fetchData(vendor_id);
    try {
      const response = await showVendorRatings(vendor_id);
      setVendorRating(response.data);
    } catch (err) { }
  };

  const handleLoadMore = () => {
    setDisplayedItems(displayedItems + 4); // Increase the number of displayed items
  };
  const handleLoadReview = () => {
    setDisplayedReview(displayedReview + 4); // Increase the number of displayed items
  };

  const removeHtmlTags = (input) => {
    const doc = new DOMParser().parseFromString(input, "text/html");
    if (
      doc.body.textContent === "null" ||
      doc.body.textContent === "undefine" ||
      doc.body.textContent === undefined
    ) {
      return "";
    } else {
      return doc.body.textContent.trim();
    }
  };


  const getVenueName = (filteredServices) => {
    return selectedLanguage === "thai"
      ? filteredServices.vendor_info.name_thai &&
        filteredServices?.vendor_info?.name_thai !== "null"
        ? filteredServices.vendor_info.name_thai
        : filteredServices.vendor_info?.venue_name
      : filteredServices.vendor_info?.venue_name;
  };

  const getBreadCrumb = (previousBreadCrumb, index) => {
    return (
      <>
        {Array.isArray(previousBreadCrumb.breadCrumbName) && <>
          <Link to={`${previousBreadCrumb?.breadCrumbNamePath}`} className="breadcrumb-link-tag cursor-pointer">
            {(selectedLanguage === "thai")
              ? previousBreadCrumb?.breadCrumbName[1]  // Show Thai breadcrumb
              : previousBreadCrumb?.breadCrumbName[0]}
          </Link>
          {previousBreadCrumb?.breadCrumbName[1] && index === 1 &&
            <span aria-hidden="true" className="breadcrumb-separator">
              &gt;
            </span>
          }
        </>

        }
      </>
    );
  };

  const venueRating = !error ? (
    <>
      <h5>{ratingVenue?.toFixed(1)}</h5>
      <Flex gap="middle" vertical>
        {ratingVenue ? <>
          <Rate
            tooltips={desc}
            disabled
            value={ratingVenue}
          />
        </> : <>

        </>}

      </Flex>
    </>
  ) : (
    <>
      <div className="venue-name-h5">0</div>
      <Flex gap="middle" vertical>
        <Rate
          tooltips={desc}
          disabled
          value={ratingVenue}
        />
      </Flex>
    </>
  );

  const getHeartClassName = (filteredServices) => {
    return filteredServices.vendor_info.favourite.is_vendor_favourite
      ? "bi bi-suit-heart-fill"
      : "bi bi-suit-heart";
  };

  const getAboutUs = (filteredServices) => {
    return selectedLanguage === "en"
      ? filteredServices?.vendor_info?.venue_des_en === "undefined" ||
        !filteredServices?.vendor_info?.venue_des_en
        ? ""
        : filteredServices.vendor_info.venue_des_en
      : filteredServices.vendor_info.description_thai;
  };

  const getCartDisable = (serviceIs, outer) => {
    if (cartItem?.includes(serviceIs)) {
      return !outer ? "disabled-icon" : "disable-icon-outer";
    } else {
      return "inabled-icon";
    }
  };

  const handleCloseDiscountModal = () => {
    setShowModalDiscount(false);
  };

  const handleShowDiscountModal = () => {
    setShowModalDiscount(true);
  };

  const [showModalDesc, setShowModalDesc] = useState(false);
  const [messageDesc, setMessageDesc] = useState();
  const [messageTerm, setMessageTerm] = useState();

  const getReadMoreFun = (service) => {
    const description_en = removeHtmlTags(service.description_en);
    const description_thai = removeHtmlTags(service.description_thai);
    const special_term_en = removeHtmlTags(service.service);
    const special_term_thai = removeHtmlTags(service.special_term_thai);
    const descriptionAndSpecialTermEng = description_en + "" + special_term_en;
    const descriptionAndSpecialTermThai =
      special_term_thai + "" + description_thai;

    return selectedLanguage === "en"
      ? descriptionAndSpecialTermEng.split("").length > 200
      : descriptionAndSpecialTermThai.split("").length > 200;
  };

  const handleCloseDesc = () => {
    setShowModalDesc(false);
  };

  const handleShowDesc = (service) => {
    if (selectedLanguage === "en") {
      setMessageDesc(service?.description_en);
      setMessageTerm(service?.special_term_en);
    } else {
      setMessageDesc(service?.description_thai);
      setMessageTerm(service?.special_term_thai);
    }
    setShowModalDesc(true);
  };
  return (
    <>{(isPageNotFound) ? <>
      <PageNotFound />
    </> : <>
      <NavbarLogic handleAddToCartCount={handleAddToCartCount} />
      <MetaTag
       custom_title={getVenueName(filteredServices)} 
       custom_img={filteredServices.vendor_image?.[0]}
       />
      <div
        className={`search-display-des-outer-div after-category-new-div container ${deletionRequest && "deletionRequest-not-show-filter"
          } ${role && "search-display-desc-role"}`}
      >
        <div className="breadcrumb-outer-div">
          <nav
            lang="sk"
            role="navigation"
            aria-label="Omrvinková navigácia"
            className="breadcrumb"
          >
            <ol>
              <>
                <li>
                  <Link to={HomeRoutes.home} className="breadcrumb-link-tag">
                    {t("breadcrumb.home")}
                  </Link>
                </li>
                {previousBreadCrumb &&
                  previousBreadCrumb.length > 0 &&
                  previousBreadCrumb.map((obj, index) => (
                    <React.Fragment key={index}>
                      <span aria-hidden="true" className="breadcrumb-separator">
                        &gt;
                      </span>
                      <li className="breadcrumb-li-tag">
                        {getBreadCrumb(obj)}
                      </li>
                    </React.Fragment>
                  ))}
                {previousBreadCrumb?.length === 1 && (
                  <span aria-hidden="true" className="breadcrumb-separator">
                    &gt;
                  </span>
                )}
                {!previousBreadCrumb && <>
                  <span aria-hidden="true" className="breadcrumb-separator">
                    &gt;
                  </span>
                </>}
                <li className="breadcrumb-li-tag">
                  {Object.values(filteredServices).length > 0 && (
                    <>{getVenueName(filteredServices)}</>
                  )}
                </li>
              </>
            </ol>
          </nav>
        </div>
        {Object.values(filteredServices).length > 0 ? (
          <>
            <div id="overview">
              <div className="view-all-service-container">
                <div>
                  <div className="search-display-venue-name">
                    <div className="venue-name-h5">
                      {getVenueName(filteredServices)}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div>
                    <div className="service_desc_address">
                      <span className="me-1 location-icon">
                        {CustomIcon.location}
                      </span>
                      <p>
                        {filteredServices.vendor_info?.address}
                        &nbsp;{filteredServices.vendor_info?.city}
                        &nbsp;
                        {filteredServices.vendor_info?.postcode}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row search-display-desc-image-div">
                  <div className="col-lg-8 col-md-12">
                    <ImageGallery
                      images={filteredServices?.vendor_image || []}
                    />
                  </div>
                  <div className="col-lg-4 col-md-12 mb-2 map-div-dis-serv-desc">
                    <div className="row map-div-dis-serv-row">
                      <DisplayServiceMap
                        address={filteredServices?.vendor_info?.address}
                        postcode={filteredServices?.vendor_info?.postcode}
                        city={filteredServices?.vendor_info?.city}
                      />
                    </div>
                    <div className="row mt-4">
                      <div className="mb-2 search-display-desc-icon-row">
                        <div className="search-display-desc-icon-like">
                          <i
                            className={`${getHeartClassName(filteredServices)}`}
                            onClick={() =>
                              handleLikeClickShop(
                                filteredServices.vendor_info.favourite
                                  .is_vendor_favourite,
                                filteredServices.vendor_info.favourite
                                  .favourite_id,
                                filteredServices.vendor_info?.vendor_id,
                                "vendor",
                                role
                              )
                            }
                            data_bs_toggle="modal"
                            data_bs_target="#exampleModal"
                          ></i>
                          <span className="search-display-desc-icon-share-text">
                            {" "}
                            &nbsp;{t("globalItems.addedOrganizationToFavorite")}
                          </span>
                        </div>
                        &nbsp;&nbsp;
                        <div>
                          <span className="search-display-desc-icon-share">
                            &nbsp;
                            <CustomShare
                              className="search-display-desc-icon-share"
                              placement={"bottom"}
                              items={shareItems}
                            />
                          </span>
                          <span className="search-display-desc-icon-share-text">
                            &nbsp;{t("globalItems.share")}
                          </span>
                        </div>
                      </div>
                      <hr className="mt-1"></hr>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="disp-serv-desc-outer-div col-lg-8 col-md-12">
                    <div className="store-heading">
                      <div className="row">
                        <div className="main-section">
                          <div className="hedding-title">
                            <div className="fw-bold abouts venue-name-h5">
                              {t("globalItems.venueRating")}
                            </div>
                          </div>
                          <div className="rating-part mt-2">
                            <div className="average-rating">{venueRating}</div>
                            <div className="ambience-rating">
                              {ratings.map((rating, index) => (
                                <div className="">
                                  {t(`globalItems.${rating.label}`)}
                                </div>
                              ))}
                            </div>
                            <div className="loder-ratimg">
                              {[
                                "ambience_rating",
                                "cleanliness_rating",
                                "staff_rating",
                                "value_rating",
                              ].map((ratingType, index) => (
                                <div className={progressBar} key={index}>
                                  <div
                                    className={`search-display-desc-container4-progress${index + 1
                                      }-done`}
                                    style={{
                                      width: `${(!error &&
                                        ratingProgressBar?.[ratingType]
                                        ? ratingProgressBar?.[ratingType] / 5
                                        : 0) * 100
                                        }%`,
                                    }}
                                  ></div>
                                </div>
                              ))}
                            </div>

                            <div className="start-parts">
                              {[
                                "ambience_rating",
                                "cleanliness_rating",
                                "staff_rating",
                                "value_rating",
                              ].map((ratingType, index) => (
                                <RatingSection
                                  key={index}
                                  rating={ratingProgressBar?.[ratingType]}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 mb-2">
                    <div className="view-all-service-container-day">
                      <h6 className="search-display-review">
                        {t("header.timeHeading")}
                      </h6>
                      <div className="row">
                        <div className="col-4 days">
                          {daysOfWeek.slice(0, 7).map((day, index) => (
                            <div key={index}>{day}</div>
                          ))}
                        </div>
                        <div className="col-8 days">
                          {daysOfWeek.slice(0, 7).map((day, index) => (
                            <div key={index}>
                              {filteredServices.vendor_info?.bussiness_hours
                                ? filteredServices.vendor_info.bussiness_hours[
                                  day
                                ]?.shop_open === "true"
                                  ? `${filteredServices.vendor_info.bussiness_hours[day]?.open} to ${filteredServices.vendor_info.bussiness_hours[day]?.close}`
                                  : t("globalItems.closed")
                                : t("globalItems.closed")}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-4 mb-2 view-all-service-container">
                  <div className="venue-name-h5">
                    {t("globalItems.aboutTheStore")}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getAboutUs(filteredServices),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="view-all-btn mt-4 mb-3">
              <div className="search-display-desc-btn">
                <Button
                  className={`btn search-display-desc-btn-Service ${activeButton === "service"
                    ? "search-desc-active-btn"
                    : "search-desc-de-active-btn"
                    }`}
                  onClick={() => handleButtonClick("service")}
                >
                  <a href="#service">{t("teamSetupField.services")}</a>
                </Button>
                <Button
                  className={`btn search-display-desc-btn-Review ${activeButton === "review"
                    ? "search-desc-active-btn"
                    : "search-desc-de-active-btn"
                    }`}
                  onClick={() => handleButtonClick("review")}
                >
                  <a href="#review"> {t("globalItems.Reviews")}</a>
                </Button>
              </div>
            </div>
            {currentPage === "service" && (
              <div
                className="view-all-service-container"
                style={{
                  display: activeButton === "service" ? "block" : "none",
                }}
              >
                <div className="row">
                  {filteredServices &&
                    filteredServices.service_info
                      ?.slice(0, displayedItems)
                      .map((service, index) => (
                        <div key={index} className="col-lg-6 col-md-12">
                          {service.is_enabled && (
                            <>
                              <div>
                                <section className="best-deals">
                                  <div className="best-deals__deal-item">
                                    <div className="deal-item__deal-header">
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="deal-header__title venue-name-h5">
                                            {selectedLanguage === "thai" &&
                                              selectedLanguage !== undefined &&
                                              selectedLanguage !== null
                                              ? service.service_name_thai
                                              : service.service_name}
                                          </div>
                                        </div>
                                        <div className="col-6 view-all-heart-icon">
                                          <span>
                                            <i
                                              className={`${service.favourite
                                                .is_service_favourite
                                                ? "bi bi-suit-heart-fill"
                                                : "bi bi-suit-heart"
                                                }`}
                                              style={{
                                                cursor: "pointer",
                                                color: service.favourite
                                                  .is_service_favourite
                                                  ? "red"
                                                  : "",
                                              }}
                                              onClick={() =>
                                                handleLikeClick(
                                                  service.favourite
                                                    .is_service_favourite,
                                                  service.favourite
                                                    .favourite_id,
                                                  service.service_id,
                                                  "service",
                                                  role
                                                )
                                              }
                                            ></i>{" "}
                                            <div
                                              className={`modal fade ${showModal
                                                ? "d-block show"
                                                : "d-none"
                                                }`}
                                              id="exampleModal"
                                              tabIndex="-1"
                                              aria-labelledby="exampleModalLabel"
                                              aria-hidden="true"
                                            >
                                              <SignIn
                                                setShowModal={setShowModal}
                                                showModal={showModal}
                                                type={type}
                                                commonId={commonId}
                                                userType={userType}
                                              />
                                            </div>
                                          </span>
                                          &nbsp;&nbsp;
                                          <span>
                                            <CustomShare
                                              className="search-display-desc-container3-icons-share"
                                              placement={"bottom"}
                                              items={shareItems}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <span className="view-all-star">
                                        {Array.isArray(perticullarRating) &&
                                          perticullarRating.map((item) =>
                                            item.service_id ===
                                              service.service_id ? (
                                              <div key={item.service_id}>
                                                <Rate
                                                  allowHalf
                                                  disabled
                                                  value={item.ratings}
                                                />
                                                <span className="min-content">
                                                  &nbsp;&nbsp;&nbsp;
                                                  <i className="bi bi-clock-fill clock-icon me-1"></i>
                                                  {service?.duration}{" "}
                                                  {t("notification.minute")}
                                                </span>
                                              </div>
                                            ) : null
                                          )}
                                      </span>
                                      <div className="display-service-price-outer">
                                        <span className="search-display-best-deals-span">
                                          <CurrencyDisplay
                                            value={
                                              service.special_price
                                                ? service.special_price
                                                : service.price
                                            }
                                            subPrice={service.price}
                                          />
                                        </span>
                                        <span>
                                          <DiscountPercent
                                            value={service?.discount_on_service}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="search-display-desc-container3-para1">
                                      {selectedLanguage === "thai" &&
                                        selectedLanguage !== undefined &&
                                        selectedLanguage !== null ? (
                                        <>
                                          <p className="search-display-desc-description_thai">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  service.description_thai,
                                              }}
                                            ></div>
                                          </p>
                                          <p className="search-display-desc-description_thai">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  service.special_term_thai,
                                              }}
                                            ></div>
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p className="search-display-desc-description_eng">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: service.description_en,
                                              }}
                                            ></div>
                                          </p>
                                          <p className="search-display-desc-description_eng">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: service.special_term_en,
                                              }}
                                            ></div>
                                          </p>
                                        </>
                                      )}
                                    </div>

                                    <div className="mt-2 mb-2 search-display-service-desc-btn-div">
                                      {getReadMoreFun(service) && (
                                        <>
                                          <div>
                                            <div
                                              onClick={() =>
                                                handleShowDesc(service)
                                              }
                                              className="read-more-service-desc"
                                            >
                                              {t("ServiceField.readMore")}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div></div>
                                      <PeakDiscountModal
                                        showModalDiscount={showModalDiscount}
                                        handleCloseDiscountModal={
                                          handleCloseDiscountModal
                                        }
                                        handleShowDiscountModal={
                                          handleShowDiscountModal
                                        }
                                      />
                                      <div className="d-flex justify-content-">
                                        <div
                                          onClick={() =>
                                            handleAddToCart(service.service_id)
                                          }
                                          data_bs_toggle="modal"
                                          data_bs_target="#exampleModal"
                                          className={`cart-icon-btn btn me-2 cart-btn-outer ${getCartDisable(
                                            service.service_id,
                                            true
                                          )}`}
                                        >
                                          <span>
                                            <i
                                              className={`bi bi-cart-plus-fill shop-card-share mt-1 ${getCartDisable(
                                                service.service_id
                                              )}`}
                                            ></i>
                                          </span>
                                          <span
                                            className={`cart-btn-text ${getCartDisable(
                                              service.service_id
                                            )}`}
                                          >
                                            {t("cart.addToCart")}
                                          </span>
                                        </div>
                                        <div className="mb-2">
                                          <BookNowModalLogic
                                            className="display-service-button1"
                                            service_id={service.service_id}
                                            vender_details={
                                              filteredServices?.vendor_info
                                            }
                                            service_details={[service]}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                  {displayedItems < filteredServices.service_info.length && (
                    <>
                      <button className="cta" onClick={handleLoadMore}>
                        <span className="hover-underline-animation">
                          {t("validation.seeMore")}
                        </span>
                        <svg
                          id="arrow-horizontal"
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="10"
                          viewBox="0 0 46 16"
                        >
                          <path
                            id="Path_10"
                            data-name="Path 10"
                            d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                            transform="translate(30)"
                          ></path>
                        </svg>
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <CustomSpinner />
        )}
        {currentPage === "review" && (
          <div
            className="mt-4 view-all-service-container"
            style={{ display: activeButton === "review" ? "block" : "none" }}
          >
            <div className="row">
              {showReview ? (
                showReview.slice(0, displayedReview).map((review, index) => {
                  const date = new Date(review.create_at);
                  const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  };
                  let formattedDate = date.toLocaleDateString("en-US", options);
                  return (
                    <div
                      className="search-display-desc-container4-desc-container mt-2"
                      key={index}
                    >
                      <div className="comment-part">
                        <div className="user-img-part">
                          <div className="user-img">
                            <CustomImage
                              alt={"review_image"}
                              src={review.image_url || User}
                              width={"unset"}
                              height={"unset"}
                            />
                          </div>
                          <div className="user-text">
                            <div className="venue-name-h5">
                              {review.user_name}
                            </div>
                            <p>{formattedDate}</p>
                          </div>
                        </div>
                        <div className="comment">
                          <div className="search-display-desc-container4-icons-star">
                            <Rate
                              allowHalf
                              defaultValue={review.average_rating}
                            />
                          </div>
                          <p>{review.review}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    {t("ServiceField.noReviewAvailable")}
                  </div>
                </>
              )}

              {displayedReview < showReview?.length && (
                <>
                  <button className="cta" onClick={handleLoadReview}>
                    <span className="hover-underline-animation">
                      {t("validation.seeMore")}
                    </span>
                    <svg
                      id="arrow-horizontal"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="10"
                      viewBox="0 0 46 16"
                    >
                      <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                        transform="translate(30)"
                      ></path>
                    </svg>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: !(Object.values(filteredServices).length > 0)
            ? "20%"
            : "5%",
        }}
      >
        <Footer />
      </div>
      <DescModal
        showModal={showModalDesc}
        handleClose={handleCloseDesc}
        messageDesc={messageDesc}
        messageTerm={messageTerm}
      />

    </>}
    </>
  );
};
SearchAndDisplayDesc.propTypes = {
  previousState: PropTypes.object,
  previousPath: PropTypes.string,
  vender_details: PropTypes.object,
  handleLikeClick: PropTypes.func,
  favouriteServices: PropTypes.array,
  handleLikeClickShop: PropTypes.func,
  favouriteShops: PropTypes.array,
  handleAddToCart: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  filteredServices: PropTypes.array,
  vendor_id: PropTypes.string,
  type: PropTypes.string,
  commonId: PropTypes.string,
  userType: PropTypes.string,
  title: PropTypes.string,
  shareUrl: PropTypes.string,
  handleAddToCartCount: PropTypes.func,
};

export default SearchAndDisplayDesc;
