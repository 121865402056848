import './SuggestionService.css';
import { React, PropTypes, Form, Button, Locale } from '../../../libraries'
import { Modal } from 'react-bootstrap';

const SuggestionModal = ({
  showSuggestionModal,
  handleSuggestionClose,
  handleSuggestion,
}) => {
  const { t } = Locale();
  return (
    <Modal
      show={showSuggestionModal}
      onHide={() => handleSuggestionClose({ status: 'cancel' })}
      dialogClassName="custom-modal customModal"
    >
      <Modal.Header
        closeButton
        className="headerLine"
        onClick={() => handleSuggestionClose({ status: 'cancel' })}
      />
      <Modal.Body>
        <div>
          <Form
            layout="vertical"
            autoComplete="off"
            onFinish={handleSuggestion}
          >
            <Form.Item
              className="suggestion-service-modal-formItem"
              label={
                <span>
                  {t('ServiceField.suggestedName')}
                  <span className="custom-astric">*</span>
                </span>
              }
              name="suggestedName"
              rules={[
                {
                  required: true,
                  message: `${t('field.pleaseEnter')} ${t('ServiceField.suggestedName')}`,
                },
              ]}
            >
              <input className="input-field-suggestion-modal form-control" />
            </Form.Item>
            <Form.Item className="text-center">
              <Button
                type="primary"
                id="submitButton"
                className="suggest-service-submit-button"
                htmlType="submit"
              >
                {t('button.submitButton')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

SuggestionModal.propTypes = {
  showSuggestionModal: PropTypes.bool,
  handleSuggestionClose: PropTypes.func,
  handleSuggestion: PropTypes.func,
};

export default SuggestionModal;