import './Branches.css';
import { React, PropTypes, useState, Locale, Modal } from '../../../libraries';
import { BootTable } from '../../../common/bootstrapTable/bootTable';
import { CustomSpinner, Heading, EmptyComponent } from '../../../common';
import { NewBranch, VendorHeader } from '../index';
import { UnderReviewLogic } from '../../component';
const Branches = ({
  feildblanck,
  isShowEmptyPage,
  isOrganizationStatus,
  loader,
  subDistrictOption,
  filterOption2,
  onChange,
  onSearch,
  postalCode,
  totalPage,
  handlePaginationChange,
  handleChangeFilter,
  filterFormValue,
  setFilterFormValue,
  setFormErrors,
  formErrors,
  setFormValues,
  formValues,
  setSelectedImages,
  setSelectedImage,
  selectedImage,
  selectedImages,
  handleSubmit,
  handleImageUpload,
  handleDeselectImage,
  handleChange,
  data,
  handleStatusChange,
}) => {
  const { t } = Locale();
  const heading = [
    'Branch Id',
    'Branch Name',
    'Branch Address',
    'Status',
    'Action',
  ];
  const [branchData, setBranchData] = useState(data);
  const [editBookingModalOpen, setEditBookingModalOpen] = useState(false);

  const closeEditBookingModal = () => {
    feildblanck();
    setEditBookingModalOpen(!editBookingModalOpen);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Modal
        open={editBookingModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        className="branch-modal"
      >
        <NewBranch
          subDistrictOption={subDistrictOption}
          filterOption2={filterOption2}
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          postalCode={postalCode}
          handleSubmit={handleSubmit}
          formErrors={formErrors}
          handleChange={handleChange}
          formValues={formValues}
          handleDeselectImage={handleDeselectImage}
          selectedImages={selectedImages}
          setFormValues={setFormValues}
          setSelectedImages={setSelectedImages}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
          handleImageUpload={handleImageUpload}
          closeEditBookingModal={closeEditBookingModal}
          setFormErrors={setFormErrors}
        />
      </Modal>
      <div className="heading-outer-div">
        <VendorHeader />
        <Heading
          text={t('vendor.branches')}
          icon={'bi bi-diagram-3'}
          button={
            <button
              onClick={() => closeEditBookingModal()}
              className="btn branches-buttton"
            >
              {t('vendor.newBranch')}
            </button>
          }
        />
        <div className="after-heading-outer-div">
          {isOrganizationStatus !== 'verified' ? (
            <>
              <UnderReviewLogic />
            </>
          ) : (
            <>
              <div className="user-common-box">
                <div className={loader ? `d-none` : ''}>
                  <div className="user-common-box-inner">
                    {(data && data.length > 0) ||
                      isShowEmptyPage === 'emptyBranchFilter' ? (
                      <>
                        {heading && (
                          <BootTable
                            subDistrictOption={subDistrictOption}
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={filterOption}
                            postalCode={postalCode}
                            handlePaginationChange={handlePaginationChange}
                            handleChangeFilter={handleChangeFilter}
                            totalPage={totalPage}
                            filterFormValue={filterFormValue}
                            setFilterFormValue={setFilterFormValue}
                            setFormErrors={setFormErrors}
                            handleSubmit={handleSubmit}
                            formErrors={formErrors}
                            handleChange={handleChange}
                            formValues={formValues}
                            handleDeselectImage={handleDeselectImage}
                            selectedImages={selectedImages}
                            setFormValues={setFormValues}
                            setSelectedImages={setSelectedImages}
                            setSelectedImage={setSelectedImage}
                            selectedImage={selectedImage}
                            handleImageUpload={handleImageUpload}
                            handleStatusChange={handleStatusChange}
                            setBranchData={setBranchData}
                            heading={heading}
                            branchData={branchData}
                            bootData={data}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {isShowEmptyPage === 'emptyBranch' && (
                          <EmptyComponent type="isBranch" />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {loader && <CustomSpinner />}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
Branches.propTypes = {
  subDistrictOption: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  filterOption: PropTypes.string,
  postalCode: PropTypes.array.isRequired,
  totalPage: PropTypes.number,
  handlePaginationChange: PropTypes.func,
  handleChangeFilter: PropTypes.func,
  filterFormValue: PropTypes.object,
  setFilterFormValue: PropTypes.func,
  setFormErrors: PropTypes.func,
  formErrors: PropTypes.object,
  setFormValues: PropTypes.func,
  formValues: PropTypes.object,
  setSelectedImages: PropTypes.func,
  setSelectedImage: PropTypes.func,
  selectedImage: PropTypes.string,
  selectedImages: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleImageUpload: PropTypes.func,
  handleDeselectImage: PropTypes.func,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  handleStatusChange: PropTypes.func,
};

export default Branches;