import { apiCall } from "../base";

const vendorFetchProfileDetails = async () => {
    const response = await apiCall('authentication/user_details', 'GET');
    return response;
  };
  
  const vendorEditMyProfile = async (params) => {
    const response = await apiCall('business/user', 'PATCH', params);
    return response;
  };

  const organizationStatus = async () => {
    const response = await apiCall(
      '/authentication/organization_verification',
      'GET',
    );
    return response;
  };

  export {
    vendorFetchProfileDetails,vendorEditMyProfile,organizationStatus
  }