import './Shop.css';
import { React, PropTypes, Button, Modal, Locale, Pagination, Rate } from '../../../libraries';
import { DefaultShop } from '../../../assets/Images.js';
import { CustomSpinner, Heading, EmptyComponent } from '../../../common';
import { CustomShare, ReviewAndRatingsHeaderLogic, ShopModalLogic } from '../../../components';
import { VendorHeader } from '../../../vendor/pages';
import { CustomImage } from '../../../lazyComponent/index.js';
import { useState } from 'react';
const Shop = ({
  setUpdateReviewRating,
  showRatingsShop,
  shopRating,
  handleShareUrlChange,
  shareItems,
  setShopRating,
  setServiceIdRating,
  serviceIdRating,
  data,
  setModal1Open,
  modal1Open,
  handleLikeClickShop,
  favouriteShops,
  viewAllFunction,
  isLoader,
  isErroPage,
  filterValue,
  total_pages,
  handlePaginationChange,
}) => {
  const { t } = Locale();
  const deletionRequest = localStorage.getItem('status') === 'requested';
  const selectedLanguage = localStorage.getItem('i18nextLng')
  const [count,setCount]=useState(0);
  const handleLikeClickShopArg = (item) => {
    return item?.favourite?.is_vendor_favourite
      ? item?.favourite?.favourite_id
      : item?.vendor_id;
  };

  const getVenueName = (item) => {
    return (selectedLanguage === 'thai') ? ((item.venue_name_thai) ? (item.venue_name_thai) : (item.venue_name)) : (item.venue_name)
  }
  return (
    <>
      <div className={modal1Open ? `fade modal-backdrop show` : ''}></div>
      <div
        className={`heading-outer-div user-outer-div ${deletionRequest ? 'deletionRequest-user-header' : ''}`}
      >
        <VendorHeader />
        <Heading text={t('vendorReview.reviews')} icon="bi bi-hand-thumbs-up" />
        <div className="after-heading-outer-div">
          <div className="user-common-box">
            <div className="user-common-box-inner">
              <div className="user-common-box-fix-content row-box">
                <ReviewAndRatingsHeaderLogic />
              </div>
              <div
                className={
                  !isLoader
                    ? `add-to-fav-container add-to-inner-fav-container mt-2 user-inner-content-scrollar user-inner-content-scrollar-after-fix-content`
                    : 'd-none'
                }
              >
                <div className="container-fluid add-to-fav-container3">
                  <div className="mb-4 h-auto">
                    <>
                      {data && data.length > 0 && !isLoader && (
                        <div className="row user-favourite-shop-row">
                          {data.map((item, index) => (
                            <div className="col-lg-6 col-md-6 mt-3" key={index}>
                              <div className="shop-cart-dd">
                                <div className="user-img-review">
                                  <div className="image-shop-outer-div">
                                    <CustomImage
                                      src={item.image_url || DefaultShop}
                                      alt="f"
                                      width={'unset'}
                                      height={'unset'}
                                      onClick={() =>
                                        viewAllFunction(
                                          item.vendor_id,
                                          item.venue_name,
                                        )
                                      }
                                      className="cursor-point-class"
                                    />
                                  </div>
                                  <div className="user-text-review">
                                    <p
                                      onClick={() =>
                                        viewAllFunction(
                                          item.vendor_id,
                                          item.venue_name,
                                        )
                                      }
                                      className="display-service-heading cursor-point-class"
                                    >
                                      {getVenueName(item)}
                                    </p>
                                    <div className="shop-review-addres-rating">
                                      <div className="shop-card-address review-shop-rating-web">
                                        <Rate
                                          allowHalf
                                          disabled
                                          value={item.vendor_average_rating}
                                        ></Rate>
                                      </div>
                                      <p className="shop-address-p-tag">
                                        <span className="shop-card-address">
                                          {item.address}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="shop-icons">
                                      <span>
                                        <i
                                          className={`${item?.favourite?.is_vendor_favourite
                                            ? 'bi bi-suit-heart-fill'
                                            : 'bi bi-suit-heart'
                                            }`}
                                          style={{
                                            cursor: 'pointer',
                                            color: favouriteShops?.some(
                                              (favorite) =>
                                                favorite.organization_id ===
                                                item?.vendor_id,
                                            )
                                              ? 'red'
                                              : '',
                                          }}
                                          onClick={() =>
                                            handleLikeClickShop(
                                              handleLikeClickShopArg(item),
                                              item?.favourite
                                                ?.is_vendor_favourite,
                                            )
                                          }
                                        ></i>
                                      </span>
                                      <span>
                                        <CustomShare
                                          className="shop-card-share-shop"
                                          placement={'bottom'}
                                          shareChange={() =>
                                            handleShareUrlChange(item)
                                          }
                                          items={shareItems}
                                        />
                                      </span>
                                      <div className="review-shop-rating-mobile">
                                        <Rate
                                          allowHalf
                                          disabled
                                          value={item.vendor_average_rating}
                                        ></Rate>
                                      </div>
                                      <span>
                                        {item.vendor_rating === null ? (
                                          <Button
                                            className="view-button-shop"
                                            onClick={() => {
                                              setServiceIdRating(
                                                item.vendor_id,
                                              );
                                              setShopRating(null);
                                              setModal1Open(true);
                                              setCount((prev)=>prev+1)
                                            }}
                                          >
                                            {t('globalItems.rating')}
                                          </Button>
                                        ) : (
                                          <Button
                                            className="view-button-shop"
                                            onClick={() => {
                                              showRatingsShop(item);
                                              setCount((prev)=>prev+1)
                                              setModal1Open(true);
                                            }}
                                          >
                                            {t('button.view')}
                                          </Button>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <Modal
                                className="reviewShop-rating-modal branch-modal"
                                open={modal1Open}
                                onCancel={() => setModal1Open(false)}
                                okButtonProps={{ style: { display: 'none' } }}
                                cancelButtonProps={{
                                  style: { display: 'none' },
                                }}
                              >
                                <ShopModalLogic
                                count={count}
                                  setUpdateReviewRating={setUpdateReviewRating}
                                  setShopRating={setShopRating}
                                  shopRating={shopRating}
                                  serviceIdRating={serviceIdRating}
                                />
                              </Modal>
                            </div>
                          ))}
                        </div>
                      )}
                      {total_pages > 15 && (
                        <div className="text-center mt-3">
                          <Pagination
                            current={filterValue?.page_number}
                            pageSize={filterValue?.per_page}
                            total={total_pages}
                            showSizeChanger={false}
                            defaultCurrent={3}
                            onChange={handlePaginationChange}
                          />
                        </div>
                      )}
                      {isErroPage && (
                        <>
                          <EmptyComponent type="isShop" />
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
              {isLoader && (
                <>
                  <CustomSpinner />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Shop.propTypes = {
  setServiceIdRating: PropTypes.func,
  serviceIdRating: PropTypes.any,
  data: PropTypes.array,
  setModal1Open: PropTypes.func,
  modal1Open: PropTypes.bool,
  handleLikeClickShop: PropTypes.func,
  favouriteShops: PropTypes.array,
};
export default Shop;