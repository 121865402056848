import { React } from '../.././libraries';
const Label = ({ text, className }) => {
  return (
    <div>
      <label htmlFor={text} className={className}>
        {text}
      </label>
      <br />
    </div>
  );
};
export default Label;