import './CustomSelectCheckbox.css';
import { React, PropTypes, Locale, useEffect ,DownOutlined} from '../../../../libraries';

const CustomSelectCheckbox = ({
  toggleSelectItemVisibility,
  selectItemVisible,
  searchText,
  setSearchText,
  selectedServices,
  selectedItems,
  setSelectedItems,
  services,
}) => {

  const { t } = Locale();
  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (item === 'all') {
        return prevSelectedItems.length === services.length
          ? []
          : services.map((item) => item.id);
      } else {
        const index = prevSelectedItems.indexOf(item);
        if (index !== -1) {
          return [
            ...prevSelectedItems.slice(0, index),
            ...prevSelectedItems.slice(index + 1),
          ];
        } else {
          return [...prevSelectedItems, item];
        }
      }
    });
  };

  useEffect(() => {
    setSelectedItems(selectedServices);
  }, [selectedServices, setSelectedItems]);

  const handleSearchInputChange = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchText(searchQuery);
  };

  const filteredItems = services.filter((item) =>
    item.first_name.toLowerCase().includes(searchText),
  );

  return (
    <div>
      <div
        className="select-header"
        onClick={toggleSelectItemVisibility}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleSelectItemVisibility();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <span className="select-title">
          {t('header.select')} {t('teamSetupField.services')}
        </span>
        <DownOutlined />
      </div>
      {selectItemVisible && (
        <div id="select-item">
          <input
            type="text"
            value={searchText}
            onChange={handleSearchInputChange}
            placeholder="Search..."
          />
          <ul>
            <li key="all">
              <div className="row">
                <div className="col-1 edit-team-member-checkbox">
                  <input
                    // className={className}
                    type="checkbox"
                    checked={selectedItems.length === services.length}
                    onChange={() => handleCheckboxChange('all')}
                  />
                </div>
                <div className="col-11">
                  <label>{t('teamSetupField.selectAllServices')}</label>
                </div>
              </div>
            </li>
            {filteredItems.map((item) => (
              <li key={item.id}>
                <div className="row">
                  <div className="col-1 edit-team-member-checkbox">
                    <input
                      // className={className}
                      type="checkbox"
                      checked={selectedItems.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </div>
                  <div className="col-11">
                    <label>{item.first_name}</label>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
CustomSelectCheckbox.propTypes = {
  toggleSelectItemVisibility: PropTypes.func,
  selectItemVisible: PropTypes.bool,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  selectedServices: PropTypes.array,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  services: PropTypes.array,
};

export default CustomSelectCheckbox;
