import './UserBookingEdit.css';
import { React, PropTypes, useEffect, DatePicker, Select, dayjs, moment, DateTime, Locale } from '../../../libraries';
import { CustomModal } from '../../../common';
import { useEditUserBooking } from '../../../components';
import { getBookingByUserId } from '../../../services';

const EditUserBooking = ({
  bookindata,
  booking,
  setLoader,
  closeEditBookingModal,
  bookings,
  setBookings,
}) => {
  const {
    vendorOpenignHoursFunction,
    timeSlots,
    setDayName,
    vendorOpenignHours,
    handleDateSelect,
    formValues,
    setFormValues,
    handleChange,
    handleSubmit,
    formErrors,
  } = useEditUserBooking({
    booking,
    closeEditBookingModal,
    bookings,
    setBookings,
    setLoader,
    bookindata,
  });
  const { t } = Locale();
  useEffect(() => {
    const getSingleBooking = async () => {
      const showBooking = await getBookingByUserId(booking);
      const bookingTime = showBooking.data.booking_service.booking_time;
      const bookingDate = showBooking.data.booking_service.booking_date;

      // Convert booking time to Bangkok time
      const dateTimeInBangkok = DateTime.fromISO(bookingTime, {
        zone: 'Asia/Bangkok',
      });
      const formattedTime = dateTimeInBangkok.toFormat('HH:mm');

      setFormValues({
        customerName: showBooking.data.customer_name,
        serviceName: showBooking.data.service_name_en,
        duration: showBooking.data.duration,
        listedPrice: showBooking.data.booking_service.price,
        paidAmount: showBooking.data.paid_amount,
        discount: showBooking.data.total_discount,
        paymentType: showBooking.data.payment_type,
        status: showBooking.data.booking_service.status,
        scheduleTime: formattedTime,
        scheduleDate: bookingDate,
        teamMember: showBooking.data.team_member_name || '',
        delivery_type: showBooking.data.booking_service.delivery_type,
      });

      const dateObj = new Date(bookingDate);
      const options = { weekday: 'long' };
      const dayName = dateObj
        .toLocaleDateString('en-US', options)
        .toLocaleLowerCase();
      setDayName(dayName);

      if (showBooking && showBooking.data.vendor_business_hours) {
        vendorOpenignHoursFunction(showBooking.data.vendor_business_hours);
      }
    };

    getSingleBooking();
  }, [booking]);

  return (
    <>
      <CustomModal
        text={
          <form className="form">
            <div className="edit-booking-header">
              <h5 className="title">{t('venderBooking.editBooking')}</h5>
            </div>
            <div className="container mt-2">
              <div className="row">
                <div className="form-group mt-2 col-md-6 col-12">
                  <label className="width100">
                    <span className="">{t('venderBooking.scheduledDate')}</span>
                    <span className="custom-estric">*</span>
                    <DatePicker
                      placeholder="Select Date"
                      onChange={handleDateSelect}
                      value={
                        formValues?.scheduleDate &&
                        dayjs(formValues?.scheduleDate, {
                          format: 'YYYY-MM-DD',
                        })
                      }
                      disabledDate={(current) => {
                        if (current && current < moment().startOf('day')) {
                          return true;
                        }
                        if (
                          current &&
                          ((!vendorOpenignHours.sunday &&
                            current.day() === 0) ||
                            (!vendorOpenignHours.monday &&
                              current.day() === 1) ||
                            (!vendorOpenignHours.tuesday &&
                              current.day() === 2) ||
                            (!vendorOpenignHours.wednesday &&
                              current.day() === 3) ||
                            (!vendorOpenignHours.thursday &&
                              current.day() === 4) ||
                            (!vendorOpenignHours.friday &&
                              current.day() === 5) ||
                            (!vendorOpenignHours.saturday &&
                              current.day() === 6))
                        ) {
                          return true;
                        }
                        return false;
                      }}
                      className="user-profile-date-picker-ant form-control mt-1 user-booking-reschedule-booking"
                    />

                    <div className="error">{formErrors?.scheduleDate}</div>
                  </label>
                </div>
                <div className="form-group mt-2 col-md-6 col-12">
                  <label>
                    <span>{t('venderBooking.resheluleTime')}</span>
                    <span className="custom-estric">*</span>
                  </label>
                  <Select
                    value={formValues?.scheduleTime}
                    onChange={(value) => handleChange('scheduleTime', value)}
                    className="mt-1 user-edit-booking-time-dropdown"
                    placeholder="Select Schedule Time"
                  >
                    {timeSlots &&
                      timeSlots.map((time, index) => (
                        <Select.Option key={index} value={time}>
                          {time}
                        </Select.Option>
                      ))}
                  </Select>
                  <div className="error">{formErrors?.scheduleTime}</div>
                </div>
              </div>
            </div>
            <div className="form-group mt-4">
              <center>
                <input
                  type="button"
                  value="Cancel"
                  className="form-cancel-booking"
                  onClick={closeEditBookingModal}
                />
                <input
                  disabled={formValues?.status === 'cancelled_by_provider'}
                  type="submit"
                  onClick={handleSubmit}
                  value="Update"
                  className="form-submit-booking"
                />
              </center>
            </div>
          </form>
        }

      />
    </>
  );
};
EditUserBooking.propTypes = {
  booking: PropTypes.any,
  closeEditBookingModal: PropTypes.func,
  bookings: PropTypes.array,
  setBookings: PropTypes.func,
};

export default EditUserBooking;