import './uploadFileModal.css';
import { CustomButton } from '../customButton/customButton';
import { UploadIcon } from '../../assets/Images';
import { Locale, React } from '../../libraries/index';
import { ImageCropper, Small } from '../index';
const UploadImageModal = ({
  handleCancel,
  handleSubmit,
  imageName,
  err,
  fileInputRef,
  imagePath,
  handleFileChange,
  onCropComplete,
  crop,
  zoom,
  setZoom,
  setCrop,
}) => {
  const { t } = Locale();

  let closeButtonObject = document.getElementById(
    'upload_file_modal_close_button',
  );
  return (
    <>
      <div
        className="modal fade"
        id="uploadFileModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="mt-2 w-100">
                <h5 className="modal-title passwordchangemodal-heading d-flex justify-content-center">
                  {!imageName ? (
                    <>
                      {t('vendor.please')} {t('header.select')}{' '}
                      {t('vendor.image')}
                    </>
                  ) : (
                    <>{imageName}</>
                  )}
                </h5>
              </div>
              <div>
                <button
                  onClick={(e) => handleCancel(e, closeButtonObject)}
                  type="button"
                  id="upload_file_modal_close_button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
            </div>
            <div className="modal-body">
              <div className="container p-5">
                <div className={imagePath ? "upload-file-modal-div-after-image" : "upload-file-modal-div"}>
                  {!imagePath && (
                    <>
                      <img
                        alt="Upload icon"
                        src={UploadIcon}
                        className="img-fluid upload-file-img-icon"
                      />
                    </>
                  )}
                  {
                    <ImageCropper
                      uploadImage={imagePath}
                      onCropComplete={onCropComplete}
                      crop={crop}
                      zoom={zoom}
                      setZoom={setZoom}
                      setCrop={setCrop}
                      handleChange={handleFileChange}
                      accept={['JPG', 'PNG', 'JPEG', 'WEBP']}
                      className={'upload-file-input-file crop-image-file'}
                      fileInputRef={fileInputRef}
                    />
                  }
                </div>

                <div className="mt-5 upload-file-h6-tag">
                  <h6>{t('vendor.supportsFormate')}</h6>
                </div>
                <div className="upload-image-moda">
                  <Small name={imageName} err={err.imageName} />
                </div>
              </div>
              <div className="upload-file-button-outer-div">
                <span className="upload-file-cancel-button">
                  {' '}
                  <CustomButton
                    type="button"
                    className="btn upload-modal-cancel-button"
                    data_bs_dismiss="modal"
                    onClick={handleCancel}
                    closeButtonObject={closeButtonObject}
                    formType={'cancel-button'}
                    name={t('button.cancelButton')}
                  />
                </span>
                <span className="upload-file-upload-button">
                  {' '}
                  <CustomButton
                    type="button"
                    className="btn upload-modal-upload-button"
                    data_bs_dismiss="modal"
                    onClick={handleSubmit}
                    closeButtonObject={closeButtonObject}
                    formType={'uploadfile-modal'}
                    name={`${t('vendor.upload')} ${t('vendor.image')}`}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UploadImageModal;