import "./PaymentDone.css";
import { React, Locale, useNavigate } from "../../libraries";
const PaymentDone = ({ role }) => {
  const { t } = Locale();
  const navigate = useNavigate();
  const navigatePage = () => {
    if (!role) {
      navigate('/')
    }
    else {
      if (role === "Customer") {
        navigate('/user/booking')
      }
      else {
        navigate('/vendor/booking')
      }
    }
  }
  return (
    <div className="payment-done-continer">
      <div class="card">
        <div className="payment-done-page">
          <i class="checkmark">✓</i>
        </div>
        <h1 className="mt-4">{t("button.success")}</h1>
        <p>
          {t("signMessage.successMsg")}
        </p>
        <button className="btn btn-outline-success mt-2" onClick={navigatePage}>Continue booking</button>
      </div>
    </div>
  );
};
export default PaymentDone;
