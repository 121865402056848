import {
  React,
  useEffect,
  useState,
  useLocation,
  useNavigate,
} from '../../libraries/index';
import { AddToFavouriteSettingHeader } from '../../pages';
import { CustomerRoutes } from '../../routes/index.js';
const AddToFavouriteSettingHeaders = () => {
  const [isPage, setIsPage] = useState({
    accountEditPage: false,
    openingHours: false,
  });
  const pathlocation = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathlocation.pathname === `${CustomerRoutes.user}${CustomerRoutes.favouriteShop}`) {
      setIsPage((prevPage) => ({
        ...prevPage,
        accountEditPage: true,
        openingHours: false,
      }));
    } else if (pathlocation.pathname === `${CustomerRoutes.user}${CustomerRoutes.favouriteService}`) {
      setIsPage((prevPage) => ({
        ...prevPage,
        accountEditPage: false,
        openingHours: true,
      }));
    }
  }, [pathlocation.pathname]);

  const navigateFun = (e, path) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <>
      <AddToFavouriteSettingHeader navigateFun={navigateFun} isPage={isPage} />
    </>
  );
};
export default AddToFavouriteSettingHeaders;