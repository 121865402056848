import './ImageGallery.css';
import { React, PropTypes, useEffect, useState, Carousel } from '../../libraries';
import { DefaultShop } from '../../assets/Images';

const CustomImage = React.lazy(() => import('../../common/image/image'));
const ImageGallery = ({ images }) => {
  const [expandedImgSrc, setExpandedImgSrc] = useState(
    images && images.length > 0 ? images[0] : DefaultShop,
  );
  const [clickSideImage, setClickSideImg] = useState(false);

  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleImageClick = (src, index) => {
    setClickSideImg(true);
    setExpandedImgSrc(src);
    setCarouselIndex(index);
  };

  const handleCarouselChange = (current) => {
    setClickSideImg(false);
    setExpandedImgSrc(images[current]);
    setCarouselIndex(current);
  };

  useEffect(() => { }, [expandedImgSrc])

  return (
    <div>
      {images.length >= 1 ? (
        <>
          <div className="row  search-display-desc-outer-div">
            <div className="col-2 side-small-img">
              {images.map((image, index) => (
                <div className="column" key={index}>
                  <img
                    alt="Gallery"
                    className=""
                    src={image}
                    onClick={() => handleImageClick(image, index)}
                  />
                </div>
              ))}
            </div>

            <div className="col-10" id="carousel-inner-div">
              <Carousel
                arrows
                infinite={true}
                initialSlide={carouselIndex}
                afterChange={handleCarouselChange}
                arrowSize={24}
                dots={false}
              >
                {images.map((image, index) => (
                  <div key={index}>
                    <img
                      alt="Gallery"
                      id="expandedImg"
                      className="active w-100"
                      src={clickSideImage ? expandedImgSrc : image}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>

          {/* ------------------------ mobile view (max-width 574px) image-------------------- */}

          <div className="search-display-desc-outer-div-mobile search-display-desc-outer-div">
            <div id="carousel-inner-div">
              <div id="carousel-inner-div">
                <Carousel
                  arrows
                  infinite={true}
                  initialSlide={carouselIndex}
                  afterChange={handleCarouselChange}
                  arrowSize={24}
                  dots={false}
                >
                  {images.map((image, index) => (
                    <div key={index}>
                      <img
                        alt="Gallery"
                        id="expandedImg"
                        className="active w-100"
                        src={clickSideImage ? expandedImgSrc : image}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="side-small-img mt-3">
              {images.map((image, index) => (
                <div className="column" key={index}>
                  {index < 4 && (
                    <img
                      alt="Gallery"
                      className="gallery-image"
                      src={image}
                      onClick={() => handleImageClick(image, index)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="single-shop-img">
            <CustomImage
              alt="Gallery"
              id="expandedImg"
              className="active image-gallery-single-img"
              src={expandedImgSrc || DefaultShop}
              width={'unset'}
              height={'unset'}
            />
          </div>
        </>
      )}
    </div>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
};

export default ImageGallery;
