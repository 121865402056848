import {
  React,
  useEffect,
  useState,
  useDispatch,
  useSelector,
  useLocation,
} from '../../../libraries/index';
import { OpeningHoursPolicie } from '../../pages/index';
import { fetchAccountInformation } from '../../../redux/index';
import { handleErrorResponse } from '../../../services/index';
const OpeningHoursPolicies = ({ deletionRequest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { vendorAccoutnDetails } = useSelector((state) => state.vendor);
  const initialOpeningValue = {
    bussiness_hours: {
      monday: {
        open: '',
        close: '',
        shop_open: false,
      },
      tuesday: {
        open: '',
        close: '',
        shop_open: false,
      },
      wednesday: {
        open: '',
        close: '',
        shop_open: false,
      },
      thursday: {
        open: '',
        close: '',
        shop_open: false,
      },
      friday: {
        open: '',
        close: '',
        shop_open: false,
      },
      saturday: {
        open: '',
        close: '',
        shop_open: false,
      },
      sunday: {
        open: '',
        close: '',
        shop_open: false,
      },
    },
    serviceMyLocation: false,
    serviceCustomerLocation: false,
    breakTime: '',
    cash_payment: false,
    pay_online: false,
    dayValidAfterPurchase: '',
    cancelDayBeforeScheduleDate: '',
  };
  const [openingHourseFormValue, setOpeningHourseFormValue] = useState(initialOpeningValue);
  const [isLoader, setIsLoader] = useState(false);
  const [applyAll, setApplyAll] = useState(false);
  const [applyDisable, setApplyDisable] = useState(false);
  const isSection = localStorage.getItem('isSection');

  useEffect(() => {
    if (isSection !== 'isBusinessAccount') {
      localStorage.setItem('isSection', 'isBusinessAccount');
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoader(true);
        await dispatch(fetchAccountInformation());
      } catch (error) {
        handleErrorResponse(error);
      } finally {
        setIsLoader(false);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (vendorAccoutnDetails && vendorAccoutnDetails.organization) {
      let phone_number =
        vendorAccoutnDetails &&
        vendorAccoutnDetails.organization.phone &&
        vendorAccoutnDetails.organization.phone.split(' ').length > 1 &&
        vendorAccoutnDetails.organization.phone.split(' ')[1].toString();

      let tempCountryCode =
        vendorAccoutnDetails &&
        vendorAccoutnDetails.organization.phone &&
        vendorAccoutnDetails.organization.phone.split(' ').length > 1 &&
        vendorAccoutnDetails.organization.phone.split(' ')[0].toString();
      let tempImageArray;
      tempImageArray = Object.values(
        vendorAccoutnDetails.organization_image,
      ).map((imageObject) => imageObject.url);

      setOpeningHourseFormValue((prevFormValue) => ({
        ...prevFormValue,
        email: vendorAccoutnDetails.organization.email
          ? vendorAccoutnDetails.organization.email
          : 'vijay@gmail.com',
        bussiness_hours: vendorAccoutnDetails.organization.bussiness_hours,
        venue_name: vendorAccoutnDetails.organization.name,
        countrycode: tempCountryCode,
        venueThaiName:
          vendorAccoutnDetails.organization?.name_thai === 'null'
            ? ''
            : vendorAccoutnDetails.organization?.name_thai,
        address: vendorAccoutnDetails.organization.location,
        postcode: vendorAccoutnDetails.organization.postcode,
        city: vendorAccoutnDetails.organization.city,
        venuPhoneNumber: phone_number,
        venueEnglishDescription: vendorAccoutnDetails.organization.description,
        venueThaiDescription:
          vendorAccoutnDetails.organization.description_thai,
        website:
          vendorAccoutnDetails.organization.website === 'null'
            ? ''
            : vendorAccoutnDetails.organization.website,
        shopImages: tempImageArray,
        shopTempImages: vendorAccoutnDetails.organization_image,
        serviceMyLocation:
          vendorAccoutnDetails.organization.service_at_bussiness_location,
        serviceCustomerLocation:
          vendorAccoutnDetails.organization.service_at_customer_location,
        breakTime: vendorAccoutnDetails.organization.break_time,
        // cancellationPolicy: vendorAccoutnDetails.organization.cancellation_policy,
        cash_payment: vendorAccoutnDetails.organization.cash_payment,
        pay_online: vendorAccoutnDetails.organization.online_payment,
        dayValidAfterPurchase:
          vendorAccoutnDetails.organization.cancel_days_before_scheduled,
        cancelDayBeforeScheduleDate:
          vendorAccoutnDetails.organization.rescheduling_permitted_days,
      }));

      checkIfAllDaysAreSame((vendorAccoutnDetails.organization.bussiness_hours), true)
    }
  }, [vendorAccoutnDetails.organization]);

  const checkIfAllDaysAreSame = (bussiness_hours, firstTime) => {
    const mondayHours = bussiness_hours?.monday;
    const normalizeShopOpen = (value) => {
      return value === true || value === 'true';
    };
    const allDaysMatch =bussiness_hours&& Object?.keys(bussiness_hours)?.every(day => {
      const currentDay = bussiness_hours[day];
      return (
        currentDay.open === mondayHours.open &&
        currentDay.close === mondayHours.close &&
        normalizeShopOpen(currentDay.shop_open) === normalizeShopOpen(mondayHours.shop_open)
      );
    });
    if (allDaysMatch) {
      firstTime  ? setApplyDisable(true) : setApplyAll(true);
    } else {
      firstTime  ? setApplyDisable(false) : setApplyAll(false); // Optional, if you want to enable it otherwise
    }
  };

  useEffect(() => {
    checkIfAllDaysAreSame((openingHourseFormValue?.bussiness_hours), false);
  }, [openingHourseFormValue?.bussiness_hours])



  return (
    <>
      <OpeningHoursPolicie
        deletionRequest={deletionRequest}
        setApplyDisable={setApplyDisable}
        applyDisable={applyDisable}
        isLoader={isLoader}
        openingHourseFormValue={openingHourseFormValue}
        setOpeningHourseFormValue={setOpeningHourseFormValue}
        applyAll={applyAll}
        setApplyAll={setApplyAll}
      />
    </>
  );
};
export default OpeningHoursPolicies;
