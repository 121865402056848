import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

// Descriptions and Titles
const descriptionEN = 'Discover SURA360, your ultimate destination for beauty, relaxation, and dental services...';
const descriptionTH = 'พบกับ SURA360 จุดหมายปลายทางที่สมบูรณ์แบบสำหรับความงาม การผ่อนคลาย...';
const titleEN = "Sura360 - Relax. Refresh. Rejuvenate";
const titleTH = "Sura360 - ผ่อนคลาย สดชื่น ฟื้นฟู";

// Keywords
const englishKeywords = `Spa,Massage,Sura360,Service,Wellness center...`.trim().replace(/\s\s+/g, ' ');
const thaiKeywords = `สปา,นวด,Sura360,บริการ,ศูนย์สุขภาพ...`.trim().replace(/\s\s+/g, ' ');

const combinedKeywordsEN = englishKeywords;
const combinedKeywordsTH = thaiKeywords;

export const MetaTag = ({
  custom_title,
  custom_description,
  custom_img
}) => {
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    // Detect user's browser language
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang === 'th' || userLang === 'th-TH') {
      setLanguage('th');
    } else {
      setLanguage('en');
    }
  }, []);

  // Select the meta tags based on the detected language
  const title = language === 'th' ? titleTH : titleEN;
  const description = language === 'th' ? descriptionTH : descriptionEN;
  const keywords = language === 'th' ? combinedKeywordsTH : combinedKeywordsEN;

  // Ensure a valid image URL is passed
  const imageUrl = custom_img || "https://www.sura360.com/logo.png";  // Default fallback image

  return (
    <Helmet>
      {/* Dynamic Title */}
      <title>{custom_title ? `${custom_title} || Sura360` : title}</title>

      {/* Meta Tags */}
      <meta name="description" content={custom_description || description} />
      <meta name="keywords" content={keywords} />

      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={custom_description} />

      {/*----------- Open Graph Meta Tag ------------------------ */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={custom_title} />
      <meta property="og:description" content={custom_description} />
      <meta property="og:image" content={imageUrl} />

      {/*----------- Twitter Meta Tag ------------------------ */}
      <meta property="twitter:card" content={imageUrl} />
      <meta property="og:url" content={window.location.href} />
      <meta property="twitter:title" content={custom_title} />
      <meta property="twitter:description" content={custom_description} />
      <meta property="twitter:image" content="https://www.sura360.com/logo.png" />

      {/* Additional Meta Tags */}
      <link rel="canonical" href={window.location.href} />




      <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <meta name="google-site-verification" content="p_RHHXpQ4vlUkLnoEZ1fTRUsRzm2kEDd0obNKWqFTcc" />
    </Helmet>
  );
};
