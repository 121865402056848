import { React } from '../libraries';

const CustomImage = React.lazy(() => import('../common/image/image.js'));
const OrderHistory = React.lazy(() => import('../pages/OrderHistory/OrderHistory.js'));
const OrderHistoryLogic = React.lazy(() => import('../components/orderHistory/orderHistory.js'));
const NavbarLogic = React.lazy(() => import('../components/navbar/navbar.js'));
const HomeHeader = React.lazy(() => import('../pages/NewHome/Header/HomeHeader.js'));

export {
    CustomImage,
    OrderHistory,
    OrderHistoryLogic,
    NavbarLogic,
    HomeHeader
}