import './AboutUs.css';
import { React, useEffect, useState } from '../../libraries';
import { AboutUsImage } from '../../assets/Images';
import { Footer } from '../../common';
import { NavbarLogic } from '../../components';
import { BreadCrumb } from '../BreadCrumb/BreadCrumb';
import { CustomImage } from '../../lazyComponent';
import { MetaTag } from "../../customHooks/index";

const AboutUs = ({ aboutUsData, breadCrumbData }) => {
  const selectedLanguage = localStorage.getItem('i18nextLng');

  const [privacy, setPrivacy] = useState(0);

  useEffect(() => { }, [selectedLanguage, privacy]);

  const removeHtmlTags = (input) => {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.body.textContent || "";
  };

  const about_us_title =
    selectedLanguage === "thai"
      ? aboutUsData?.about?.title_in_thai
      : aboutUsData?.about?.title;
      
  const sub_content_sub_heading = (data) =>
    selectedLanguage === "thai"
      ? removeHtmlTags(data?.sub_content?.sub_heading_in_thai)
      : data?.sub_content?.sub_heading;


  return (
    <>
      <MetaTag custom_title={about_us_title}/>

      <NavbarLogic setPrivacy={setPrivacy} />
      <div className="container about-us-outer-container">
        <BreadCrumb data={breadCrumbData} />
        <div className="aboutus-img-div">
          <div className="row">
            <CustomImage
              src={AboutUsImage}
              className="img-fluid"
              alt="aboutus"
              width={"unset"}
              height={"unset"}
            />
            <div className="aboutus-title text-center">{about_us_title}</div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5">
            {aboutUsData?.about_us_image_url && (
              <div className="col-md-4 about-img-div">
                <CustomImage
                  alt={aboutUsData?.about_us_image_url}
                  src={aboutUsData?.about_us_image_url}
                  className="img-fluid"
                  width={"unset"}
                  height={"unset"}
                />
              </div>
            )}
            <div
              className={`${
                aboutUsData?.about_us_image_url && "col-md-8 about-image-desc"
              }`}
            >
              {selectedLanguage === "thai" ? (
                <p
                  className="description_in_thai"
                  dangerouslySetInnerHTML={{
                    __html: aboutUsData?.about?.description_in_thai,
                  }}
                ></p>
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: aboutUsData?.about?.description,
                  }}
                ></p>
              )}
            </div>
          </div>
          {aboutUsData?.about_sub_contents_data?.map((data, index) => (
            <div key={index} className="about-sub-section">
              <div className="row mt-5 mb-5">
                {data?.sub_content_image_url ? (
                  <>
                    <div className={`col-md-${index % 2 === 0 ? "8" : "4"}`}>
                      <p className="sub-heading-content">
                        {sub_content_sub_heading(data)}
                      </p>
                      {selectedLanguage === "thai" ? (
                        <p
                          className="description_in_thai"
                          dangerouslySetInnerHTML={{
                            __html: data?.sub_content?.description_in_thai,
                          }}
                        ></p>
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data?.sub_content?.description,
                          }}
                        ></p>
                      )}
                    </div>
                    <div
                      className={`col-md-${
                        index % 2 === 0 ? "4" : "8"
                      } about-sub-section`}
                    >
                      <CustomImage
                        src={data?.sub_content_image_url}
                        className="img-fluid"
                        alt="sub_content_image_url"
                        width={"unset"}
                        height={"unset"}
                      />
                    </div>
                  </>
                ) : (
                  <div className="col-md-12">
                    <p className="sub-heading-content">
                      {sub_content_sub_heading(data)}
                    </p>
                    {selectedLanguage === "thai" ? (
                      <p
                        className="description_in_thai"
                        dangerouslySetInnerHTML={{
                          __html: data?.sub_content?.description_in_thai,
                        }}
                      ></p>
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.sub_content?.description,
                        }}
                      ></p>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default AboutUs;