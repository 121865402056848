import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getContactUsDetails, getSocialLink } from '../../../services/index';

export const fetchUserFooterInformation = createAsyncThunk(
  'user/fetchUserFooterInformation',
  async () => {
    const footerResponse = await getContactUsDetails();
    const socialLinkResponse = await getSocialLink();
    return {
      contactDetail: footerResponse?.data?.contact_detail,
      socialLinks: socialLinkResponse?.data,
    };
  },
);

const slice = createSlice({
  name: 'user',
  initialState: {
    userFooterDetails: {},
    socialLinks: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserFooterInformation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserFooterInformation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userFooterDetails = action.payload.contactDetail;
        state.socialLinks = action.payload.socialLinks;
      })
      .addCase(fetchUserFooterInformation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default slice.reducer;