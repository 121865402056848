import './AddToFavouriteSettingHeader.css';
import { Locale, React } from '../../libraries/index';
import { CustomerRoutes } from '../../routes/index';

const AddToFavouriteSettingHeader = ({ isPage, navigateFun }) => {
  const { t } = Locale();
  return (
    <>
      <div className="user-booking-tab-outer-div">
        <span
          onClick={(e) => {
            navigateFun(e, `${CustomerRoutes.user}${CustomerRoutes.favouriteShop}`);
          }}
          className={`user-bookings-tab btn ms-1 ${isPage.accountEditPage ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'}`}
        >
          {t('favouritePage.shop')}
        </span>
        <span
          onClick={(e) => {
            navigateFun(e, `${CustomerRoutes.user}${CustomerRoutes.favouriteService}`);
          }}
          className={`user-bookings-tab btn ms-1 ${isPage.openingHours ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'}`}
        >
          {t('favouritePage.service')}
        </span>
      </div>
    </>
  );
};
export default AddToFavouriteSettingHeader;