import { apiCall } from "../base";

const getHomeDetail = async () => {
    const response = await apiCall('public/landing_sections', 'GET');
    return response;
  };
  const creteNewsLetter = async (params) => {
    const response = await apiCall('public/news_letters', 'POST', params)
    return response;
  }

export {getHomeDetail,creteNewsLetter}