import { Link, React } from '../../libraries/index';
const CustomLink = ({
  text,
  path,
  color,
  className,
  handleLinkClick,
  target,
}) => {
  return (
    <Link
      to={path}
      style={{ color }}
      className={className}
      onClick={handleLinkClick}
      target={target}
    >
      {text}
    </Link>
  );
};
export default CustomLink;