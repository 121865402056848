import { apiCall } from "../base";

const vendorOrgnizationEdit = async (params) => {
    const response = await apiCall('business/organization', 'PATCH', params);
    return response;
};
const vendorOrgnizationInformation = async () => {
    const response = await apiCall('business/organizations', 'GET');
    return response;
};
const delete_image = async (id) => {
    const role = localStorage.getItem('role');
    let param = {
        role: role,
    };
    const response = await apiCall(
        `/authentication/images/${id}`,
        'DELETE',
        param,
    );
    return response;
};
export {
    vendorOrgnizationEdit, vendorOrgnizationInformation, delete_image,
}