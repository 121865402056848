import './smallTag.css';
import { React } from '../../libraries';
const Small = ({ err, className }) => {
  return (
    <>
      {err ? (
        <small className={`error ${className}`}>{err}</small>
      ) : (
        <small className="error"></small>
      )}
    </>
  );
};
export default Small;