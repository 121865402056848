import { Rate } from 'antd';
export const RatingSection=({  rating })=> {
    return (
        <div>
        <Rate allowHalf disabled value={rating}/>
        <span className="rating-percentage">{rating?.toFixed(1)}</span>
        {
            rating ? (<span class="single-star">&#9733;</span>):<span class="single-star-unfill">&#9733;</span>
        }
        
        </div>
    );
  }

