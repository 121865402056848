import { apiCall } from '../base';
 
const getFooterDetails = async (endpoint) => {
    const response = await apiCall(`footer/${endpoint}`, 'GET');
    return response;
};
const getBlog = async () => {
    const response = await apiCall(`public/blogs`, 'GET');
    return response;
};
const contactUs = async (param) => {
    const response = await apiCall('footer/contacts', 'POST', param);
    return response;
};
const getSocialLink = async () => {
    const response = await apiCall('public/social_media_links', 'GET');
    return response;
}

const getAboutUsDetail = () => getFooterDetails('abouts');
const getPrivacyPolicies = () => getFooterDetails('privacy_policies');
const getBussinessPolicyDetail = () => getFooterDetails('business_partner_policies');
const getTermConditionDetail = () => getFooterDetails('terms_and_conditions');
const getCancelPolicyDetail = () => getFooterDetails('refund_and_cancellation_policies');
const getContactUsDetails = () => getFooterDetails('contact_details');
const getAllFAQ = () => getFooterDetails('faqs');


export {
    getAboutUsDetail,
    getPrivacyPolicies,
    getBussinessPolicyDetail,
    contactUs,
    getTermConditionDetail,
    getCancelPolicyDetail,
    getContactUsDetails,
    getBlog,
    getAllFAQ,
    getSocialLink
};
