import { Locale } from '../../libraries';
const UserMyProfileHeader = ({ currentPage, setCurrentPage }) => {
  const { t } = Locale();
  const isTeamMember = localStorage.getItem('role') === 'TeamMember';
  return (
    <>
      <div className="AccountSettingHeader-container">
        <div className="user-booking-tab-outer-div my-profile-section">
          <span
            onClick={() => setCurrentPage('myProfile')}
            className={`user-bookings-tab btn ms-1 ${currentPage === 'myProfile' ? 'user-bookings-tab-active':'user-bookings-tab-de-active'}`}
          >
            {t('userEdit.personalInfo')}
          </span>
          <span
            onClick={() => setCurrentPage('passwordChange')}
            className={`my-profile-change-password user-bookings-tab btn ms-1 ${currentPage === 'passwordChange' ? 'user-bookings-tab-active':'user-bookings-tab-de-active'}`}
          >
            {t('userEdit.passwordChange')}
          </span>
          {isTeamMember && (
            <>
              <span
                onClick={() => setCurrentPage('workingHour')}
                className={`my-profile-change-password user-bookings-tab btn ms-1 team-member-working-hour ${currentPage === 'workingHour' ? 'user-bookings-tab-active':'user-bookings-tab-de-active'}`}
              >
                {t('vendor.workingHours')}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default UserMyProfileHeader;