import { useState, useCallback } from '../../libraries';
import { getCroppedImg } from "../index";

const useCropImage = (selectedFile, setSelectedFile, fileName) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(selectedFile, croppedAreaPixels);
      try {
        setSelectedFile(croppedImage);
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        return file;
      } catch (error) {
        console.error('Error fetching or processing blob:', error);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return {
    crop,
    setCrop,
    zoom,
    setZoom,
    onCropComplete,
    showCroppedImage
  };
};
export default useCropImage;