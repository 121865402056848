import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./Navbar.css";
import { CustomDropDown, Notifications, PermissionDenied } from "../../../common";
import { ProfilePhoto, SuraLogo, SuraMobileLogo, thailand_img, usa_img } from "../../../assets/Images";
import { React, Link, useNavigate, Button, Locale, memo, useEffect, useState, useLocation, useSelector, useParams } from "../../../libraries";
import { NavbarSearch, NavbarCategory, CancelDeletionHeaderLogic } from "../../../components";
import { HomeRoutes, CustomerRoutes, BusinessRoutes, AuthRoutes } from "../../../routes/index";
import { MenuSlider, MarqueeComponent } from "../../index";
import { CustomImage } from "../../../lazyComponent";
import { decryptData } from "../../../customHooks/index";

const Navbar = memo(
  ({
    handleDashboard,
    handleHomePage,
    children,
    products,
    showInnerHeader,
    setPrivacy,
    showSideBar,
    showSortBar,
    serviceType,
    showData,
  }) => {
    const location = useLocation();
    const params = useParams();
    const { t, handleChangeLanguage } = Locale();
    const userFooterDetails = useSelector(
      (state) => state.userFooterDetails.userFooterDetails
    );
    const { encryptedData } = params || {}
    const decryptedData = encryptedData && decryptData(encryptedData)
    const { searchText } = decryptedData || {};
    const isServicePage = params?.subCategoryName || searchText;
    const isErrorPage = showData?.length === 0 && location.pathname === (HomeRoutes.home);
    const navigate = useNavigate();
    const [deletionRequest, setDeletionRequest] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isActiveSearch, setIsActiveSearch] = useState(false);
  const [showModalPermission, setShowModalPermission] = useState(false)

    const status = (localStorage.getItem("status"))
    const vendorAccountDeletion = (localStorage.getItem("deletionRequestStatus"));
    const role = localStorage.getItem("role");
    const userName = localStorage.getItem("name");
    const selectedLanguage = localStorage.getItem("i18nextLng");
    const isCustomer = localStorage.getItem("role") === "Customer";
    const iCategory = !localStorage.getItem("isSection");
    const isServer = localStorage.getItem("isServer") === 'off'
    const isVendor =
      localStorage.getItem("role") === "Vendor" ||
      localStorage.getItem("type") === true ||
      localStorage.getItem("type") === "true";
    const isTeamMember = localStorage.getItem("role") === "TeamMember";
    const userProfilePhoto = localStorage.getItem("profilePhoto");
    const [visibleSlide, setVisibleSlide] = useState(false);
    const [hideDisplayName, setHideDisplayName] = useState(false)
    const navigateProfile = () => {
      navigate(`${CustomerRoutes.user}${CustomerRoutes.editProfile}`);
    };

    const handleClosePermissionModal = () => {
      setShowModalPermission(false);
    }

    const showDrawer = () => {
      setVisibleSlide(true);
    };
    const sale =
      selectedLanguage === "en"
        ? userFooterDetails?.sale
        : userFooterDetails?.sale_thai;

    const items = [
      {
        key: "1",
        label: (
          <div className="cart-section-paragraph">
            <img
              height={15}
              width={25}
              src={usa_img}
              alt="English Flag"
              className="navbar-flag-icon"
            />
            &nbsp; {t("field.english")}
          </div>
        ),
        onClick: () => {
          handleChangeLanguage("en");
          if (setPrivacy) {
            setPrivacy((pre) => pre + 1);
          }
        },
      },
      {
        key: "2",
        label: (
          <div className="cart-section-paragraph">
            <img
              height={15}
              width={25}
              src={thailand_img}
              alt="Thailand Flag"
              className="navbar-flag-icon"
            />
            &nbsp;&nbsp;{t("field.thailand")}
          </div>
        ),
        onClick: () => {
          handleChangeLanguage("thai");
          if (setPrivacy) {
            setPrivacy((pre) => pre - 1);
          }
        },
      },
    ];

    const displayName = (role) => {
      if (role === "freelancer" || role === "Vendor") {
        return localStorage.getItem("name");
      }
      if (role === "Customer") {
        return userName;
      } else {
        return "SuraUser";
      }
    };

    const handleNearbyShopClick = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            navigate(`${HomeRoutes.shopNearMe}?lat=${latitude}&lng=${longitude}`);
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              setShowModalPermission(true)
            }
          },
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };

    const navigateOnSignIn = () => {
      navigate(AuthRoutes.signIn);
    };

    useEffect(() => {
      if ((status === "requested") || vendorAccountDeletion === 'requested') {
        setDeletionRequest(true);
      }
      else setDeletionRequest(false);
    }, [status]);

    const handleMouseEnter = () => {
      setIsOpen(true);
    };

    const handleMouseLeave = () => {
      setIsOpen(false);
    };

    const navigateAddToCart = (e) => {
      e.preventDefault();
      if (role !== "Customer") navigate(AuthRoutes.signIn);
      else {
        localStorage.setItem("isSection", "isCart");
        navigate(`${CustomerRoutes.user}${CustomerRoutes.addToCart}`);
      }
    };

    return (
      <>
        {showInnerHeader ? (
          <>
            <div
              className={`show-inner-header-web-inner-navbar-inner-outer-div ${deletionRequest && "after-deletion-request-inner-header"
                }`}
            >
              <div className="navbar-logo">
                {isVendor || isTeamMember ? (
                  <>
                    <Link onClick={(e) => handleDashboard(e)}>
                      <CustomImage
                        className="web-navbar-logo-image-tag img-fluid mobile-log-img-tag"
                        src={SuraMobileLogo}
                        alt="logo"
                        height={"53px"}
                        width={"53px"}
                      />
                      <CustomImage
                        className="web-navbar-logo-image-tag img-fluid"
                        src={SuraLogo}
                        alt="logo"
                        height={"53px"}
                        width={"100px"}
                      />
                    </Link>
                  </>
                ) : (
                  <>
                    <Link onClick={(e) => handleHomePage(e)}>
                      <CustomImage
                        className="web-navbar-logo-image-tag img-fluid mobile-log-img-tag"
                        src={SuraMobileLogo}
                        alt="logo"
                        height={"53px"}
                        width={"53px"}
                      />
                      <CustomImage
                        className="web-navbar-logo-image-tag img-fluid"
                        src={SuraLogo}
                        alt="logo"
                        height={"53px"}
                        width={"100px"}
                      />
                    </Link>
                  </>
                )}
              </div>
              {((isCustomer) || (!role)) && (
                <>
                  <div className="navbar-search d-flex">
                    <div className="search-box">
                      <NavbarSearch
                        className="nav-input-search1 user-module-inner-bottom-header"
                        type="search"
                        placeholder={`\u00A0${t("header.placeholder")}`}
                        name="search"
                      />
                    </div>
                    <div className="near-me-button">
                      <Button
                        className="near-me near-me-btn button-font-and-color btn-on-hover"
                        type="button"
                        onClick={handleNearbyShopClick}
                      >
                        {t("header.nearMe")}
                      </Button>
                    </div>
                  </div>
                </>
              )}
              <div className={`actions`}>
                {isCustomer && (
                  <>
                    <div className="cart">
                      <div className="navbar-cart-div">
                        <Link
                          title="Add To Cart"
                          className="header-cart-anchor"
                          onClick={(e) => navigateAddToCart(e)}
                        >
                          <span>
                            <i className="bi bi-cart3 header-cart"></i>
                          </span>
                          {products !== 0 && (
                            <sup className={"navbar-sup"}>{products}</sup>
                          )}
                        </Link>
                      </div>
                    </div>
                  </>
                )}
                {role && (
                  <div className="notification">
                    <div className="navbar-cart-div">
                      <Notifications />
                    </div>
                  </div>
                )}
                <div className="language">
                  <div className="language-outer-div">
                    <CustomDropDown
                      className="navbar-language-togle user-header-web-lang-dropdown"
                      placement={"bottomRight"}
                      items={items}
                      selectedLanguage={selectedLanguage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <>
              <div className="user-sidebar-cancel-deletion-header">
                {deletionRequest && (
                  <>
                    <CancelDeletionHeaderLogic
                      deletionRequest={deletionRequest}
                      setDeletionRequest={setDeletionRequest}
                    />
                  </>
                )}
              </div>
            </>
            <div
              className={`header ${deletionRequest && "after-deletion-request-navbar"} ${!isCustomer && "vendor-order-header"}`}
            >
              {!isVendor && !deletionRequest && (
                <>
                  <div className="header-offer text-center container-fluid">
                    <MarqueeComponent sale={sale} />
                  </div>
                </>
              )}

              <div className="navbar">
                <div
                  className={
                    isActiveSearch ? `active-search-logo` : `logo-section`
                  }
                >
                  {isVendor || isTeamMember ? (
                    <>
                      {visibleSlide && (
                        <MenuSlider
                          hideDisplayName={hideDisplayName}
                          setHideDisplayName={setHideDisplayName}
                          displayName={displayName}
                          handleNearbyShopClick={handleNearbyShopClick}
                          visibleSlide={visibleSlide}
                          setVisibleSlide={setVisibleSlide}
                        />
                      )}
                      <div className={!(isCustomer) ? 'vendor-menubar' : `menubar`}>
                        <div className={`iPad_barsMenu`}>
                          <span
                            className={`iPad_barsBtn`}
                            onClick={showDrawer}
                          ></span>
                        </div>
                      </div>
                      <Link onClick={(e) => handleDashboard(e)}>
                        <CustomImage
                          className="sura-logo img-fluid mobile-logo-img-tag"
                          src={SuraMobileLogo}
                          alt="logo"
                          height={"53px"}
                          width={"50px"}
                        />

                        <CustomImage
                          className="sura-logo img-fluid sura-log-web"
                          src={SuraLogo}
                          alt="logo"
                          height={"53px"}
                          width={"100px"}
                        />
                      </Link>
                    </>
                  ) : (
                    <>
                      {visibleSlide && (
                        <MenuSlider
                          hideDisplayName={hideDisplayName}
                          setHideDisplayName={setHideDisplayName}
                          displayName={displayName}
                          handleNearbyShopClick={handleNearbyShopClick}
                          visibleSlide={visibleSlide}
                          setVisibleSlide={setVisibleSlide}
                        />
                      )}
                      <div className="menubar">
                        <div className={`iPad_barsMenu`}>
                          <span
                            className={`iPad_barsBtn`}
                            onClick={showDrawer}
                          ></span>
                        </div>
                      </div>
                      <Link onClick={(e) => handleHomePage(e)}>
                        <CustomImage
                          className="sura-logo img-fluid mobile-logo-img-tag"
                          src={SuraMobileLogo}
                          alt="logo"
                          height={"53px"}
                          width={"50px"}
                        />
                        <CustomImage
                          className="sura-logo img-fluid sura-log-web"
                          src={SuraLogo}
                          alt="logo"
                          height={"53px"}
                          width={"100px"}
                        />
                      </Link>
                    </>
                  )}
                </div>
                {!isVendor && (
                  <>
                    <div className={`search`}>
                      <div className="search-field">
                        <NavbarSearch
                          setIsActiveSearch={setIsActiveSearch}
                          type="search"
                          placeholder={`\u00A0${t("header.placeholderheader")}`}
                          name="search"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div
                  className={`d-flex navbar-search-field-new ${isActiveSearch && `active-search-search-outer-div`
                    }`}
                >
                  {!isVendor && (
                    <>
                      <div
                        className={`navbar-search ${isActiveSearch && `active-search-search-div`
                          }`}
                      >
                        <div className="search-field">
                          <NavbarSearch
                            setIsActiveSearch={setIsActiveSearch}
                            type="search"
                            placeholder={`\u00A0${t("header.placeholder")}`}
                            name="search"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className={
                      isActiveSearch ? `active-search-action` : `actions`
                    }
                  >
                    {!role && (
                      <>
                        <div className="near-me-button">
                          <button
                            className="near-me near-me-btn button-font-and-color btn-on-hover"
                            type="button"
                            onClick={handleNearbyShopClick}
                          >
                            {t("header.nearMe")}
                          </button>
                        </div>
                        <div
                          className="sing-btn-on-hover signIn button-font-and-color read-more-font "
                          onClick={navigateOnSignIn}
                        >
                          {t("header.signin")}
                          {"/"}
                          {t("header.signup")}
                        </div>
                        <div
                          className="business-section dropdown"
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div
                            className={`business-link dropdown-toggle ${isOpen && "is-active-dropdown"
                              } read-more-font button-font-and-color`}
                            aria-expanded={isOpen ? "true" : "false"}
                          >
                            {t("header.becomPartner")}
                          </div>
                          <ul
                            className={`dropdown-menu ${isOpen ? "show" : ""}`}
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`${AuthRoutes.signUp}${BusinessRoutes.vendor}`}
                              >
                                {t("header.asVendor")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`${AuthRoutes.signUp}${BusinessRoutes.freelancer}`}
                              >
                                {t("header.asFreelancer")}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                    {isCustomer && (
                      <>
                        <div className="near-me-button">
                          <button
                            className="near-me near-me-btn button-font-and-color btn-on-hover mt-2"
                            type="button"
                            onClick={handleNearbyShopClick}
                          >
                            {t("header.nearMe")}
                          </button>
                        </div>
                        <div className="cart">
                          <div className="navbar-cart-div">
                            <Link
                              onClick={(e) => navigateAddToCart(e)}
                              title="Add To Cart"
                              className="header-cart-anchor"
                              href="/"
                            >
                              <span className="header-cart">
                                <i className="bi bi-cart3"></i>
                              </span>
                              {products !== 0 && (
                                <sup className={"navbar-sup"}>{products}</sup>
                              )}
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                    {role && (
                      <div className="notification d-flex">
                        <div className="navbar-cart-div">
                          <Notifications />
                        </div>
                      </div>
                    )}
                    <div className="language language-hamburger">
                      <div className="language-outer-div">
                        <CustomDropDown
                          className="navbar-language-togle user-header-web-lang-dropdown"
                          placement={"bottomRight"}
                          items={items}
                          selectedLanguage={selectedLanguage}
                        />
                      </div>
                      {role && (
                        <>
                          <div className="profile nvabar-profile">
                            <CustomImage
                              className={`user_header_profilePhoto ${userProfilePhoto && "localstorage-profile"
                                }`}
                              src={
                                userProfilePhoto
                                  ? userProfilePhoto
                                  : ProfilePhoto
                              }
                              alt="Profile"
                              width={"unset"}
                              height={"unset"}
                            />
                            <MenuSlider
                              hideDisplayName={hideDisplayName}
                              setHideDisplayName={setHideDisplayName}
                              visibleSlide={visibleSlide}
                              displayName={displayName}
                              setVisibleSlide={setVisibleSlide}
                            />
                          </div>
                        </>
                      )}
                      <div className="menusidebar-role-inner-width">
                        <MenuSlider
                          hideDisplayName={hideDisplayName}
                          setHideDisplayName={setHideDisplayName}
                          visibleSlide={visibleSlide}
                          displayName={displayName}
                          handleNearbyShopClick={handleNearbyShopClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-item item-right">
                {!iCategory && role === "Customer" && (
                  <>
                    {visibleSlide && (
                      <MenuSlider
                        hideDisplayName={hideDisplayName}
                        setHideDisplayName={setHideDisplayName}
                        displayName={displayName}
                        handleNearbyShopClick={handleNearbyShopClick}
                        visibleSlide={visibleSlide}
                        setVisibleSlide={setVisibleSlide}
                      />
                    )}
                    <div className="mobile-view-inner-search-fild-outer-div">
                      <div className="menubar">
                        <div className={`iPad_barsMenu`}>
                          <span
                            className={`iPad_barsBtn`}
                            onClick={showDrawer}
                          ></span>
                        </div>
                      </div>
                      <NavbarSearch
                        type="search"
                        placeholder={`\u00A0${t("header.placeholder")}`}
                        name="search"
                      />
                      {role && (
                        <>
                          &nbsp;&nbsp;
                          <div className="notification d-flex">
                            <div className="navbar-cart-div user-notification">
                              <Notifications />
                            </div>
                          </div>
                          &nbsp;&nbsp;
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              {iCategory && !isServer && !isErrorPage && (
                <>
                  <hr className="navbar-category-hr-tag" />
                  <NavbarCategory
                    hideDisplayName={hideDisplayName}
                    setHideDisplayName={setHideDisplayName}
                    className={"navbar-category"}
                    selectedLanguage={selectedLanguage}
                  />
                </>
              )}
              {(isServicePage ||
                (serviceType &&
                  serviceType.some(
                    (type) =>
                      type.category &&
                      type.category.name_en &&
                      type.category.name_en.trim() !== ""
                  ))) && (
                  <div className="menu-icon">
                    <div onClick={showSortBar}>
                      <i className="bi bi-sliders me-2"></i>
                      {t("header.sortBy")}
                    </div>
                    <div className="vr"></div>
                    <div onClick={showSideBar}>
                      <i className="bi bi-funnel me-2"></i>
                      {t("field.filters")}
                    </div>
                  </div>
                )}
              {(iCategory) && <>
                <div className="header-item item-right">
                  <div className="mobile-view-search-fild-outer-div">
                    {visibleSlide && (
                      <MenuSlider
                        hideDisplayName={hideDisplayName}
                        setHideDisplayName={setHideDisplayName}
                        displayName={displayName}
                        handleNearbyShopClick={handleNearbyShopClick}
                        visibleSlide={visibleSlide}
                        setVisibleSlide={setVisibleSlide}
                      />
                    )}
                    <div className="menubar">
                      <div className={`iPad_barsMenu`}>
                        <span
                          className={`iPad_barsBtn`}
                          onClick={showDrawer}
                        ></span>
                      </div>
                    </div>
                    <NavbarSearch
                      type="search"
                      placeholder={`\u00A0${t("header.placeholder")}`}
                      name="search"
                    />
                    {/* .user-notification .navbar-sup-notification */}
                    {role && (
                      <>
                        &nbsp;&nbsp;
                        <div className="notification d-flex">
                          <div className="navbar-cart-div home-notification">
                            <Notifications />
                          </div>
                        </div>
                        &nbsp;&nbsp;
                        <div className="user-icon"
                          onClick={navigateProfile}
                        >
                          <i class="bi bi-person"></i>
                        </div>
                      </>
                    )}
                  </div>
                </div>

              </>}
              {(showModalPermission) && <>
                <PermissionDenied showModal={showModalPermission} handleClose={handleClosePermissionModal} />
              </>}
            </div>

            {children && children}
          </>
        )}
      </>
    );
  }
);

export default Navbar;