import sharedRoutes from "./sharedRoutes";

const businessRoutes = {
  vendor: '/vendor',
  vendors: '/bussiness/*',
  bussiness: '/bussiness/',
  freelancer: '/freelancer',
  dashboard: 'dashbord',
  bussinessbookings: 'booking',
  addService: 'services/add',
  buisinesseditService: 'services/edit',
  businessReview: 'review',
  bussinessServices: 'services',
  payout: 'payout',
  payoutSummary: 'payout/summary',
  teamMembers: 'team_members',
  finance: 'finance',
  accountDetails: 'account/details',
  openinghoursandpolicies: 'account/opening_hours_and_policies',
  branches: 'branches',
  calender: 'calender',
  ...sharedRoutes
};

export default businessRoutes;