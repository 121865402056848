import { useEffect, useState, Locale, useNavigate, toast } from "../../libraries";
import type { DatePickerProps } from 'antd';
import { handleErrorResponse, handleSuccessResponse, changePassword, editProfile } from "../../services";
import {
  isSelect,
  isStrongPassword,
  isValidAddress,
  isValidConformPassword,
  isValidName,
  isValidPhoneNumber,
} from "../../common/validation/fieldRegex";
import jsonData from "../../jsonData/address.json";
import { checkFileSize } from "../../common/uploadFileModal/fileSizeLogic";
import { useCropImage } from "../../common";
const UserEditProfile = () => {
  const navigate = useNavigate();
  const { t } = Locale();
  const initialValues = {
    fname: "",
    lname: "",
    gender: "",
    dob: "",
    phoneNumber: "",
    address: "",
    postalCode: "",
    email: "",
    area: "",
    city: "",
    password: "",
    countrycode: "+66",
    currentPasswords: "",
    confirmPassword: "",
    file: "",
    image: "",
  };
  const previousValue = {
    fname: "",
    lname: "",
    gender: "",
    dob: "",
    phoneNumber: "",
    address: "",
    postalCode: "",
    email: "",
    area: "",
    city: "",
    password: "",
    countrycode: "+66",
    currentPasswords: "",
    confirmPassword: "",
    file: "",
    image: "",
  };
  const [previousFormValue, setPreviousFormValue] = useState(previousValue)
  const [formValue, setFormValue] = useState(initialValues);
  const [closeModal, setCloseModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [subDistrictOption, setSubDistrictOption] = useState([])
  const [profileVisible, setProfileVisible] = useState(true)
  const { crop, setCrop, zoom, setZoom, onCropComplete, showCroppedImage } = useCropImage(selectedFile, setSelectedFile, formValue?.file?.name);

  const navigateFunction = (url) => {
    navigate(url);
  };

  const handleDateSelect = async (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    await setFormValue((prevValues) => ({
      ...prevValues,
      dob: dateString,
    }));
  };

  const handleChange = (e) => {
    const formData = new FormData();
    if (e instanceof File) {
      setProfileVisible(false)
      if (e?.type?.includes('image') && checkFileSize(e, t)) {
        setFormValue((prevValues) => ({
          ...prevValues,
          file: e,
        }));
        formData.append("image", e);
        setSelectedFile(URL.createObjectURL(e));
      } else {
        return;
      }
    }

    else {
      const { name, value } = e.target;
      const errorLogs = checkValidation({
        ...formValue,
        [name]: value,
      });
      setFormErrors(errorLogs);
      setFormValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

  };

  const checkValidation = (values) => {
    var errors = {};
    if (Object.keys(values).length > 3) {
      errors = isValidName(values.fname, errors, "fname", `${t("field.fname")}`, t);
      errors = isValidName(values.lname, errors, "lname", `${t("field.lName")}`, t);
      errors = isValidPhoneNumber(
        values.phoneNumber,
        errors,
        "phoneNumber",
        `${t("field.phoneNumber")}`,
        t
      );
      errors = isValidAddress(values.address, errors, "address", `${t("field.address")}`, t);
      errors = isSelect(values.city, errors, "city", `${t("field.city")}`, t);
      errors = isSelect(values.postalCode, errors, "postalCode", `${t("field.postalCode")}`, t);
      errors = isSelect(values.dob, errors, "dob", "dob", t);
      errors = isSelect(values.gender, errors, "gender", `${t("field.gender")}`, t);
    } else {
      errors = isStrongPassword(
        values.currentPassword,
        errors,
        "currentPassword",
        t
      );
      errors = isStrongPassword(values.password, errors, "password", t);
      errors = isValidConformPassword(
        { password: values.password, cpassword: values.confirmPassword },
        errors,
        "confirmPassword",
        t
      );
    }
    return errors;
  };

  const handleClose = () => {
    setCloseModal(!closeModal);
  };


  const checkIfUpdated = () => {
    return JSON.stringify(formValue) !== JSON.stringify(previousFormValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let cropFile = await showCroppedImage();
    let err = {};
    err = checkValidation(formValue);
    setProfileVisible(true)
    setFormErrors(err);
    if (Object.keys(err).length === 0) {
      try {
        if (checkIfUpdated()) {
          formValue.phoneNumber =
            formValue.phoneNumber.split(" ").length <= 1
              ? formValue.countrycode.split("(")[0].trim() +
              " " +
              formValue.phoneNumber
              : formValue.phoneNumber;

          let response;
          let formDatas;
          formDatas = appendData(formValue, cropFile);
          response = await editProfile(formDatas);
          localStorage.setItem(
            "name",
            response.data.user.first_name + " " + response.data.user.last_name
          );
          if (response.data.images)
            localStorage.setItem("profilePhoto", response.data.images);
          setFormValue((pre) => ({
            ...pre,
            image: response.data?.images,
          }));
          handleSuccessResponse(response, response.data.message);
        }
        else {
          toast.error(t('userEdit.noUpdateMessage'));
      }
      } catch (err) {
        handleErrorResponse(err);
      }
    }
  };

  const handleAreaChange = (event) => {
    const areaValue = event.target.value;
    const matchingData = jsonData.find((item) =>
      item.districtNameEn.toLowerCase().includes(areaValue.toLowerCase())
    );
    if (matchingData) {
      setFormValue((prevValues) => ({
        ...prevValues,
        area: areaValue,
        postalCode: matchingData.postalCode.toString(),
        city: matchingData.districtNameEn,
      }));
    } else {
      setFormValue((prevValues) => ({
        ...prevValues,
        area: areaValue,
        postalCode: "",
        city: "",
      }));
    }
  };

  const handleChangePassword = async (e, formValue, setErr, formType, navigateUrl, navigate, closeButtonObject) => {
    e.preventDefault();
    let param = {
      currentPassword: formValue?.currentPassword,
      password: formValue.password,
      confirmPassword: formValue.confirmPassword,
    };
    const errorlogs = await checkValidation(param);
    setFormErrors(errorlogs);
    param = {
      old_password: formValue?.currentPassword,
      password: formValue.password,
      password_confirmation: formValue.confirmPassword,
      role: "user",
    };
    let response;
    if (Object.keys(errorlogs).length === 0) {
      try {
        response = await changePassword(param);

        handleSuccessResponse(response, response.data.message);
        closeButtonObject.click();
      } catch (error) {
        handleErrorResponse(error);
      }
    }
  };

  const appendData = (formValues, cropFile) => {
    const formData = new FormData();
    formData.append("role", localStorage.getItem("role"));
    formData.append("phone_number", formValues.phoneNumber);
    formData.append("city", formValues.city);
    formData.append("first_name", formValues.fname);
    formData.append("last_name", formValues.lname);
    formData.append("gender", formValues.gender.toLowerCase());
    formData.append("date_of_birth", formValues.dob);
    formData.append("address", formValues.address);
    formData.append("postcode", formValues.postalCode);
    if (formValues.file) {
      formData.append("image", cropFile);
    }
    return formData;
  };

  const onChange = (name, value) => {
    if (name === "postalCode") {
      const selectedPostalCode = value;
      const selectedPostalCodeData = jsonData.filter(item => item.postalCode === selectedPostalCode);
      const subDistrictOP = selectedPostalCodeData.map(item => ({ value: item.districtNameEn, label: item.districtNameEn }));
      setSubDistrictOption(subDistrictOP);
    }
    setFormValue({ ...formValue, [name]: value });
  };

  const onSearch = (value) => { };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterOption2 = (input, option) => option.label === parseInt(input);

  useEffect(() => { }, [closeModal]);

  return {
    filterOption2,
    onSearch,
    filterOption,
    onChange,
    closeModal,
    handleClose,
    setFormValue,
    selectedFile,
    navigateFunction,
    formErrors,
    formValue,
    handleDateSelect,
    appendData,
    handleChangePassword,
    handleAreaChange,
    handleSubmit,
    checkValidation,
    handleChange,
    subDistrictOption,
    setFormErrors,
    profileVisible,
    crop,
    setCrop,
    zoom,
    setZoom,
    onCropComplete,
    setPreviousFormValue
  };
};
export default UserEditProfile;
