import { React } from '../../libraries';
const CustomParagraph = ({ tag, className, text, title }) => {
  return (
    <>
      <p className={className} title={title}>
        {text}
        {tag}
      </p>
    </>
  );
};
export default CustomParagraph;