import './heading.css';
 const Heading = ({
  onClick,
  text,
  button,
  icon = 'bi bi-arrow-right-circle',
}) => {
  return (
    <>
      <div className="user-common-box headin-oute-container">
        <div className="heading-inner-box">
          {!button ? (
            <>
              <div className="heading-user-profile-outer-div">
                <span className="braches-text">
                  {onClick ? (
                    <>
                      <i
                        className={`${icon} heading-icon`}
                        onClick={onClick}
                      ></i>
                    </>
                  ) : (
                    <>
                      <i className={`${icon} heading-icon`}></i>
                    </>
                  )}
                  {text}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <div className="heading-user-profile-outer-div">
                  <span className="braches-text">
                    {' '}
                    <i className={`${icon} heading-icon`}></i> {text}
                  </span>
                </div>
                {button}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Heading;