import { React, lozad, useEffect } from '../../libraries';
import { DefaultServiceImg } from '../../assets/Images';
const CustomImage = ({
  src,
  className,
  alt,
  height,
  width,
  onClick,
  isLCP,
}) => {

  useEffect(() => {
    const observer = lozad('.lozad', {
      loaded: function (el) {
        el.onload = () => {
          el.classList.add('loaded');
        };
        el.onerror = () => {
          el.src = DefaultServiceImg;
        };
      },
    });
    observer.observe();
  }, [DefaultServiceImg]);

  return (
    <>
      {!onClick ? (
        <img
          loading={isLCP ? `eager` : `lazy`}
          data-src={src}
          width={width}
          alt={alt}
          className={`lozad ${className}`}
          height={height}
        />
      ) : (
        <img
          loading={isLCP ? `eager` : `lazy`}
          data-src={src}
          width={width}
          alt={alt}
          className={`lozad ${className}`}
          height={height}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default CustomImage;
