import { React, Input } from '../../libraries';
const PasswordField = ({
  id,
  name,
  value,
  type,
  onChange,
  placeholder,
  error,
  className,
}) => {
  return (
    <div>
      <Input.Password
        className={className}
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="off"
      />

      <div>{error && <small className="small-err">{error}</small>}</div>
    </div>
  );
};
export default PasswordField;