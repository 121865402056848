import './PayoutSummary.css';
import { CustomSpinner, Heading } from '../../../common';
import { Locale, Pagination, useLocation, useEffect } from '../../../libraries';
import { VendorHeader } from '../index.js';
import { CurrencyDisplay } from '../../../jsonData';
import { PayoutSummaryLogic, UnderReviewLogic } from '../../component';

const PayoutSummary = ({deletionRequest}) => {
  const location = useLocation();
  const { t } = Locale();
  const {
    total_pages,
    handlePaginationChange,
    formatDate,
    formatTime,
    dailyTransactionData,
    tableHeading,
    monthlyData,
    isOrganizationStatus,
    isLoader,
    handleNavigatePayoutPage,
    monthName,
    isOpenDailyTransaction,
    setIsOpenDailyTransaction,
    filterValue,
    downloadRecord,
    disableDownload,
  } = PayoutSummaryLogic();
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const dynamicMonthName =
    selectedLanguage === 'en'
      ? monthName?.monthNameEng
      : monthName?.monthNameThai;
  const isSection = localStorage.getItem('isSection');

  useEffect(() => {
    if (isSection !== 'isBusinessPayout') {
      localStorage.setItem('isSection', 'isBusinessPayout');
    }
  }, [location.pathname]);

  const getStatus = (obj) => {
    return selectedLanguage === 'en' ? obj?.status : obj?.status_thai;
  };

  return (
    <>
      <div className={`heading-outer-div vendor-header-payout-summary ${deletionRequest && 'vendor-deletionRequest-user-header'}`}>
        <VendorHeader />
        <Heading
          text={t('payout.PayoutSummary')}
          onClick={(e) => handleNavigatePayoutPage(e)}
          icon={'bi bi-caret-left-fill'}
        />
        <div className="after-heading-outer-div">
          {isOrganizationStatus !== 'verified' ? (
            <>
              <UnderReviewLogic />
            </>
          ) : (
            <>
              <div className={`user-common-box`}>
                <div className="user-common-box-inner">
                  <div className="user-common-box-fix-content row-box m-0 mb-3">
                    <div className="user-booking-tab-outer-div  vendor-payout-summary">
                      <span
                        className={`user-bookings-tab ms-1 user-bookings-tab-active`}
                      >
                        {dynamicMonthName}
                      </span>
                      <span
                        className={`user-bookings-tab  ms-1 user-bookings-tab-active vendor-payout-summary-download-icon ${disableDownload&&'download-btn-disabled'}` }
                        onClick={() => downloadRecord(dynamicMonthName)}
                        aria-disabled={disableDownload}
                      >
                        <i className="bi bi-file-earmark-arrow-down-fill"></i>
                      </span>
                    </div>
                  </div>
                  <div
                    className={`user-inner-content-scrollar-after-fix-content vendor-sumary-payout-outer-div  ${!isLoader ? `user-inner-content-scrollar` : 'd-none'}`}
                  >
                    <div className="row payout-summary-row">
                      <div className="col-lg-6 col-sm-6">
                        <div className="finance-inner-cart-div company-details-inner">
                          <div className="box-heading">
                            {`${t('vendor.bookings')} (${monthlyData?.bookings?.total_bookings || 0})`}
                          </div>
                          <div className="box-content mt-3">
                            <div className="vendor-payout-summary-booking-compelete">
                              <div>{t('payout.bookingComplete')}</div>
                              <div>{monthlyData?.bookings?.completed}</div>
                            </div>
                            <div className="vendor-payout-summary-booking-canceled">
                              <div>{t('payout.bookingCancel')}</div>
                              <div>{monthlyData?.bookings?.cancelled}</div>
                            </div>
                            <div className="vendor-payout-summary-booking-expire">
                              <div>{t('payout.bookingExp')}</div>
                              <div>{monthlyData?.bookings?.expired}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6  col-sm-6">
                        <div className="finance-inner-cart-div company-details-inner">
                          <div className="box-heading">
                            {t('payout.salesSummary')}
                          </div>
                          <div className="box-content mt-3">
                            <div className="vendor-payout-summary-booking-compelete">
                              <div>{t('payout.totalSales')}</div>
                              <div>
                                <CurrencyDisplay
                                  value={monthlyData?.total_sale}
                                />
                              </div>
                            </div>
                            <div className="vendor-payout-summary-booking-canceled">
                              <div>{t('payout.suraCommision')}</div>
                              <div>
                                <CurrencyDisplay
                                  value={monthlyData?.total_commission}
                                />
                              </div>
                            </div>
                            <div className="vendor-payout-summary-booking-expire">
                              <div>{t('payout.netPayout')}</div>
                              <div>
                                <CurrencyDisplay
                                  value={monthlyData?.total_revenue}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="payout-summary-daily-transaction">
                      <div className="payout-summary-daily-transaction-inner">
                        <div
                          className={`payout-summary-daily-transaction-heading ${isOpenDailyTransaction && 'daily-transaction-heading-active'}`}
                        >
                          <div className="payout-summary-daily-transaction-text">
                            {t('payout.dailyTransaction')}
                          </div>
                          <div className="payout-summary-daily-transaction-icon">
                            {isOpenDailyTransaction ? (
                              <i
                                className="bi bi-caret-up-fill"
                                onClick={() =>
                                  setIsOpenDailyTransaction(
                                    !isOpenDailyTransaction,
                                  )
                                }
                              ></i>
                            ) : (
                              <i
                                className="bi bi-caret-down-fill"
                                onClick={() =>
                                  setIsOpenDailyTransaction(
                                    !isOpenDailyTransaction,
                                  )
                                }
                              ></i>
                            )}
                          </div>
                        </div>
                        {isOpenDailyTransaction && (
                          <>
                            <div className="daily-transaction-details-outer">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    {tableHeading &&
                                      tableHeading.length > 0 &&
                                      tableHeading.map((obj, index) => (
                                        <th key={index}>{obj}</th>
                                      ))}
                                  </tr>
                                </thead>
                                {dailyTransactionData &&
                                  dailyTransactionData.length ? (
                                  <>
                                    <tbody>
                                      {dailyTransactionData.map(
                                        (obj, index) => (
                                          <tr key={index}>
                                            <td>{obj.booking_id}</td>
                                            <td>{obj.transaction_id}</td>
                                            <td>
                                              {formatDate(obj.created_at)}
                                            </td>
                                            <td>
                                              {formatTime(obj.created_at)}
                                            </td>
                                            <td className="vendor-dashbord-price-td">
                                              <CurrencyDisplay
                                                value={obj.total_paid_amount}
                                              />
                                            </td>
                                            <td>{getStatus(obj)}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </>
                                ) : (
                                  <>
                                    <tbody>
                                      <tr className="text-center">
                                        <td
                                          colSpan={'6'}
                                          className="notAvailable"
                                        >
                                          {t('payoutSummary.noTransaction')}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </>
                                )}
                              </table>
                              {total_pages > 10 && (
                                <div className="text-center">
                                  <Pagination
                                    current={filterValue?.page_number}
                                    pageSize={filterValue?.per_page}
                                    total={total_pages}
                                    showSizeChanger={false}
                                    defaultCurrent={3}
                                    onChange={handlePaginationChange}
                                  />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {isLoader && (
            <>
              <CustomSpinner />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default PayoutSummary;
