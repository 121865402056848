import {React,  useEffect, useState, useRef,Link ,Locale} from "../../libraries";


const MobileCategorySection = ({
    subCategoryClickVisible,
    handleCategoryClickFun,
    items,
    onCategoryClick,
    subCategoriesDatas,
    onSubCategoryClick,
    handleMouseLeave,
    setTitle,
    setIsBackBtn,
    activeCategoryId,
    setActiveCategoryId,
}) => {
    const { t } = Locale();
    const selectedLanguage = localStorage.getItem("i18nextLng");
    const [activeItemId, setActiveItemId] = useState(null); // To track the active menu item
    const submenu1Ref = useRef({});

    useEffect(() => {
        submenu1Ref.current = {};
    }, []);

    useEffect(() => {
        let lastScrollTop = 0;
        const delta = 5;
        const headerElement = document.querySelector("header");
        const navbarHeight = headerElement.offsetHeight;

        const handleScroll = () => {
            const st = window.scrollY;
            if (Math.abs(lastScrollTop - st) <= delta) return;

            if (st > lastScrollTop && st > navbarHeight) {
                headerElement.classList.remove("nav-down");
                headerElement.classList.add("nav-up");
            } else {
                if (st + window.innerHeight < document.documentElement.scrollHeight) {
                    headerElement.classList.remove("nav-up");
                    headerElement.classList.add("nav-down");
                }
            }
            lastScrollTop = st;
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const showSubMenu = (categoryId, title, titleInThai) => {
        // Update title based on selected language
        setTitle(selectedLanguage === "en" ? title : titleInThai);
        setActiveCategoryId(categoryId); // Set active category
        setIsBackBtn(true); // Enable back button
    };


    const handleItemClick = (itemId) => {
        setActiveItemId(itemId);
    };


    return <>
        <ul className="menu-main">
            {/* Category List */}
            {!activeCategoryId && items.map((item) => (
                <li
                    key={item.id}
                    className="menu-item-has-children"
                    onClick={(e) => {
                        handleItemClick(item.id);
                        !subCategoryClickVisible
                            ? handleCategoryClickFun(
                                e,
                                item.name_en,
                                item.name_en,
                                item.name_thai
                            )
                            : showSubMenu(item.id, item.name_en, item.name_thai);
                    }}
                    onMouseEnter={() =>
                        onCategoryClick(item.id, item.name_en, item.name_thai)
                    }
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <Link className="category">
                        {selectedLanguage === "thai" ? item.name_thai : item.name_en}
                        &nbsp;&nbsp;<i className="bi bi-chevron-down"></i>
                    </Link>
                </li>
            ))}
            {/* Subcategory List in mobile view----------------*/}
            {activeCategoryId && (
                <div className="sub-menu single-column-menu mobile-view-sub-category-list">
                    <ul className="submenu-main">
                        {subCategoriesDatas.map((subItem) => (
                            <li
                                key={subItem.id}
                                className="menu-item-has-children colorBlack "
                                onClick={(e) =>
                                    onSubCategoryClick(
                                        e,
                                        subItem.id,
                                        subItem.name_en,
                                        subItem.name_thai
                                    )
                                }
                            >
                                <Link>
                                    {selectedLanguage === "thai" ? subItem.name_thai : subItem.name_en}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </ul>

    </>
}

export default MobileCategorySection;