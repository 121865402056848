import './CancelDeletionHeader.css';
import { CustomButton } from '../../common/customButton/customButton';
import { Locale } from '../../libraries/index';
const CancelDeletionHeader = ({ handleSubmit }) => {
  const { t } = Locale();
  return (
    <>
      <div className="cancel-deletion-outer-div">
        <div className="cancel-deletion-inner-div mb-1 mt-1">
          <div className="cancel-deletion-content ">
            <span className="cancel-deletion-content-text">
              {t('userEdit.yourAccountGetsDeleted')}
            </span>
            <span className="cancel-deletion-content-cance-button">
              <CustomButton
                className="cancel-deletion-button"
                name={t('button.cancelRequestButton')}
                onClick={handleSubmit}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default CancelDeletionHeader;