import { React, Checkbox } from '../../libraries';
const CustomCheckbox = ({
  disabled,
  text,
  className,
  onChange,
  error,
  onClick,
}) => {
  return (
    <>
      <Checkbox
        disabled={disabled}
        onChange={onChange}
        className={`custom-check-box-ant ${className}`}
        onClick={onClick}
      >
        {text}
      </Checkbox>
      {error && <p className="small-err">{error}</p>}
    </>
  );
};
export default CustomCheckbox;