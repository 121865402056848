import { apiCall } from '../base';

const bookNow = async (params) => {
    const response = await apiCall('customer/bookings', 'POST', params);
    return response;
};

const getCheckoutUrl = async (params) => {
    const response = await apiCall(`customer/get_checkout_url?checkout_id=${params}`, 'get');
    return response;
};


const bookingPayment = async (params) => {
    const response = await apiCall(
        'customer/bookings/payment_callback',
        'POST',
        params,
    );
    return response;
};


const editBookings = async (params) => {
    const response = await apiCall(
        `customer/bookings/${params.id}`,
        'PUT',
        params.formData,
    );
    return response;
};

const getBookings = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    const response = await apiCall(`customer/bookings?${queryString}`, 'GET');
    return response;
};

const callCustomerRepay = async (id) => {
    const response = await apiCall(`customer/repay?booking_id=${id}`, 'GET');
    return response;
}

const getBookingBasedOnServiceId = async (param) => {
    const isCustomer = localStorage.getItem("role") === "Customer"
    let response;
    if (isCustomer) {
        response = await apiCall(`customer/bookings?booking_service_id=${param}`, 'GET');
    }
    else {
        response = await apiCall(`business/bookings?booking_service_id=${param}`, 'GET');
    }
    return response;
}

const getBookingByUserId = async (params) => {
    const response = await apiCall(`customer/bookings/${params}`, 'GET');
    return response;
};

const getCalculationPickAndTotalAmount = async (params) => {
    const response = await apiCall(
      `customer/bookings/calculation`,
      'POST',
      params,
    );
    return response;
  };
  
  const dataForUniqueId = async (params) => {
    const response = await apiCall(`customer/checkout_details?checkout_url=${params}`, 'GET');
    return response;
  };
export {getCalculationPickAndTotalAmount,dataForUniqueId, bookNow, editBookings, bookingPayment, getCheckoutUrl, getBookings, callCustomerRepay, getBookingBasedOnServiceId, getBookingByUserId };