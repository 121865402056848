import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getFavouriteServices,
  getFavouriteVendor,
} from '../../../services/index';

export const fetchLikes = createAsyncThunk('like/fetchLikes', async () => {
  const responseServices = await getFavouriteServices();
  const responseVendor = await getFavouriteVendor();
  return {
    favouriteServices: responseServices.data.data,
    favouriteVendor: responseVendor.data.data,
  };
});

const favouritesServicesSlice = createSlice({
  name: 'favouritesService',
  initialState: {
    favouriteServices: [],
    favouriteVendor: [],
    error: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLikes.pending, (state) => {
      state.error = 'pending';
    });
    builder.addCase(fetchLikes.fulfilled, (state, action) => {
      state.favouriteServices = action.payload.favouriteServices;
      state.favouriteVendor = action.payload.favouriteVendor;
      state.error = ''; // Clear the error on success
    });
    builder.addCase(fetchLikes.rejected, (state, action) => {
      state.favouriteServices = [];
      state.error = 'rejected: ' + action.error.message;
    });
  },
});

export default favouritesServicesSlice.reducer;
