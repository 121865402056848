import {React,Input} from '../../libraries';
const InputField = ({
  id,
  name,
  value,
  type,
  onChange,
  placeholder,
  error,
  className,
  setFormValue,
  disabled,
}) => {
  return (
    <div>
      <Input
        className={className}
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={(e) => onChange(e, setFormValue)}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete="off"
      />
      {error && <small className="input-field-err">{error}</small>}
    </div>
  );
};
export default InputField;