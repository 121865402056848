import './Sidebar.css';
import { React, useCallback, useEffect, useState, PropTypes, Locale, Link, useLocation, useNavigate, useCookies } from '../../../libraries/index';
import {
  BusinessRoutes,
  AuthRoutes,
} from '../../../routes/index';
import {
  logout,
  handleErrorResponse,
  handleSuccessResponse,
} from '../../../services';
import { CancelDeletionHeaderLogic } from '../../../components';

const VendorSidebar = ({ children, changeState, setIsOpen, isOpen,deletionRequest,setDeletionRequest}) => {
  const [menuOpenOrClose, setMenuOpenOrClose, removeMenuOpenOrClose] =
    useCookies(['menu']);
  const location = useLocation();
  const { t } = Locale();
  const freelancer =
    localStorage.getItem('type') === 'true' ||
    localStorage.getItem('type') === true;
  const isTeamMember = localStorage.getItem('role') === 'TeamMember';
  const [isActiveSection, setIsActiveSection] = useState();
  const navigate = useNavigate();
  const selectedLangugeThai = localStorage.getItem('i18nextLng') === 'thai';
  const handleSectionClick = async (section) => {
    if (section !== 'isHome') localStorage.setItem('isSection', section);
    else localStorage.removeItem('isSection');
    setIsActiveSection(section);
    if (section === 'logout') {
      try {
        const response = await logout();
        handleSuccessResponse(response, response.data.message);
        window.history.pushState(null, null, `${AuthRoutes.signIn}`);
        navigate(AuthRoutes.signIn);
        const disableBack = (event) => {
          window.history.pushState(null, null, `${AuthRoutes.signIn}`);
          event.preventDefault();
        };
        return () => {
          window.removeEventListener('popstate', disableBack);
        };
      } catch (error) {
        handleErrorResponse(error);
      }
    }
  };
  const menuToggle = () => {
    document.getElementById('menuBarIcon').classList.toggle('open_close');
    var menuOpenedValue =
      document.getElementById('menuBarIcon').classList.value;
    if (menuOpenedValue.length > 0) {
      setMenuOpenOrClose('menu', 'open');
      menuOpenedOrNot = true;
    } else {
      menuOpenedOrNot = false;
      removeMenuOpenOrClose('menu');
    }
  };

  const menuOpened = () => {
    return (
      menuOpenOrClose.menu !== undefined && menuOpenOrClose.menu.length > 1
    );
  };
  let menuOpenedOrNot = menuOpened();

  const isSection = localStorage.getItem('isSection');
  const pathname = location.pathname;

  const updateMenuOpenOrClose = useCallback(
    (value) => {
      if (menuOpenedOrNot) {
        document.getElementsByClassName('mainDiv')[0].style =
          `margin-left: ${!isTeamMember ? (!selectedLangugeThai ? '190px' : '230px') : !selectedLangugeThai ? '145px' : '170px'};`;
        setIsOpen(true);
      } else {
        document.getElementsByClassName('mainDiv')[0].style = '';
        setIsOpen(value);
      }
    },
    [isTeamMember, selectedLangugeThai, menuOpenedOrNot, setIsOpen],
  );

  useEffect(() => {
    if (localStorage.getItem('isSection')) {
      setIsActiveSection(localStorage.getItem('isSection'));
    }
    updateMenuOpenOrClose(false);
  }, [isSection, pathname, updateMenuOpenOrClose]);

  const sidebarLinks = [
    {
      title: t('vendor.dashboard'),
      icon: 'bi-speedometer2',
      path: BusinessRoutes.dashboard,
      id: 'isDashboard',
    },
    {
      title: t('vendor.myProfile'),
      icon: 'bi-person',
      path: BusinessRoutes.profile,
      id: 'isVendorProfile',
    },
    {
      title: t('vendor.accountSetting'),
      icon: 'bi-shop-window',
      path: BusinessRoutes.accountDetails,
      id: 'isBusinessAccount',
    },
    {
      title: t('vendor.finance'),
      icon: 'bi-bank',
      path: BusinessRoutes.finance,
      id: 'isBusinessFinance',
    },
    {
      title: t('vendor.payout'),
      icon: 'bi-wallet',
      path: BusinessRoutes.payout,
      id: 'isBusinessPayout',
    },
    {
      title: t('ServiceField.services'),
      icon: 'bi-collection',
      path: BusinessRoutes.bussinessServices,
      id: 'isBusinessService',
    },
    {
      title: t('vendor.branches'),
      icon: 'bi-diagram-3',
      path: BusinessRoutes.branches,
      id: 'isBusinessBranch',
      show: freelancer && 'false',
    },
    {
      title: t('teamSetupField.teamSetup'),
      icon: 'bi-people',
      path: BusinessRoutes.teamMembers,
      id: 'isBusinessTeamSetup',
    },
    {
      title: t('vendor.bookings'),
      icon: 'bi-calendar-week',
      path: BusinessRoutes.bussinessbookings,
      id: 'isBusinessBooking',
    },
    {
      title: t('vendor.calender'),
      icon: 'bi-calendar3',
      path: BusinessRoutes.calender,
      id: 'isBusinessCalender',
    },
    {
      title: `${t('vendor.review')} & ${t('vendor.ratings')}`,
      icon: 'bi-hand-thumbs-up',
      path: BusinessRoutes.businessReview,
      id: 'isBusinessReview',
    },
  ];
  const teamMemberSidebarLinks = [
    {
      title: t('vendor.myProfile'),
      icon: 'bi-person',
      path: BusinessRoutes.profile,
      id: 'isVendorProfile',
    },
    {
      title: t('vendor.bookings'),
      icon: 'bi-calendar-week',
      path: BusinessRoutes.bussinessbookings,
      id: 'isBusinessBooking',
    },
    {
      title: t('vendor.calender'),
      icon: 'bi-calendar3',
      path: BusinessRoutes.calender,
      id: 'isBusinessCalender',
    },
  ];
  const renderedLinks = (
    isTeamMember ? teamMemberSidebarLinks : sidebarLinks
  ).map((link) =>
    link.show === 'false' ? null : (
      <div className="d-flex" key={link.id}>
        <Link
          onClick={() => handleSectionClick(link.id)}
          className={`vendor_link ${isOpen ? 'vendor_inactiveLink' : 'vendor_activeLink'} ${isActiveSection === link.id ? 'vendor_sidebar-active-link' : ''}`}
          onMouseEnter={() => !isOpen && changeState()}
          to={link.path}
        >
          <div className={`vendor_icon`}>
            <i
              className={`bi ${link.icon} venodr-sidebar-fa-fa-icon ${isActiveSection === link.id ? 'vendor_sidebar-active-link' : 'vendor_sidebar-deactive-link'}`}
            ></i>
            <span
              className={`d-${isOpen ? 'block' : 'none'} vendor_link_text ${isActiveSection === link.id ? 'vendor_sidebar-active-link' : ''}`}
            >
              {link.title}
            </span>
          </div>
        </Link>
      </div>
    ),
  );
  
  return (
    <>
      <div className="vendor_sidebar_outer_container">
        <div className="user-sidebar-cancel-deletion-header">
          {deletionRequest && (
            <>
              <CancelDeletionHeaderLogic
                deletionRequest={deletionRequest}
                setDeletionRequest={setDeletionRequest}
              />
            </>
          )}
        </div>
        
        <div className="sidebar vendor_sidebar_outer_div">
          <div className="vendor_sidebarContaine-outer-div">
            <div
              className="vendor_sidebarTop_heading"
              onClick={() => updateMenuOpenOrClose(false)}
            >
              <div className="vendor_top_section">
                <div
                  onClick={() => menuToggle()}
                  id="menuBarIcon"
                  className={menuOpenedOrNot ? 'open_close' : ''}
                >
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                </div>
              </div>
            </div>
            <div
              className="vendor_menuBarOuterDiv"
              onMouseLeave={() => updateMenuOpenOrClose(false)}
            >
              {renderedLinks}
              <div className="d-flex sidebar-logout-vendor vendor-sidebar-logout">
                <Link
                  onClick={() => handleSectionClick('logout')}
                  className={`vendor_link  ${isOpen ? 'vendor_inactiveLink' : 'vendor_activeLink'}`}
                  onMouseEnter={() => !isOpen && changeState()}
                >
                  <div className={`vendor_icon vendor-sidebar-icon-logout`}>
                    <i
                      className={`bi bi-power  venodr-sidebar-fa-fa-icon vendor_sidebar-deactive-link`}
                    ></i>
                    <span
                      className={`vendor_link_text vendor_sidebar-active-link venodr-sidebar-log-out-section-active-lick d-${isOpen ? 'block' : 'none'}`}
                    >
                      {t('header.logout')}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <main
          className="mainDiv"
          onMouseEnter={() => updateMenuOpenOrClose(false)}
        >
          {children}
        </main>
      </div>
    </>
  );
};
VendorSidebar.propTypes = {
  isPageVendor: PropTypes.shape({
    isDashboard: PropTypes.bool,
    isMyProfile: PropTypes.bool,
    isAccountDetails: PropTypes.bool,
    isFinance: PropTypes.bool,
    isTeamSetup: PropTypes.bool,
    isService: PropTypes.bool,
    isBooking: PropTypes.bool,
    isCalender: PropTypes.bool,
    isBranch: PropTypes.bool,
  }),
  children: PropTypes.node,
  changeState: PropTypes.func,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};
export default VendorSidebar;