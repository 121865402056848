import { React, useState, useNavigate, Locale, moment } from '../../libraries/index';
import { encryptData } from '../../customHooks/index';
import { AddToCartModal } from '../../pages';
import { CustomerRoutes } from '../../routes/index';
import { getCalculationPickAndTotalAmount, handleErrorResponse } from '../../services';

const AddToCartModalLogic = ({
  showBookNowModel,
  setShowBookNowModel,
  products,
  deleteServiceId,
}) => {
  const navigate = useNavigate();
  const { t } = Locale();
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedBranch, setSelectedBranch] = useState({})
  const [formErr, setFormErr] = useState({});
  const handleOk = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newFormErr = {};
    let hasError = false;

    const availableProducts = products.filter(
      (product) =>
        product.vendor_business_hours &&
        Object.values(product.vendor_business_hours).some(
          (day) => day.shop_open === 'true',
        ),
    );

    const availableDeleteServiceId = deleteServiceId.filter((productId) =>
      availableProducts.some(
        (product) => product.cart_services_id === productId,
      ),
    );

    const availableSelectedDates = {};
    for (const productId of availableDeleteServiceId) {
      if (selectedDates[productId]) {
        availableSelectedDates[productId] = selectedDates[productId];
      }
    }

    const availableSelectedLocation = {};
    for (const productId of availableDeleteServiceId) {
      if (selectedLocation[productId]) {
        availableSelectedLocation[productId] = selectedLocation[productId];
      }
    }

    let selectedService = [];
    availableProducts.forEach((product) => {
      if (availableDeleteServiceId.includes(product.cart_services_id)) {
        selectedService.push(product);
      }
    });

    for (const productId of availableDeleteServiceId) {
      if (!availableSelectedDates[productId]) {
        newFormErr[productId] = `${t('vendor.please')} ${t('field.date/time')}`;
        hasError = true;
      }
    }

    if (hasError) {
      setFormErr(newFormErr);
    } else {
      localStorage.getItem('isSection') && localStorage.removeItem('isSection');
      const products = availableProducts.map(product => ({
        cart_services_id: product.cart_services_id,
        service_id: product.service_id
      }));
      const branchInformation = {};

      Object.keys(selectedBranch)?.forEach(key => {
        // Har key ke liye branchId ko as a value set karte hain
        branchInformation[key] = selectedBranch[key][0].branchId;
      });


      const dataToEncrypt = {
        products: products,
        deleteServiceId: availableDeleteServiceId,
        selectedDates: availableSelectedDates,
        selectedLocation: availableSelectedLocation,
        branchInformation: branchInformation
      };
      const encryptedData = encryptData(dataToEncrypt);
      setShowBookNowModel(false);

      handleCalculationApi(encryptedData, products, availableSelectedDates, availableSelectedLocation, branchInformation)

    }
  };

  const handleCalculationApi = async (encryptedData, products, selectedDates, selectedLocation, branchInformation) => {
    try {
      const param = {
        booking_service_details: createDynamicObjects(
          products,
          selectedDates,
          selectedLocation,
          branchInformation
        ),
        checkout_payload: encryptedData
      };
      let response = await getCalculationPickAndTotalAmount(param);
      navigate(`${CustomerRoutes.cartPayment}/${response.data.unique_checkout_url}`);

    }
    catch (err) {
      handleErrorResponse(err)

    }
  }

  const createDynamicObjects = (products, selectedDates, selectedLocation, branchInformation) => {
    let dynamicArray = [];
    products.forEach((product) => {
      const selectedDateTime = selectedDates[product.cart_services_id];
      if (selectedDateTime) {
        const dateTime = moment(selectedDateTime, 'DD/MM/YYYY HH:mm');
        const bookingDate = dateTime.format('DD-MM-YYYY');
        const bookingTime = dateTime.format('HH:mm');
        const isAtShop = selectedLocation[product.cart_services_id] === 'at_shop';
        if (isAtShop) {
          dynamicArray.push({
            service_id: product.service_id,
            booking_date: bookingDate,
            booking_time: bookingTime,
            // branch_id: branchInformation[product?.cart_services_id],
          });
        } else {
          dynamicArray.push({
            service_id: product.service_id,
            booking_date: bookingDate,
            booking_time: bookingTime,
          });
        }
      }
    });

    return dynamicArray;
  };


  const handleCancel = () => {
    setShowBookNowModel(false);
  };

  const handleDateChange = (
    productId,
    date,
    dateString,
    data,
    selectedLocationArg,
    showBranchOption,
    product
  ) => {

    if (!selectedLocation[productId]) {
      setSelectedLocation(prev => ({
        ...prev,
        [productId]: selectedLocationArg[0]?.value || 'at_shop'
      }));
    }

    if (!selectedBranch[productId]) {
      product?.branch_details?.map((obj) => {
        if (obj?.id === showBranchOption?.[0]?.value) {
          setSelectedBranch(prev => ({
            ...prev,
            [productId]: [{
              branchId: showBranchOption?.[0].value,
              branchName: obj?.name,
              branchAddress: obj?.address
            }]
          }))
        }
      })
    }
    setSelectedDates({
      ...selectedDates,
      [productId]: dateString,
    });
    if (formErr[productId]) {
      setFormErr({
        ...formErr,
        [productId]: '',
      });
    }
  };

  const handleLocationChange = (value, productId) => {
    setSelectedLocation({
      ...selectedLocation,
      [productId]: value,
    });
  };



  const handleBranchChange = (value, productId, product) => {
    product?.branch_details?.map((obj) => {
      if (obj.id === value) {
        setSelectedBranch(prev => ({
          ...prev,
          [productId]: [{
            branchId: value,
            branchName: obj?.name,
            branchAddress: obj?.address
          }]
        }))
      }
    })
  };

  const handleDisableDate = (current, product) => {
    if (current && current < moment().startOf('day')) {
      return true;
    }
    const dayOfWeek = current.day();
    const daysMapping = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };

    const dayString = Object.keys(daysMapping).find(
      (key) => daysMapping[key] === dayOfWeek,
    );
    const shopOpenToday =
      product.vendor_business_hours[dayString]?.shop_open === 'true';

    if (!shopOpenToday) {
      return true;
    }

    return false;
  };

  const handleDisableTime = (current, product) => {
    if (!current) {
      return null;
    }
    const selectedDate = current.clone().startOf('day');
    const dayOfWeek = selectedDate.format('dddd').toLowerCase();
    const openTime = moment(
      product.vendor_business_hours[dayOfWeek]?.open,
      'HH:mm',
    );
    const closeTime = moment(
      product.vendor_business_hours[dayOfWeek]?.close,
      'HH:mm',
    );
    if (current.isSame(selectedDate, 'day')) {
      return {
        disabledHours: () => {
          const hours = [];
          for (let i = 0; i < 24; i++) {
            const currentHour = moment().hour(i);
            if (
              currentHour.isBefore(openTime) ||
              currentHour.isAfter(closeTime)
            ) {
              hours.push(i);
            }
          }
          return hours;
        },
        disabledMinutes: (selectedHour) => {
          if (
            selectedHour < openTime.hour() ||
            selectedHour > closeTime.hour()
          ) {
            return [...Array(60).keys()]; // Disable all minutes
          }
          if (selectedHour === openTime.hour()) {
            return [...Array(openTime.minute()).keys()]; // Disable minutes before open time
          }
          if (selectedHour === closeTime.hour()) {
            return [...Array(closeTime.minute() + 1).keys()]; // Disable minutes after close time
          }
          return [];
        },
      };
    }

    return null;
  };




  return (
    <AddToCartModal
      handleBranchChange={handleBranchChange}
      open={showBookNowModel}
      handleOk={handleOk}
      products={products}
      handleCancel={handleCancel}
      deleteServiceId={deleteServiceId}
      handleDateChange={handleDateChange}
      formErr={formErr}
      handleDisableDate={handleDisableDate}
      handleDisableTime={handleDisableTime}
      handleLocationChange={handleLocationChange}
      selectedLocation={selectedLocation}
      selectedBranch={selectedBranch}
    />
  );
};

export default AddToCartModalLogic;
