import './currencyDisplay.css';
const CurrencyDisplay = ({ value, subPrice, formatedValue }) => {
  if (formatedValue) {
    const currencyDisplay = `฿ ${formatedValue}.00`;
    return (
      <>
        {' '}
        <div className="hl05eU">
          <div className="Nx9bqj CxhGGd">{currencyDisplay}</div>
        </div>
      </>
    );
  } else if (value) {
    const formattedValue = value?.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const currencyDisplay = `฿ ${formattedValue}`;
    const subPriceDisplay = `฿ ${subPrice}.00`;

    return (
      <>
        <div className="hl05eU d-flex align-items-center gap-2">
          {subPrice && <div className="yRaY8j A6+E6v">{(currencyDisplay!==subPriceDisplay)&&subPriceDisplay}</div>}
          <div
            className={`Nx9bqj CxhGGd ${subPrice && currencyDisplay && 'fw-bold currency-display-d-price'}`}
          >
            {currencyDisplay}
          </div>
        </div>
      </>
    );
  } else {
    const currencyDisplay = `฿ 00.00`;
    return (
      <>
        <div className="hl05eU">
          <div className="Nx9bqj CxhGGd">{currencyDisplay}</div>
          {subPrice && (
            <div className="yRaY8j A6+E6v currency-display-sub-price-second">
              ฿{subPrice}
            </div>
          )}
        </div>
      </>
    );
  }
};
export default CurrencyDisplay;