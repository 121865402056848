import { useHomeData } from '../../contextApi/home/homeContextApi';
import { useEffect, useState, useCallback, React, useNavigate } from '../../libraries';
import { Navbar } from '../../pages';
import { BusinessRoutes,HomeRoutes } from '../../routes/index';

const NavbarLogic = ({
  children,
  showInnerHeader,
  setPrivacy,
  handleAddToCartCount,
  showDrawer,
  showSort,
  serviceType,
}) => {
  const { showData } = useHomeData()
  const [itemCount, setItemCount] = useState(0);
  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  const cartCount = localStorage.getItem('cartCount');
  const addtocart = useCallback(async () => {
    try {
      if (role === 'Customer') {
        setItemCount(
          cartCount === null || cartCount === undefined || isNaN(cartCount)
            ? 0
            : cartCount,
        );
      }
    } catch (err) {
      console?.error('Error fetching cart items:', err);
    }
  }, [role, cartCount]);

  useEffect(() => {
    addtocart();
  }, [addtocart, handleAddToCartCount, itemCount]);

  const handleHomePage = useCallback(
    (e) => {
      e.preventDefault();
      localStorage.removeItem('isSection');
      navigate(HomeRoutes.home);
    },
    [navigate],
  );

  const handleDashboard = useCallback(
    (e) => {
      e.preventDefault();
      const sectionKey =
        role !== 'TeamMember' ? 'isDashboard' : 'isVendorProfile';
      localStorage.setItem('isSection', sectionKey);
      navigate(`${BusinessRoutes.bussiness}${role !== 'TeamMember' ? BusinessRoutes.dashboard : BusinessRoutes.profile}`);
    },
    [navigate, role],
  );

  return (
    <Navbar
      handleDashboard={handleDashboard}
      showInnerHeader={showInnerHeader}
      showSideBar={showDrawer}
      showSortBar={showSort}
      handleHomePage={handleHomePage}
      children={children}
      products={itemCount}
      setPrivacy={setPrivacy}
      serviceType={serviceType}
      showData={showData}
    />
  );
};
export default NavbarLogic;