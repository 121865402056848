import { React } from '../../libraries';

const CustomSelect = ({
  placeholder,
  options,
  className,
  error,
  value,
  name,
  onChange,
}) => {
  return (
    <>
      <select
        aria-label="Default select example"
        value={value}
        className={className}
        name={name}
        onChange={onChange}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      {error && <small className="small-err">{error}</small>}
    </>
  );
};
export default CustomSelect;