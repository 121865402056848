import { apiCall } from '../base';

const allServiceRating = async (params) => {
  const response = await apiCall(
    `customer/review_ratings/service?page_number=${params.page_number}&per_page=${params.per_page}`,
    'GET',
  );
  return response;
};

const serviceCreate = async (params) => {
  const response = await apiCall('customer/review_ratings', 'POST', params);
  return response;
};

const venderRatingCreate = async (params) => {
  const response = await apiCall('customer/review_ratings', 'POST', params);
  return response;
};

const getAllVenderRating = async (params) => {
  const response = await apiCall(
    `customer/review_ratings/vendor?page_number=${params.page_number}&per_page=${params.per_page}`,
    'GET',
  );
  return response;
};


export {
  allServiceRating,
  serviceCreate,
  venderRatingCreate,
  getAllVenderRating,
};